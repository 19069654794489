import { getPayments } from "api/payment";
import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatTimestamp } from "utils/utils";
import { default as ModalProvider } from "react-modal";
import {
  CheckCircleIcon,
  LinkIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useSearchParams, useParams } from "react-router-dom"; //Import `useParams`
import { toast } from "react-toastify";
import { Button, Text } from "components";
import {
  cancelSubscription,
  downloadInvoice,
  getSubBillingPortal,
} from "api/stripe";
import {
  getGoogleOrder,
  getMixedOrder,
  getOulookOrder,
  getPendingOrder,
} from "api/order";
import MultipleSelectInput from "components/MultiSelectInput";
import { SUBSCRIPTION_STATUS } from "config/constants";
import { ExternalLink } from "lucide-react";

const CHAR_LEN = 70;

function getColor(text) {
  // FIXME: Check color
  const normalizedText = text?.trim().toLowerCase() || "";

  switch (normalizedText) {
    case "active":
      return "green";
    case "Pending Form".trim().toLowerCase():
      return "yellow";
    case SUBSCRIPTION_STATUS.CancelRequest.trim().toLowerCase():
      return "red";
    case SUBSCRIPTION_STATUS.RequestToCancel.trim().toLowerCase():
      return "red";
    case SUBSCRIPTION_STATUS.PendingCancellation.trim().toLowerCase():
      return "red";
    case SUBSCRIPTION_STATUS.Cancelled.trim().toLowerCase():
      return "red";
    default:
      return "gray"; // Default color if no match
  }
}

function NewOrder({ isShowModal, onModalClose, handleReload, ...props }) {
  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isShowModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayclassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center p-8 bg-white-A700 relative mt-6 overflow-hidden bg-white-A700rounded-lg shadow md:mt-10">
        <div className="flex items-center justify-between w-full mb-2">
          <Text size="lg" as="p" className="tracking-[0.18px]">
            Order Confirmed
          </Text>
          <XMarkIcon
            className="h-10 w-10 cursor-pointer"
            onClick={() => onModalClose(false)}
          />
        </div>
        <div>
          <div class="px-4 py-6 sm:px-8 sm:py-10">
            <div class="-my-8 divide-y divide-gray-200">
              <div class="pt-16 pb-8 text-center sm:py-8">
                <CheckCircleIcon class="w-10 h-10 mx-auto text-green-500" />
                <h1 class="mt-4 text-2xl font-bold text-gray-900">
                  We received your order!
                </h1>
                <p class="mt-2 text-sm font-normal text-gray-600">
                  Your order is completed
                </p>
              </div>
              <div class="py-8">
                <div class="grid grid-cols-1 sm:grid-cols-2 gap-y-8 sm:gap-x-20">
                  <div>
                    <h2 class="text-xs font-bold tracking-widest text-gray-400 uppercase">
                      You will get invite to slack please accept it for better
                      customer support
                    </h2>
                    <div className="flex items-center justify-center mt-3">
                      <LinkIcon className="w-5 h-5 mr-2" />
                      <a
                        className="text-sm font-medium text-gray-600 hover:text-teal-300 cursor-pointer"
                        href="/guide"
                      >
                        Refer to the manual guide to learn about sending and
                        warm-up volume settings guidelines and more.
                      </a>
                    </div>
                    <div className="flex items-center justify-center mt-1">
                      <span className="text-sm font-medium text-gray-600">
                        You will receive a Slack invite for better customer
                        support at the registered email address. Our standard
                        fulfillment time is 48-72 hours.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalProvider>
  );
}

const ListItem = ({ label, value, circle = false, color = "teal" }) => {
  if (circle) {
    color = getColor(value);
  }
  return (
    <li className="flex items-center justify-between">
      <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
        {label}
      </p>
      {circle ? (
        <p
          className={`text-xs font-semibold border-${color}-300 text-${color}-500 bg-${color}-50 border  rounded-full inline-flex items-center px-2.5 py-1`}
        >
          {value}
        </p>
      ) : (
        <p className="text-base font-bold text-gray-900">{value}</p>
      )}
    </li>
  );
};

const DomainView = ({ domains, status }) => {
  return (
    <>
      <li className="flex items-center justify-between">
        <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
          Domains
        </p>
        <div className="flex gap-2 overflow-x-scroll max-w-md py-3">
          {domains?.map((name, i) => (
            <p
              key={i}
              className="text-xs font-semibold text-teal-300 bg-indigo-50 border border-teal-300 rounded-full inline-flex items-center px-2.5 py-1"
            >
              {name.name}
            </p>
          ))}
        </div>
      </li>
      <ListItem label="Total Domains" value={domains?.length} />
      <ListItem label="Status" value={status} circle={true} />
    </>
  );
};

function SubscriptionDetail({
  userObj,
  showModal,
  setShowModal,
  selectedOrder,
  handleDownloadInvoice,
}) {
  const [btnLoader, setBtnLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const getOrderType = () => {
    const isMixedOrder =
      selectedOrder["Mixed Order"] && selectedOrder["Mixed Order"].length > 0;
    const hasGoogleInboxes =
      selectedOrder["Google Inboxes"] &&
      selectedOrder["Google Inboxes"].length > 0;
    const hasOrders =
      selectedOrder["Orders"] && selectedOrder["Orders"].length > 0;

    switch (true) {
      case isMixedOrder && hasGoogleInboxes && hasOrders:
        return {
          type: "Mixed",
          id: selectedOrder["Mixed Order"][0],
        };
      case hasGoogleInboxes && !isMixedOrder && !hasOrders:
        return {
          type: "Google",
          id: selectedOrder["Google Inboxes"][0],
        };
      case hasOrders && !isMixedOrder && !hasGoogleInboxes:
        return {
          type: "Outlook",
          id: selectedOrder["Orders"][0],
        };
      default:
        return { type: "Other", id: null };
    }
  };

  const loadOrder = async () => {
    // FIXME Do for warmy and other invoice downlaod
    const { type, id } = getOrderType();
    if (type != "Other" && id) {
      try {
        if (
          [
            SUBSCRIPTION_STATUS.Cancelled,
            SUBSCRIPTION_STATUS.PendingCancellation,
          ].includes(selectedOrder.Status)
        ) {
          setData({ type, data: null });
          return;
        }
        setLoading(true);
        switch (type) {
          case "Mixed": {
            const resp = await getMixedOrder(
              userObj.token,
              userObj.organizationId,
              id
            );
            setData({ type, ...resp });
            break;
          }
          case "Outlook": {
            const resp = await getOulookOrder(
              userObj.token,
              userObj.organizationId,
              id
            );
            setData({ type, ...resp });
            break;
          }
          case "Google": {
            const resp = await getGoogleOrder(
              userObj.token,
              userObj.organizationId,
              id
            );
            setData({ type, ...resp });
            break;
          }
          default:
            console.warn("Unknown type:", type);
        }
      } catch (error) {
        console.error("Error in getSubscription:", error);
        toast.error(error?.response?.data?.message || "Unknown Error");
      }
      setLoading(false);
    }
  };

  const handleFillForm = async () => {
    try {
      setBtnLoader(true);
      const data = await getPendingOrder(
        userObj.token,
        userObj.organizationId,
        selectedOrder.id
      );
      window.location.href = `${window.location.origin}/order-success/${data.id}`;
    } catch (error) {
      toast.error(error?.response?.data?.message || "Unknown Error");
    }
    setBtnLoader(false);
  };

  const handleCancelOrder = async () => {
    // FIXME: Toast for cancel
    try {
      if (!window.confirm("Are you sure you want to cancel this subscription?"))
        return;
      setBtnLoader(true);
      const resp = await cancelSubscription(
        userObj.token,
        userObj.organizationId,
        { subId: selectedOrder.sub_id, id: selectedOrder.id }
      );
      if (resp.success) {
        toast.success("Subscription canceled successfully.");
        setShowModal(false);
      } else {
        toast.error("Failed to cancel the subscription.");
      }
    } catch (error) {
      console.error(error);
      toast.error(
        error.response?.data?.message || "Error canceling subscription."
      );
    } finally {
      setBtnLoader(false);
    }
  };

  const updateBillingMethod = async () => {
    try {
      setBtnLoader(true);
      const url = await getSubBillingPortal(
        userObj.token,
        userObj.organizationId,
        selectedOrder.sub_id
      );
      window.open(url, "_blank");
    } catch (error) {
      console.error(error);
      toast.error(
        error.response?.data?.message || "Error updating payment method"
      );
    }
    setBtnLoader(false);
  };

  useEffect(() => {
    loadOrder();
  }, [selectedOrder]);
  return (
    <ModalProvider
      appElement={document.getElementById("root")}
      isOpen={showModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayclassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-[80vw] p-8 bg-gray-50 rounded-lg max-h-[80%]">
        <div className="w-full mb-3 gap-2 flex">
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-between w-full mb-2">
              <Text size="lg" as="p" className="tracking-[0.18px]">
                Order Details
              </Text>
              <XMarkIcon
                className="h-12 w-12 cursor-pointer"
                onClick={() => setShowModal(false)}
              />
            </div>
            <div className="overflow-y-scroll">
              <Text size="md" as="p" className="tracking-[0.18px]">
                {selectedOrder.description}
              </Text>
              {selectedOrder.payment_status == "Form Data" ? (
                <div className="mt-10">
                  {btnLoader ? (
                    <Spinner />
                  ) : (
                    <Button
                      variant="gradient"
                      color="teal_300_teal_600"
                      className="tracking-[1.25px] uppercase font-medium min-w-[150px] rounded-md"
                      onClick={handleFillForm}
                    >
                      Fill Form
                    </Button>
                  )}
                </div>
              ) : (
                <ul className="mt-6 space-y-5">
                  <li className="flex items-center justify-between">
                    <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
                      Amount
                    </p>
                    <p className="text-base font-bold text-gray-900">
                      ${selectedOrder.amount}
                    </p>
                  </li>
                  <li className="flex items-center justify-between">
                    <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
                      Payment Status
                    </p>
                    <p className="text-base font-bold text-gray-900">
                      {selectedOrder.payment_status}
                    </p>
                  </li>
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <Spinner className="h-6 w-6" />
                    </div>
                  ) : data ? (
                    <>
                      <ListItem
                        label="Order Created At"
                        value={formatTimestamp(
                          selectedOrder.invoice_start_date
                        )}
                      />
                      {![
                        SUBSCRIPTION_STATUS.Cancelled,
                        SUBSCRIPTION_STATUS.PendingCancellation,
                      ].includes(selectedOrder.Status) ? (
                        data.type === "Mixed" ? (
                          <>
                            <div className="bg-white-A700 p-2">
                              <p className="text-sm font-bold">Outlook</p>
                              <DomainView
                                domains={data.outlook_domains}
                                status={data.outlook_status}
                              />
                            </div>

                            <div className="bg-white-A700 p-2">
                              <p className="text-sm font-bold">Google</p>
                              <DomainView
                                domains={data.google_domains}
                                status={data.google_status}
                              />
                            </div>
                          </>
                        ) : (
                          <DomainView
                            domains={data.domains}
                            status={data.status}
                          />
                        )
                      ) : (
                        <ListItem
                          label="Status"
                          value={selectedOrder.status}
                          circle={true}
                        />
                      )}
                      <div className="mt-4 flex items-center justify-end gap-2">
                        {btnLoader ? (
                          <Spinner />
                        ) : (
                          <>
                            <button
                              onClick={updateBillingMethod}
                              className="flex items-center space-x-1 px-3 py-1.5 rounded-md text-sm font-medium cursor-pointer transition-colors hover:text-emerald-500"
                            >
                              <ExternalLink size={16} className="mr-1" />
                              <span>Update Billing Method</span>
                            </button>
                            <Button
                              variant="gradient"
                              color="teal_300_teal_600"
                              className="rounded-md"
                              onClick={() =>
                                handleDownloadInvoice(
                                  setBtnLoader,
                                  selectedOrder.type_id
                                )
                              }
                            >
                              Download Invoice
                            </Button>

                            {/* <Button
                              className="bg-teal-300 hover:bg-teal-400 text-white-A700 rounded-lg"
                              onClick={handleUpdateOrder}
                            >
                              Update Order
                            </Button> */}
                            {![
                              SUBSCRIPTION_STATUS.RequestToCancel,
                              SUBSCRIPTION_STATUS.PendingCancellation,
                              SUBSCRIPTION_STATUS.Cancelled,
                            ].includes(selectedOrder.status) && (
                              <Button
                                variant="gradient"
                                className="rounded-md bg-red-400 text-white-A700"
                                onClick={handleCancelOrder}
                              >
                                Cancel Order
                              </Button>
                            )}
                            {[SUBSCRIPTION_STATUS.RequestToCancel].includes(
                              selectedOrder.status
                            ) && (
                              <Button
                                variant="gradient"
                                className="rounded-md bg-gray-500 text-white-A700"
                                onClick={() => toast.info("Ask Chat Support!")}
                              >
                                Renew Subscription
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="flex gap-x-2 items-center justify-end">
                      <button
                        onClick={updateBillingMethod}
                        className="flex items-center space-x-1 px-3 py-1.5 rounded-md text-sm font-medium cursor-pointer transition-colors hover:text-emerald-500"
                      >
                        <ExternalLink size={16} className="mr-1" />
                        <span>Update Billing Method</span>
                      </button>
                      <Button
                        variant="gradient"
                        color="teal_300_teal_600"
                        className="rounded-md"
                        onClick={() =>
                          handleDownloadInvoice(
                            setBtnLoader,
                            selectedOrder.type_id
                          )
                        }
                      >
                        Download Invoice
                      </Button>
                    </div>
                  )}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalProvider>
  );
}

function InvoiceDetail({
  showModal,
  setShowModal,
  selectedOrder,
  handleDownloadInvoice,
  gotoOrder,
}) {
  const [btnLoader, setBtnLoader] = useState(false);
  return (
    <ModalProvider
      appElement={document.getElementById("root")}
      isOpen={showModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayclassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-[80vw] p-8 bg-gray-50 rounded-lg max-h-[80%]">
        <div className="w-full mb-3 gap-2 flex">
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-between w-full mb-2">
              <Text size="lg" as="p" className="tracking-[0.18px]">
                {selectedOrder.type === "subscription_cycle"
                  ? "Monthly Invoice"
                  : "Payment Detail"}
              </Text>
              <XMarkIcon
                className="h-12 w-12 cursor-pointer"
                onClick={() => setShowModal(false)}
              />
            </div>
            <div className="overflow-y-scroll">
              <Text size="md" as="p" className="tracking-[0.18px]">
                {selectedOrder.Description}
              </Text>
              <ul className="mt-6 space-y-5">
                <li className="flex items-center justify-between">
                  <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
                    Amount
                  </p>
                  <p className="text-base font-bold text-gray-900">
                    ${selectedOrder.amount}
                  </p>
                </li>
                <li className="flex items-center justify-between">
                  <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
                    Payment Status
                  </p>
                  <p className="text-base font-bold text-gray-900">
                    {selectedOrder.payment_status}
                  </p>
                </li>
                {selectedOrder.type_id.startsWith("in_") && (
                  <>
                    <ListItem
                      label="Invoice Start Date"
                      value={formatTimestamp(selectedOrder.invoice_start_date)}
                    />
                    <ListItem
                      label="Invoice End Date"
                      value={formatTimestamp(selectedOrder.invoice_end_date)}
                    />
                  </>
                )}
              </ul>
              {selectedOrder.type_id.startsWith("in_") && (
                <div className="mt-4 flex items-center justify-end gap-2">
                  {btnLoader ? (
                    <Spinner />
                  ) : (
                    <>
                      <Button
                        variant="gradient"
                        color="teal_300_teal_600"
                        className="rounded-md"
                        onClick={() =>
                          handleDownloadInvoice(
                            setBtnLoader,
                            selectedOrder.type_id
                          )
                        }
                      >
                        Download Invoice
                      </Button>
                      <Button
                        variant="gradient"
                        className="rounded-md bg-gray-500 text-white-A700"
                        onClick={() => gotoOrder(selectedOrder)}
                      >
                        Goto Subscription
                      </Button>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalProvider>
  );
}

function Billing() {
  const { orderId } = useParams(); // Get orderId from URL
  const { userObj } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab") || "subscriptions";
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [domains, setDomains] = useState([]);
  const [newOrderModal, setNewOrderModal] = useState(false);

  const gotoOrder = (result) => {
    setInvoiceModal(null);
    if (result.type === "subscription_create") {
      setShowModal(result);
    } else {
      const subId = result.sub_payment_id;
      const matchingSubscription = subscriptions.data.find(
        (item) => item.id === subId
      );
      if (matchingSubscription) setShowModal(matchingSubscription);
    }
  };

  const handleDownloadInvoice = async (setLoader, id) => {
    try {
      setLoader(true);
      const { invoice_pdf } = await downloadInvoice(
        userObj.token,
        userObj.organizationId,
        id
      );
      if (invoice_pdf) {
        const link = document.createElement("a");
        link.href = invoice_pdf;
        link.download = `invoice-${id}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        toast.error("Invoice PDF not available.");
      }
    } catch (error) {
      console.error("Error downloading invoice:", error);
      toast.error("Error downloading invoice.");
    }
    setLoader(false);
  };

  const loadData = async (setter, type) => {
    setLoading(true);
    try {
      const dt = await getPayments(userObj.token, userObj.organizationId, type);
      setter(dt);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching billing");
    }
    setLoading(false);
  };

  useEffect(() => {
    const domainList = subscriptions?.data.flatMap((item) =>
      item.domains && Array.isArray(item.domains)
        ? item.domains
            .filter((name) => name)
            .map((name) => ({
              value: item.id,
              label: name.name,
            }))
        : []
    );
    setDomains(domainList);
  }, [subscriptions]);

  useEffect(() => {
    if (searchParams.get("new-order")) {
      setNewOrderModal(true);
    }
  }, [searchParams]);

  const handleTabChange = (newTab) => {
    setSearchParams({ tab: newTab.toLowerCase() });
  };

  useEffect(() => {
    if (orderId && subscriptions && subscriptions.data) {
      const found = subscriptions.data.find(
        (subscription) => subscription.id === orderId
      );
      if (found) {
        setFilteredData([found]);
      } else {
        setFilteredData([]);
      }
    } else {
      setFilteredData([]);
    }
  }, [orderId, subscriptions]);

  useEffect(() => {
    if (!orderId && subscriptions && subscriptions.data) {
      const selectedDomainValues = selectedDomain.map((item) => item.value);
      let filteredSubscriptions = subscriptions.data;
      if (selectedDomainValues.length > 0) {
        filteredSubscriptions = filteredSubscriptions.filter((subscription) =>
          selectedDomainValues.includes(subscription.id)
        );
      }
      const selectedStatusValues = Object.values(selectedStatus).map((status) =>
        status.label.trim().toLowerCase()
      );
      // Filter by status if selectedStatus is not empty
      if (selectedStatusValues.length > 0) {
        filteredSubscriptions = filteredSubscriptions.filter((subscription) => {
          let subscriptionStatus =
            subscription?.status?.trim().toLowerCase() || "active";
          if (
            subscription?.payment_status?.trim().toLowerCase() === "form data"
          ) {
            subscriptionStatus = "pending form";
          }

          return selectedStatusValues.includes(subscriptionStatus);
        });
      }
      if (filteredSubscriptions.length > 0) {
        setFilteredData(filteredSubscriptions);
      } else {
        setFilteredData([]);
      }
    }
  }, [selectedDomain, subscriptions, selectedStatus, orderId]);
  const handleOrderClick = (result) => {
    setShowModal(result);
  };

  useEffect(() => {
    loadData(setSubscriptions, "");
    loadData(setInvoices, "invoices");
  }, [userObj]);

  return (
    <main>
      <div>
        <div className="px-4 mx-auto sm:px-6 md:px-8">
          <h1 className="text-2xl font-bold text-gray-900">Billing</h1>
        </div>
        <div className="px-4 mx-auto sm:px-6 md:px-8">
          <div className="w-full pb-1 overflow-x-auto">
            <div className="border-b border-gray-200">
              <nav className="flex -mb-px space-x-4">
                {[
                  { name: "Subscriptions", data: subscriptions },
                  { name: "Invoices", data: invoices },
                ].map((tab, i) => (
                  <div
                    key={tab.name}
                    className={`py-4 text-sm font-medium transition-all duration-20 whitespace-nowrap ${
                      selectedTab === tab.name.toLowerCase()
                        ? "border-b-2 text-teal-300 border-teal-300"
                        : "cursor-pointer"
                    }`}
                    onClick={() => handleTabChange(tab.name.toLowerCase())}
                  >
                    {tab.name}{" "}
                    {tab.data?.totalRecords ? `(${tab.data.totalRecords})` : ""}
                  </div>
                ))}
              </nav>
            </div>
          </div>

          {loading ? (
            <Spinner />
          ) : (
            <>
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  {selectedTab === "subscriptions" && (
                    <>
                      {!orderId && domains && domains.length > 0 && (
                        <div className="my-2 flex gap-x-2">
                          <div className="w-1/2">
                            <MultipleSelectInput
                              options={domains}
                              label=""
                              placeholder="Select a Domain"
                              selected={selectedDomain}
                              setSelected={setSelectedDomain}
                              multiselect={true}
                              search={true}
                            />
                          </div>
                          <div className="w-1/2">
                            <MultipleSelectInput
                              options={Object.entries(SUBSCRIPTION_STATUS).map(
                                ([value, label]) => ({
                                  label,
                                  value,
                                })
                              )}
                              label=""
                              placeholder="Select a Status"
                              selected={selectedStatus}
                              setSelected={setSelectedStatus}
                              multiselect={true}
                              search={false}
                            />
                          </div>
                        </div>
                      )}
                      <div
                        className="flex flex-col"
                        style={{ height: "60vh", overflowY: "scroll" }}
                      >
                        <table className="min-w-full lg:divide-y lg:divide-gray-200">
                          <thead className="table-header-group">
                            <tr>
                              <th className="py-3.5 pl-4 pr-3 text-left text-sm whitespace-nowrap font-medium text-gray-500 sm:pl-6 md:pl-0" />
                              <th className="py-3.5 pl-4 pr-3 text-left text-sm whitespace-nowrap font-medium text-gray-500 sm:pl-6 md:pl-0">
                                ID
                              </th>
                              <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                                Product
                              </th>
                              <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                                Total Domains
                              </th>
                              <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                                Date
                              </th>
                              <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                                Price
                              </th>
                              <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                                Status
                              </th>
                              <th className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
                                <span className="sr-only">Actions</span>
                              </th>
                            </tr>
                          </thead>

                          <tbody className="divide-y divide-gray-200">
                            {filteredData.map((result, i) => {
                              let status = result?.status || "Active";
                              if (result.payment_status == "Form Data") {
                                status = "Pending Form";
                              }
                              const color = getColor(status);
                              return (
                                <tr
                                  key={i}
                                  className="hover:bg-gray-100 cursor-pointer"
                                  onClick={() => handleOrderClick(result)}
                                >
                                  <td className="flex items-start px-4 py-4 justify-center">
                                    <p className="bg-gray-100 text-gray-700 rounded-lg p-1 px-2 text-sm font-medium">
                                      {i + 1}
                                    </p>
                                  </td>
                                  <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap sm:pl-6 md:pl-0">
                                    #{result.id.replace("rec", "")}
                                  </td>

                                  <td className="px-4 py-4 text-sm font-bold text-gray-900 whitespace-nowrap">
                                    <div className="inline-flex items-center">
                                      {result.description.includes("\n")
                                        ? "Custom Order"
                                        : result.description.length > CHAR_LEN
                                        ? result.description.substring(
                                            0,
                                            CHAR_LEN
                                          ) + "..."
                                        : result.description}
                                    </div>
                                  </td>

                                  <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap sm:pl-6 md:pl-0 text-center">
                                    {result.domains.length || ""}
                                  </td>

                                  <td className="px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                                    {formatTimestamp(result.invoice_start_date)}
                                  </td>

                                  <td className="px-4 py-4 text-sm font-bold text-gray-900 lg:table-cell whitespace-nowrap">
                                    ${result.amount}
                                  </td>

                                  <td className="px-4 py-4 text-sm font-bold text-gray-900 lg:table-cell whitespace-nowrap">
                                    <p
                                      className={`text-xs font-semibold border-${color}-300 text-${color}-500 bg-green-50 border  rounded-full inline-flex items-center px-2.5 py-1`}
                                    >
                                      {status}
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                  {selectedTab === "invoices" && (
                    <div
                      className="flex flex-col"
                      style={{ height: "60vh", overflowY: "scroll" }}
                    >
                      <table className="min-w-full lg:divide-y lg:divide-gray-200">
                        <thead className="table-header-group">
                          <tr>
                            <th className="py-3.5 pl-4 pr-3 text-left text-sm whitespace-nowrap font-medium text-gray-500 sm:pl-6 md:pl-0" />

                            <th className="py-3.5 pl-4 pr-3 text-left text-sm whitespace-nowrap font-medium text-gray-500 sm:pl-6 md:pl-0">
                              ID
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                              Product
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                              Date
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                              Price
                            </th>
                            <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                              Type
                            </th>
                            <th className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
                              <span className="sr-only">Actions</span>
                            </th>
                          </tr>
                        </thead>

                        <tbody className="divide-y divide-gray-200">
                          {(invoices?.data || []).map((result, i) => (
                            <tr
                              key={i}
                              className="hover:bg-gray-100 cursor-pointer"
                              onClick={() => setInvoiceModal(result)}
                            >
                              <td className="flex items-start px-4 py-4 justify-center">
                                <p className="bg-gray-100 text-gray-700 rounded-lg p-1 px-2 text-sm font-medium">
                                  {i + 1}
                                </p>
                              </td>
                              <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap sm:pl-6 md:pl-0">
                                #{result.id.replace("rec", "")}
                              </td>

                              <td className="px-4 py-4 text-sm font-bold text-gray-900 whitespace-nowrap">
                                <div className="inline-flex items-center">
                                  {result.description.includes("\n")
                                    ? "Custom Order"
                                    : result.description.length > CHAR_LEN
                                    ? result.description.substring(
                                        0,
                                        CHAR_LEN
                                      ) + "..."
                                    : result.description}
                                </div>
                              </td>

                              <td className="px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                                {result.type_id.startsWith("in_")
                                  ? formatTimestamp(result.invoice_start_date)
                                  : formatTimestamp(result.created_at)}
                              </td>

                              <td className="px-4 py-4 text-sm font-bold text-gray-900 lg:table-cell whitespace-nowrap">
                                ${result.amount}
                              </td>
                              <td className="px-4 py-4 text-sm font-bold text-gray-900 lg:table-cell whitespace-nowrap">
                                {result.type}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>

              {showModal && (
                <SubscriptionDetail
                  userObj={userObj}
                  showModal={showModal !== null}
                  setShowModal={setShowModal}
                  selectedOrder={showModal}
                  handleDownloadInvoice={handleDownloadInvoice}
                />
              )}
              {invoiceModal && (
                <InvoiceDetail
                  showModal={invoiceModal !== null}
                  setShowModal={setInvoiceModal}
                  selectedOrder={invoiceModal}
                  handleDownloadInvoice={handleDownloadInvoice}
                  gotoOrder={gotoOrder}
                />
              )}
              <NewOrder
                isShowModal={newOrderModal}
                onModalClose={setNewOrderModal}
              />
            </>
          )}
        </div>
      </div>
    </main>
  );
}

export default Billing;
// FIXME: invoice and subscription pages not same
