import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { getRegisteredDomains } from "api/domain";
import { Button, Input } from "components";
import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Badge from "components/Badge";
import { ExternalLink } from "lucide-react";

export default function PurchaseDomains() {
  const navigate = useNavigate();

  const { userObj } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [filteredDomains, setFilteredDomains] = useState(null);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [inboxTest, setInboxTest] = useState(null);
  const [domainSource, setDomainSource] = useState([]);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [showIUModal, setShowIUModal] = useState(false); // State for modal visibility
  const [searchTerm, setSearchTerm] = useState("");
  const [egApp, setEGApp] = useState([]);

  const loadDomains = async () => {
    setLoading(true);
    try {
      const dt = await getRegisteredDomains(
        userObj.token,
        userObj.organizationId
      );
      setResults(dt);
      setFilteredDomains(dt.domains);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching domains");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (results?.domains) {
      const filtered = results.domains.filter((item) =>
        item.domain?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredDomains(filtered);
    }
  }, [searchTerm, results]);

  useEffect(() => {
    loadDomains();
  }, [userObj]);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 h-full">
          {results && (
            <>
              <div className="flex justify-between items-center">
                <p className="text-xl font-bold text-gray-900">
                  Registered Domains{" "}
                  {results.domains?.length
                    ? `(${results?.domains.length})`
                    : ""}
                </p>
              </div>

              <div className="flex flex-col h-full">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="mt-4 flex justify-between items-center gap-2 sm:flex-col">
                      <div className="w-[60%]">
                        <Input
                          placeholder="Search domain"
                          className="w-full bg-white-A700" // Ensure the input fills the 60% container
                          prefix={<MagnifyingGlassIcon className="w-5 h-5" />}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                      <Button
                        className="ml-2 rounded-lg bg-teal-300 text-whit font-light text-white-A700"
                        onClick={() => navigate(`/buy-domains`)}
                      >
                        Buy Domains
                      </Button>
                    </div>
                    {results && (
                      <div className="h-[75vh] overflow-y-auto border border-gray-200 rounded-xl mt-3">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-200">
                            <tr>
                              {[
                                "Domain Name",
                                "Purchase Date",
                                // "Time Since Purchase",
                                "Price",
                                "Payment",
                                "Auto-Renew",
                                // "Associated Order",
                                "Status",
                              ].map((header, i) => (
                                <th
                                  key={i}
                                  className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest"
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>

                          <tbody className="bg-white-A700 divide-y divide-gray-200">
                            {filteredDomains &&
                              filteredDomains.map((item, index) => (
                                <tr key={index}>
                                  <td className="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                    {item.domain}
                                  </td>
                                  <td className="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                    {item.created_at}
                                  </td>
                                  <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                    ${item.price}
                                  </td>
                                  <td className="px-4 py-4">
                                    {item.payment_id && (
                                      <button
                                        className="flex items-center space-x-1 px-3 py-1.5 rounded-md text-xs font-medium text-purple-700 bg-purple-50 hover:bg-purple-100 transition-colors"
                                        onClick={() =>
                                          navigate(
                                            `/billing/${item.payment_id}`
                                          )
                                        }
                                      >
                                        <ExternalLink
                                          size={14}
                                          className="mr-1"
                                        />
                                        <span>Order</span>
                                      </button>
                                    )}
                                  </td>
                                  <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                    {item.auto_renew ? "Yes" : ""}
                                  </td>

                                  {/* <td className="px-4 py-4 flex items-center justify-center">
                                    <button
                                      className="flex items-center space-x-1 px-3 py-1.5 rounded-md text-xs font-medium text-purple-700 bg-purple-50 hover:bg-purple-100 transition-colors"
                                      onClick={() => setInboxTest(item)}
                                    >
                                      <Inbox size={14} className="mr-1" />
                                      <span>Test</span>
                                    </button>
                                  </td> */}
                                  <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                    <Badge
                                      value={
                                        item.status.charAt(0).toUpperCase() +
                                        item.status.slice(1)
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {/* {inboxTest != null && (
        <Popup
          isOpen={inboxTest != null}
          onClose={() => {
            setInboxTest(null);
          }}
          title="Inbox Placement Test (Email Guard)"
          icon="https://www.google.com/s2/favicons?sz=64&domain_url=https%3A%2F%2Fapp.emailguard.io%2Finbox-placement-tests"
          className="w-[40%]"
        >
          <InboxTest
            user={userObj}
            showModal={inboxTest != null}
            setShowModal={() => {
              setInboxTest(null);
            }}
            egApp={egApp}
            domain={inboxTest}
            refreshEG={loadEmailGuardApps}
          />
        </Popup>
      )} */}
    </div>
  );
}
