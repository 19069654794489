import axios from "axios";
import { BACKEND_URL } from "../config/config";
import { API_VERSION } from "config/prices";

const path = "app";

const getAllApps = async (token, organizationId, filters = {}) => {
  const queryParams = new URLSearchParams({
    token,
    organizationId,
    ...filters,
  });

  const response = await axios.get(
    `${BACKEND_URL}/api/${API_VERSION}/${path}?${queryParams.toString()}`
  );

  return response.data;
};


const createApp = async (token, organizationId, data) => {
  const response = await axios.post(
    `${BACKEND_URL}/api/${API_VERSION}/${path}?token=${token}&organizationId=${organizationId}`,
    data
  );
  return response.data;
};

const deleteApp = async (token, organizationId, id) => {
  const response = await axios.delete(
    `${BACKEND_URL}/api/${API_VERSION}/${path}/${id}?token=${token}&organizationId=${organizationId}`
  );
  return response.data;
};

export { getAllApps, createApp, deleteApp };
