import React, { useState } from "react";
import { Check, ChevronDown } from "lucide-react";
import { cn } from "lib/utils";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuCheckboxItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { Button } from "components/ui/button";
import { toast } from 'components/ui/use-toast';
interface TldOption {
  value: string;
  label: string;
  isPremium?: boolean;
}

const tlds = [
  ".com",
  ".net",
  ".org",
  ".co",
  ".app",
  ".dev",
  ".io",
  ".ai",
  ".tech",
  ".me.uk",
  ".org.uk",
  ".uk",
  ".info",
  ".us",
  ".cl",
  ".eco",
  ".eu",
  ".fi",
  ".fr",
  ".xyz",
  ".bio"
];

const commonTlds: TldOption[] = tlds.map(tld => ({
  value: tld,
  label: tld
}));


interface TldSelectorProps {
  selectedTlds: string[];
  onTldChange: (tlds: string[]) => void;
  lightTheme?: boolean;
}

const TldSelector: React.FC<TldSelectorProps> = ({
  selectedTlds,
  onTldChange,
  lightTheme = false,
}) => {
  const [open, setOpen] = useState(false);

  const toggleTld = (tld: string) => {
    if (selectedTlds.includes(tld)) {
      onTldChange(selectedTlds.filter((t) => t !== tld));
    } else {
      if(selectedTlds.length>=5){
        toast({
          title: "Can Select at max 5 Tlds",
          variant: "destructive",
        });
        return
      }
      onTldChange([...selectedTlds, tld]);
    }
  };

  const containerClass = lightTheme
    ? "bg-white-A700border border-gray-200 shadow-sm rounded-lg p-3"
    : "bg-opacity-10 bg-white-A700 border border-white/10 backdrop-blur-sm rounded-lg p-3";

  const buttonTextClass = lightTheme ? "text-gray-700" : "text-white/70";

  const selectedCount = selectedTlds.length;

  return (
    <div className={containerClass}>
      <p
        className={`text-sm mb-2 ${
          lightTheme ? "text-gray-700" : "text-white/70"
        }`}
      >
        Select TLDs to include:
      </p>
      <DropdownMenu open={open} onOpenChange={setOpen}>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            className={`w-full justify-between ${
              lightTheme
                ? "bg-white-A700border-gray-200 hover:bg-gray-50"
                : "bg-white/5 border-white/10 hover:bg-white/10"
            }`}
          >
            <span className={buttonTextClass}>
              {selectedCount > 0
                ? `${selectedCount} TLD${
                    selectedCount !== 1 ? "s" : ""
                  } selected`
                : "Select TLDs"}
            </span>
            <ChevronDown
              className={`h-4 w-4 ${
                lightTheme ? "text-gray-500" : "text-white/50"
              }`}
            />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-full min-w-[200px] max-h-[200px] overflow-y-scroll">
          {commonTlds.map((tld) => (
            <DropdownMenuCheckboxItem
              key={tld.value}
              checked={selectedTlds.includes(tld.value)}
              onSelect={(e) => {
                e.preventDefault();
                toggleTld(tld.value);
              }}
            >
              <div className="flex items-center justify-between w-full">
                <span>{tld.label}</span>
                {tld.isPremium && (
                  <span className="text-xs px-1 py-0.5 rounded bg-amber-100 text-amber-700 ml-2">
                    Premium
                  </span>
                )}
              </div>
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>

      {selectedTlds.length > 0 && (
        <div className="flex flex-wrap gap-2 mt-3">
          {selectedTlds.map((tld) => {
            const tldInfo = commonTlds.find((t) => t.value === tld);
            return (
              <div
                key={tld}
                className={cn(
                  "px-3 py-1 rounded-full text-sm flex items-center gap-1",
                  lightTheme
                    ? "bg-emerald-100 text-emerald-700 border border-emerald-200"
                    : "bg-emerald-500/20 text-emerald-400 border border-emerald-500/30"
                )}
              >
                {tld}
                {tldInfo?.isPremium && (
                  <span
                    className={`ml-1 text-xs px-1 py-0.5 rounded ${
                      lightTheme
                        ? "bg-amber-100 text-amber-700"
                        : "bg-amber-500/20 text-amber-400"
                    }`}
                  >
                    Premium
                  </span>
                )}
                <button
                  onClick={() => toggleTld(tld)}
                  className={`ml-1 text-xs hover:text-opacity-80 ${
                    lightTheme ? "text-emerald-800" : "text-emerald-300"
                  }`}
                >
                  &times;
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TldSelector;
