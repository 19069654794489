import { updateDomainAvailability } from "services/domainService";
import prefix_suffix from "./prefix_suffix.json"; // Import the JSON file

const commonTlds = [".com", ".net", ".org", ".io", ".co", ".app", ".dev"];

export const checkSingleDomain = async (
  userObj:any,
  domain: string
): Promise<DomainSuggestion> => {
  const domainSuggestion: DomainSuggestion = {
    domain,
    status: "checking",
    price: 0,
  };

  const [updatedSuggestion] = await updateDomainAvailability(userObj,[
    domainSuggestion,
  ]);
  return updatedSuggestion;
};

export const generateDomainIdeas = async (
  keyword: string,
  industry?: string,
  selectedTlds: string[] = commonTlds,
  limit: number = 5,
  offset: number = 0
): Promise<DomainSuggestion[]> => {
  if (!keyword || keyword.trim() === "") return [];

  const baseKeyword = keyword.toLowerCase().trim().replace(/\s+/g, "");
  const results: DomainSuggestion[] = [];

  // Use selected TLDs or default to commonTlds
  const tldsToUse = selectedTlds.length > 0 ? selectedTlds : commonTlds;

  // Industry-specific suffixes
  const industrySuffixes: Record<string, string[]> = {
    technology: [
      "tech",
      "app",
      "digital",
      "dev",
      "code",
      "byte",
      "cloud",
      "ai",
      "data",
      "soft",
      "net",
      "sys",
    ],
    ecommerce: [
      "shop",
      "store",
      "market",
      "buy",
      "cart",
      "deal",
      "sale",
      "mart",
      "bazaar",
      "trade",
    ],
    health: [
      "health",
      "care",
      "fit",
      "wellness",
      "life",
      "vital",
      "med",
      "clinic",
      "therapy",
      "pharma",
    ],
    education: [
      "learn",
      "edu",
      "academy",
      "school",
      "tutor",
      "class",
      "study",
      "campus",
      "institute",
      "knowledge",
    ],
    finance: [
      "finance",
      "money",
      "wealth",
      "capital",
      "invest",
      "bank",
      "pay",
      "fund",
      "loan",
      "credit",
    ],
    food: [
      "food",
      "eat",
      "meal",
      "dish",
      "taste",
      "chef",
      "kitchen",
      "recipe",
      "bake",
      "dine",
    ],
    travel: [
      "travel",
      "trip",
      "journey",
      "tour",
      "visit",
      "explore",
      "wander",
      "voyage",
      "adventure",
      "escape",
    ],
  };

  // Use industry-specific suffixes if industry is provided
  if (industry && industrySuffixes[industry]) {
    const suffixes = industrySuffixes[industry];

    // Generate combinations with suffixes
    suffixes.forEach((suffix) => {
      tldsToUse.forEach((tld) => {
        results.push({
          domain: `${baseKeyword}${suffix}${tld}`,
          status: "checking", // Initial status before API check
          price: 0,
        });
      });
    });
  } else {
    // Use prefixes and suffixes from the JSON file if no industry is provided
    const { prefixes, suffixes } = prefix_suffix;

    // Generate combinations with prefixes
    prefixes.forEach((prefix) => {
      tldsToUse.forEach((tld) => {
        results.push({
          domain: `${prefix}${baseKeyword}${tld}`,
          status: "checking",
          price: 0,
        });
      });
    });

    // Generate combinations with suffixes
    suffixes.forEach((suffix) => {
      tldsToUse.forEach((tld) => {
        results.push({
          domain: `${baseKeyword}${suffix}${tld}`,
          status: "checking",
          price: 0,
        });
      });
    });
  }

  // Shuffle the results to ensure randomness
  const shuffledResults = shuffleArray(results);

  // Apply pagination (limit and offset)
  const paginatedResults = shuffledResults.slice(offset, offset + limit);

  return paginatedResults;
};

function shuffleArray<T>(array: T[]): T[] {
  const newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
}

// Types
export type DomainStatus = "available" | "taken" | "premium" | "checking";

export interface DomainSuggestion {
  domain: string;
  status: DomainStatus;
  price: number;
  originalLanguage?: string;
}