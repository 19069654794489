import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Img, Warning, PasswordInput } from "components";
import { checkPasswordStrength } from "utils/validations";
import { Spinner } from "components/Spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { accountSetup } from "api/auth";
import { toast } from "react-toastify";

const AccountSetup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const token = params.get("token");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [pVal, setPVal] = useState({ 1: true, 2: true, 3: true });
  const [cBtn, setCBtn] = useState(false);
  const [inpval, setInpval] = useState({
    name: "",
    password: "",
    cPassword: "",
  });

  const setVal = (e) => {
    const { name, value } = e.target;
    setInpval((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "password") {
      const { rules } = checkPasswordStrength(value, name);
      setPVal(rules);
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/sign-up");
    }
    setError("");
    const { rules, isStrong } = checkPasswordStrength(
      inpval.password,
      inpval.name
    );
    const con =
      inpval.name &&
      isStrong &&
      inpval.password === inpval.cPassword;
    setCBtn(con);
  }, [token, inpval, navigate]);

  useEffect(() => {
    setError("");
    const { isStrong } = checkPasswordStrength(inpval.password, inpval.name);
    const con = isStrong && inpval.password === inpval.cPassword;
    setCBtn(con);
  });

  const handleAccountSetup = async () => {
    const { name, password } = inpval;
    setLoading(true);
    try {
      await accountSetup(token, { name, password });
      navigate("/sign-in");
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to setup account");
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <title>Scaledmail</title>
        <meta name="description" content="Scaledmail" />
      </Helmet>
      <div className="flex justify-center items-center w-full h-screen">
        <div className="flex flex-col items-center w-[36%] md:w-full gap-[15px] p-8 sm:p-5 bg-white-A700 shadow-xs rounded-[16px]">
          {error && <Warning text={error} showImage={false} />}
          <Text
            as="h2"
            className="!text-teal-500 tracking-[0.15px] capitalize text-xl"
          >
            Scaledmail by beanstalk
          </Text>
          <div className="flex flex-col self-stretch items-center gap-[19px]">
            <Text size="md" as="p" className="tracking-[0.18px]">
              Account Setup
            </Text>
          </div>
          <div className="w-full space-y-3">
            <div>
              <label htmlFor="name" className="text-sm font-bold text-gray-900">
                Name
              </label>
              <div className="mt-2">
                <input
                  // type="text"
                  name="name"
                  onChange={setVal}
                  value={inpval.name}
                  placeholder="Name here"
                  className="block w-full px-4 py-3 placeholder-gray-500 border-[1px] border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300"
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="text-sm font-bold text-gray-900"
                >
                  Password
                </label>
              </div>
              <PasswordInput
                name="password"
                setVal={setVal}
                inpval={inpval.password}
              />
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="text-sm font-bold text-gray-900"
                >
                  Confirm Password
                </label>
              </div>
              <div className="mt-2">
                <PasswordInput
                  name="cPassword"
                  setVal={setVal}
                  inpval={inpval.cPassword}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col self-stretch gap-2">
            {[
              "Password Strength:",
              "At least 8 characters",
              "Contains a number or symbol",
              "Cannot contain your name or email address",
            ].map((txt, i) => (
              <div className="flex items-center gap-2 flex-1" key={i}>
                {i !== 0 && (
                  <Img
                    src={
                      pVal[i] ? "images/img_check.svg" : "images/img_remove.svg"
                    }
                    alt="password"
                    className="h-[18px] w-[18px]"
                  />
                )}
                <div className="flex">
                  <Text
                    as="p"
                    className={`self-end ${
                      pVal[i] ? "!text-gray-600" : "!text-red-500"
                    } tracking-[0.15px]`}
                  >
                    {txt}
                  </Text>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col self-stretch items-center gap-[19px]">
            {loading ? (
              <Spinner />
            ) : (
              <Button
                className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium rounded-md"
                onClick={handleAccountSetup}
                disabled={!cBtn}
              >
                Setup
              </Button>
            )}
          </div>
          
          <a href="sign-in" className="font-bold hover:underline">
                Sign in now
              </a>
        </div>
      </div>
    </>
  );
};

export default AccountSetup;
