import axios from "axios";
import { BACKEND_URL } from "../config/config";
import { API_VERSION } from "config/prices";

const path = "inbox-placement-tests";

const getInboxPlacementTest = async (
  token,
  organizationId,
  id,
  filters = {}
) => {
  const queryParams = new URLSearchParams({
    token,
    organizationId,
    ...filters,
  });
  const response = await axios.get(
    `${BACKEND_URL}/api/${API_VERSION}/${path}/${id}?${queryParams.toString()}`
  );
  return response.data;
};

const getAllInboxPlacementTests = async (
  token,
  organizationId,
  filters = {}
) => {
  const queryParams = new URLSearchParams({
    token,
    organizationId,
    ...filters,
  });

  const response = await axios.get(
    `${BACKEND_URL}/api/${API_VERSION}/${path}?${queryParams.toString()}`
  );

  return response.data;
};

const createInboxPlacementTest = async (token, organizationId, data) => {
  const response = await axios.post(
    `${BACKEND_URL}/api/${API_VERSION}/${path}?token=${token}&organizationId=${organizationId}`,
    data
  );
  return response.data;
};

export {
  getAllInboxPlacementTests,
  createInboxPlacementTest,
  getInboxPlacementTest,
};
