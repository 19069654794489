// TODO: orders page domain view and explanation
// REstivtion on input like domain and email sequncer empty
// Subscriptions
const API_VERSION = "v1";
// ##################################
// # Subscriptions
const WARMY_OUTLOOK_PRICE_ID = process.env.REACT_APP_WARMY_OUTLOOK_PRICE_ID;
const WARMY_GOOGLE_PRICE_ID = process.env.REACT_APP_WARMY_GOOGLE_PRICE_ID;
// ##################################
// # Low sending volume - ALL
// # Discounted
const LSV_OUTLOOK_79_PRICE_ID = process.env.REACT_APP_LSV_OUTLOOK_79_PRICE_ID;
const LSV_OUTLOOK_149_PRICE_ID = process.env.REACT_APP_LSV_OUTLOOK_149_PRICE_ID;
// # Low sending volume
const LSV_SMTP_PRICE_ID = process.env.REACT_APP_LSV_SMTP_PRICE_ID;
const LSV_70MS_30G_PRICE_ID = process.env.REACT_APP_LSV_70MS_30G_PRICE_ID;
const LSV_70G_30MS_PRICE_ID = process.env.REACT_APP_LSV_70G_30MS_PRICE_ID;
const LSV_50G_50MS_PRICE_ID = process.env.REACT_APP_LSV_50G_50MS_PRICE_ID;
const LSV_OUTLOOK_PRICE_ID = process.env.REACT_APP_LSV_OUTLOOK_PRICE_ID;
const LSV_GOOGLE_INBOXES_PRICE_ID =
  process.env.REACT_APP_LSV_GOOGLE_INBOXES_PRICE_ID;
// Medium Sending Volume
const MSV_70MS_30G_PRICE_ID = process.env.REACT_APP_MSV_70MS_30G_PRICE_ID;
const MSV_70G_30MS_PRICE_ID = process.env.REACT_APP_MSV_70G_30MS_PRICE_ID;
const MSV_50G_50MS_PRICE_ID = process.env.REACT_APP_MSV_50G_50MS_PRICE_ID;
const MSV_OUTLOOK_PRICE_ID = process.env.REACT_APP_MSV_OUTLOOK_PRICE_ID;
const MSV_GOOGLE_INBOXES_PRICE_ID =
  process.env.REACT_APP_MSV_GOOGLE_INBOXES_PRICE_ID;
// High Sending Volume
const HSV_70MS_30G_PRICE_ID = process.env.REACT_APP_HSV_70MS_30G_PRICE_ID;
const HSV_70G_30MS_PRICE_ID = process.env.REACT_APP_HSV_70G_30MS_PRICE_ID;
const HSV_50G_50MS_PRICE_ID = process.env.REACT_APP_HSV_50G_50MS_PRICE_ID;
const HSV_OUTLOOK_PRICE_ID = process.env.REACT_APP_HSV_OUTLOOK_PRICE_ID;
const HSV_GOOGLE_INBOXES_PRICE_ID =
  process.env.REACT_APP_HSV_GOOGLE_INBOXES_PRICE_ID;
// ##################################
// # One time payments
const SWAP_DOMAIN_PRICE_ID = process.env.REACT_APP_SWAP_DOMAIN_PRICE_ID;
const INSTANTLY_UPLOAD_PRICE_ID =
  process.env.REACT_APP_INSTANTLY_UPLOAD_PRICE_ID;
// Other
const HSV_50G_50MS_YEARLY_PRICE_ID=process.env.REACT_APP_HSV_50G_50MS_YEARLY_PRICE_ID
// ##################################
// # Archieved
const ARCH_LSV_70G_30MS_1_PRICE_ID =
  process.env.REACT_APP_ARCH_LSV_70G_30MS_1_PRICE_ID;
const ARCH_LSV_OUTLOOK_149_1_PRICE_ID =
  process.env.REACT_APP_ARCH_LSV_OUTLOOK_149_1_PRICE_ID;
const ARCH_LSV_OUTLOOK_149_2_PRICE_ID =
  process.env.REACT_APP_ARCH_LSV_OUTLOOK_149_2_PRICE_ID;
const ARCH_LSV_OUTLOOK_1_PRICE_ID =
  process.env.REACT_APP_ARCH_LSV_OUTLOOK_1_PRICE_ID;
// ####################################################################
const WARMY_OUTLOOK_NAME = "Warmy.io For Outlook";
const WARMY_GOOGLE_NAME = "Warmy.io For Google";

const WARMY_GOOGLE = {
  id: "WARMY",
  reason: "Warmy Google Inboxes",
  mode: "subscription",
  name: WARMY_GOOGLE_NAME,
  price: 2,
  type: "per inboxes per month",
  domain: 1,
  image: "https://www.warmy.io/images/logo/logo.svg",
};

const WARMY_OUTLOOK = {
  id: "WARMY",
  reason: "Warmy Outlook Inboxes",
  mode: "subscription",
  name: WARMY_OUTLOOK_NAME,
  price: 25,
  type: "49 inboxes per month",
  domain: 1,
  image: "https://www.warmy.io/images/logo/logo.svg",
};

const GOOGLE_ADDONS = {
  [WARMY_GOOGLE_PRICE_ID]: WARMY_GOOGLE,
};

const OUTLOOK_ADDONS = {
  [WARMY_OUTLOOK_PRICE_ID]: WARMY_OUTLOOK,
  [INSTANTLY_UPLOAD_PRICE_ID]: {
    id: "INSTANTLY_UPLOAD",
    reason: "Instantly Setup 4 Domain",
    mode: "payment",
    name: "Instantly Setup (Per Package)",
    price: 50,
    type: "one-time",
    domain: 4,
    image:
      "https://media.licdn.com/dms/image/v2/D4D0BAQGxvB5umA6JPA/company-logo_200_200/company-logo_200_200/0/1713263394482/instantlyapp_logo?e=2147483647&v=beta&t=5TVlFElks7yzqZmHlCIR0F24aKEQJpsJXhIWaOvJ1y4",
  },
};

const MIXED_ADDONS = {
  [WARMY_GOOGLE_PRICE_ID]: WARMY_GOOGLE,
  [WARMY_OUTLOOK_PRICE_ID]: WARMY_OUTLOOK,
};

const LSV_70G_30MS = {
  id: "MIXED_PACKAGE",
  name: "SM Google 70% - SM MS 30%",
  reason: "Low Sending Volume - 70G / 30MS",
  mode: "subscription",
  price: 189,
  domains: 12,
  features: [
    "20 GSuite + 98 MS = 118 Mailboxes",
    "Up To 24,000 Emails Per Month",
  ],
  google: {
    mailbox: 20,
  },
  microsoft: {
    mailbox: 98,
    domains: 2,
  },
  frequency: "/monthly",
  link: "/mixed-order",
  addons: MIXED_ADDONS,
};

const LSV_OUTLOOK_4_DOMAINS = {
  id: "SCALEDMAIL",
  reason: "Scaledmail",
  mode: "subscription",
  name: "SM - Microsoft",
  price: 199,
  domains: 4,
  microsoft: {
    mailbox: 196,
    domains: 4,
  },
  features: ["196 MS Mailboxes", "Up To 29,400 Emails Per Month"],
  link: "/outlook-order",
  frequency: "/monthly",
  addons: OUTLOOK_ADDONS,
};
const LSV_OUTLOOK_149 = {
  [LSV_OUTLOOK_149_PRICE_ID]: { ...LSV_OUTLOOK_4_DOMAINS, price: 149 },
};



const LSV_OUTLOOK_79 = {
  [LSV_OUTLOOK_79_PRICE_ID]: {
    id: "SCALEDMAIL",
    reason: "Scaledmail",
    mode: "subscription",
    name: "SM - Microsoft",
    price: 79,
    domains: 1,
    microsoft: {
      mailbox: 49,
      domains: 1,
    },
    features: ["49 MS Mailboxes", "Up To 7,350 Emails Per Month"],
    link: "/outlook-order",
    frequency: "/monthly",
    // FIXME:
    // addons: OUTLOOK_ADDONS,
  },
};

const HSV_50G_50MS={
  id: "MIXED_PACKAGE",
  reason: "High Sending Volume - 50G / 50MS",
  name: "SM MS 50% - SM Google 50%",
  mode: "subscription",
  price: 807,
  domains: 47,
  features: [
    "70 GSuite + 588 MS = 658 Mailboxes",
    "Up To 120,000 Emails Per Month",
  ],
  google: {
    mailbox: 70,
  },
  microsoft: {
    mailbox: 588,
    domains: 12,
  },
  frequency: "/monthly",
  link: "/mixed-order",
  addons: MIXED_ADDONS,
}

const OTHER={
 [HSV_50G_50MS_YEARLY_PRICE_ID]: HSV_50G_50MS
}


const LSV_MAPPING = {
  [LSV_GOOGLE_INBOXES_PRICE_ID]: {
    id: "GOOGLE_INBOX",
    reason: "Google Inboxes",
    mode: "subscription",
    name: "SM - Google",
    price: 120,
    domains: 15,
    google: {
      mailbox: 30,
    },
    features: ["30 GSuite Mailboxes", "Up To 13,000 Emails Per Month"],
    link: "/google-order",
    frequency: "/monthly",
    addons: GOOGLE_ADDONS,
  },
  [LSV_OUTLOOK_PRICE_ID]: LSV_OUTLOOK_4_DOMAINS,
  [LSV_50G_50MS_PRICE_ID]: {
    id: "MIXED_PACKAGE",
    reason: "Low Sending Volume - 50G / 50MS",
    mode: "subscription",
    name: "SM MS 50% - SM Google 50%",
    price: 259,
    domains: 14,
    features: [
      "20 GSuite + 196 MS = 216 Mailboxes",
      "Up To 38,400 Emails Per Month",
    ],
    google: {
      mailbox: 20,
    },
    microsoft: {
      mailbox: 196,
      domains: 4,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
  [LSV_70MS_30G_PRICE_ID]: {
    id: "MIXED_PACKAGE",
    name: "SM MS 70% - SM Google 30%",
    reason: "Low Sending Volume - 30G / 70MS",
    mode: "subscription",
    price: 229,
    domains: 9,
    features: [
      "10 GSuite + 196 MS = 206 Mailboxes",
      "Up To 33,900 Emails Per Month",
    ],
    google: {
      mailbox: 10,
    },
    microsoft: {
      mailbox: 196,
      domains: 4,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
  [LSV_70G_30MS_PRICE_ID]: LSV_70G_30MS,
  [LSV_SMTP_PRICE_ID]: {
    new: true,
    id: "SMTP",
    reason: "Scaledmail",
    mode: "subscription",
    name: "SM - SMTP",
    price: 45,
    domains: 12,
    per_domain:4,
    microsoft: {
      mailbox: 48,
      domains: 12,
    },
    features: ["48 SMTP Mailboxes", "Up To 14,400 Emails Per Month"],
    link: "/outlook-order",
    frequency: "/monthly",
    // addons: OUTLOOK_ADDONS,
  },
};

const MSV_MAPPING = {
  [MSV_GOOGLE_INBOXES_PRICE_ID]: {
    id: "GOOGLE_INBOX",
    reason: "Google Inboxes",
    mode: "subscription",
    name: "SM - Google",
    price: 175,
    domains: 25,
    google: {
      mailbox: 50,
    },
    features: ["50 GSuite mailboxes", "Up To 22,500 Emails Per Month"],
    link: "/google-order",
    frequency: "/monthly",
    addons: GOOGLE_ADDONS,
  },
  [MSV_OUTLOOK_PRICE_ID]: {
    id: "SCALEDMAIL",
    reason: "Scaledmail",
    mode: "subscription",
    name: "SM - Microsoft",
    price: 398,
    domains: 8,
    microsoft: {
      mailbox: 392,
      domains: 8,
    },
    features: ["392 MS Mailboxes", "Up To 58,800 Emails Per Month"],
    link: "/outlook-order",
    frequency: "/monthly",
    addons: OUTLOOK_ADDONS,
  },
  [MSV_50G_50MS_PRICE_ID]: {
    id: "MIXED_PACKAGE",
    reason: "Medium Sending Volume - 50G / 50MS",
    mode: "subscription",
    name: "SM MS 50% - SM Google 50%",
    price: 518,
    domains: 28,
    features: [
      "40 GSuite + 392 MS = 432 Mailboxes",
      "Up To 76,800 Emails Per Month",
    ],
    google: {
      mailbox: 40,
    },
    microsoft: {
      mailbox: 392,
      domains: 8,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
  [MSV_70MS_30G_PRICE_ID]: {
    id: "MIXED_PACKAGE",
    name: "SM MS 70% - SM Google 30%",
    reason: "Medium Sending Volume - 30G / 70MS",
    mode: "subscription",
    price: 458,
    domains: 18,
    features: [
      "20 GSuite + 392 MS = 412 Mailboxes",
      "Up To 67,800 Emails Per Month",
    ],
    google: {
      mailbox: 20,
    },
    microsoft: {
      mailbox: 392,
      domains: 8,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
  [MSV_70G_30MS_PRICE_ID]: {
    id: "MIXED_PACKAGE",
    name: "SM Google 70% - SM MS 30%",
    reason: "Medium Sending Volume - 70G / 30MS",
    mode: "subscription",
    price: 319,
    domains: 24,
    features: [
      "40 GSuite + 196  MS = 236 Mailboxes",
      "Up To 47,400 Emails Per Month",
    ],
    google: {
      mailbox: 40,
    },
    microsoft: {
      mailbox: 196,
      domains: 4,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
};


const HSV_MAPPING = {
  [HSV_GOOGLE_INBOXES_PRICE_ID]: {
    id: "GOOGLE_INBOX",
    reason: "Google Inboxes",
    mode: "subscription",
    name: "SM - Google",
    price: 300,
    domains: 50,
    google: {
      mailbox: 100,
    },
    features: ["100 mailboxes", "Up To 45,000 Emails Per Month"],
    link: "/google-order",
    frequency: "/monthly",
    addons: GOOGLE_ADDONS,
  },
  [HSV_OUTLOOK_PRICE_ID]: {
    id: "SCALEDMAIL",
    reason: "Scaledmail",
    mode: "subscription",
    name: "SM - Microsoft",
    price: 597,
    domains: 12,
    microsoft: {
      mailbox: 588,
      domains: 12,
    },
    features: ["588 Mailboxes", "Up To 88,200 Emails Per Month"],
    link: "/outlook-order",
    frequency: "/monthly",
    addons: OUTLOOK_ADDONS,
  },
  [HSV_50G_50MS_PRICE_ID]: HSV_50G_50MS,
  [HSV_70MS_30G_PRICE_ID]: {
    id: "MIXED_PACKAGE",
    name: "SM MS 70% - SM Google 30%",
    reason: "High Sending Volume - 30G / 70MS",
    price: 717,
    domains: 32,
    mode: "subscription",
    features: [
      "40 GSuite + 588 MS = 628 Mailboxes",
      "Up To 71,280 Emails Per Month",
    ],
    google: {
      mailbox: 40,
    },
    microsoft: {
      mailbox: 588,
      domains: 12,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
  [HSV_70G_30MS_PRICE_ID]: {
    id: "MIXED_PACKAGE",
    name: "SM Google 70% - SM MS 30%",
    reason: "High Sending Volume - 70G / 30MS",
    price: 638,
    domains: 52,
    mode: "subscription",
    features: [
      "80 GSuite + 392 MS = 472 Mailboxes",
      "Up To 94,800 Emails Per Month",
    ],
    google: {
      mailbox: 80,
    },
    microsoft: {
      mailbox: 392,
      domains: 8,
    },
    frequency: "/monthly",
    link: "/mixed-order",
    addons: MIXED_ADDONS,
  },
};

const ARCHIEVED_PACKAGES = {
  [ARCH_LSV_70G_30MS_1_PRICE_ID]: LSV_70G_30MS,
  [ARCH_LSV_OUTLOOK_149_1_PRICE_ID]: LSV_OUTLOOK_149[LSV_OUTLOOK_149_PRICE_ID],
  [ARCH_LSV_OUTLOOK_149_2_PRICE_ID]: LSV_OUTLOOK_149[LSV_OUTLOOK_149_PRICE_ID],
  [ARCH_LSV_OUTLOOK_1_PRICE_ID]: LSV_OUTLOOK_149[LSV_OUTLOOK_149_PRICE_ID],
};

const PRICE_MAPPING = {
  // Mixed Packages
  ...LSV_MAPPING,
  ...MSV_MAPPING,
  ...HSV_MAPPING,
  // Addons
  ...GOOGLE_ADDONS,
  ...OUTLOOK_ADDONS,
  ...MIXED_ADDONS,
  // Discounted
  ...LSV_OUTLOOK_149,
  ...LSV_OUTLOOK_79,
  // Other
  ...OTHER
  // [SWAP_DOMAIN_PRICE_ID]: {
  //   id: "DOMAIN_SWAP",
  //   reason: "Domain Swap",
  //   name: "Domain Swap",
  //   mode: "payment",
  //   price: 25,
  //   domain: 1,
  // },
  // Old package for old subscriptions
  // ...ARCHIEVED_PACKAGES,
};

module.exports = {
  PRICE_MAPPING,

  LSV_MAPPING,
  MSV_MAPPING,
  HSV_MAPPING,

  WARMY_GOOGLE_PRICE_ID,
  WARMY_OUTLOOK_PRICE_ID,

  SWAP_DOMAIN_PRICE_ID,
  INSTANTLY_UPLOAD_PRICE_ID,

  API_VERSION,
  WARMY_GOOGLE_NAME,
  WARMY_OUTLOOK_NAME,

  LSV_OUTLOOK_PRICE_ID,
  LSV_OUTLOOK_149_PRICE_ID,
  LSV_OUTLOOK_79_PRICE_ID,
  LSV_OUTLOOK_149,
  LSV_OUTLOOK_79,
};
