import React from "react";
import { Check } from "lucide-react";
import { cn } from "lib/utils";
import { Progress } from "components/ui/progress";

interface Step {
  number: number;
  title: string;
  description: string;
  icon: React.ElementType;
}

interface ProgressStepsProps {
  currentStep: number;
  steps: Step[];
}

const ProgressSteps: React.FC<ProgressStepsProps> = ({
  currentStep,
  steps,
}) => {
  // Calculate overall progress percentage
  const progressPercentage = ((currentStep - 1) / (steps.length - 1)) * 100;

  return (
    <div className="w-full py-8 px-4 animate-fade-in">
      <div className="max-w-4xl mx-auto">
        {/* Overall progress indicator */}
        <div className="mb-8">
          <Progress value={progressPercentage} className="h-2 bg-gray-100" />
        </div>

        {/* Steps row */}
        <div className={`grid gap-4 grid-cols-${steps.length}`}>
          {steps.map((step) => {
            const StepIcon = step.icon;
            const isCompleted = currentStep > step.number;
            const isCurrent = currentStep === step.number;
            const isUpcoming = currentStep < step.number;

            return (
              <div
                key={step.number}
                className={cn(
                  "flex flex-col items-center transition-all duration-300",
                  isCompleted && "opacity-90",
                  isCurrent && "scale-105",
                  isUpcoming && "opacity-60"
                )}
              >
                {/* Step status indicator */}
                <div
                  className={cn(
                    "w-14 h-14 mb-3 rounded-lg flex items-center justify-center shadow-sm",
                    isCompleted && "bg-brand text-white",
                    isCurrent && "bg-white ring-2 ring-brand text-brand",
                    isUpcoming &&
                      "bg-gray-50 text-gray-400 border border-gray-200"
                  )}
                >
                  {isCompleted ? (
                    <Check className="w-6 h-6" strokeWidth={3} />
                  ) : (
                    <StepIcon className="w-6 h-6" />
                  )}
                </div>

                {/* Step information */}
                <div className="text-center">
                  <h3
                    className={cn(
                      "font-semibold text-base",
                      isCompleted && "text-gray-800",
                      isCurrent && "text-brand",
                      isUpcoming && "text-gray-500"
                    )}
                  >
                    {step.title}
                  </h3>
                  <p
                    className={cn(
                      "text-xs mt-1",
                      isCompleted && "text-gray-600",
                      isCurrent && "text-gray-800",
                      isUpcoming && "text-gray-400"
                    )}
                  >
                    {step.description}
                  </p>
                </div>

                {/* Mobile step number indicator */}
                <div className="md:hidden mt-2">
                  <span
                    className={cn(
                      "inline-flex items-center justify-center w-5 h-5 text-xs rounded-full font-medium",
                      isCompleted && "bg-brand/20 text-brand",
                      isCurrent && "bg-brand text-white",
                      isUpcoming && "bg-gray-200 text-gray-500"
                    )}
                  >
                    {step.number}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProgressSteps;
