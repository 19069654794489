import {
  Globe,
  ArrowLeft,
  ArrowRight,
  ShoppingCart,
  Info,
  Loader2,
} from "lucide-react";
import { Button } from "components/ui/button";
import { toast } from "components/ui/use-toast";
import { fetchDomainPrice } from "services/domainService";
import DomainGenerator from "components/DomainGenerator/DomainGenerator";
import { useEffect } from "react";

export default function DomainSelection({
  handleBack,
  handleNext,
  totalDomains,
  selectedDomains,
  setSelectedDomains,
  totalDomainPrice,
  setTotalDomainPrice,
  userObj,
}) {
  useEffect(() => {
    const p = selectedDomains.reduce((sum, domain) => sum + domain.price, 0);
    setTotalDomainPrice(p);
  }, [selectedDomains]);

  const handleAddDomain = async (domain) => {
    if (selectedDomains.some((d) => d.domain === domain.domain)) {
      toast({
        title: "Domain already in basket",
        description: `${domain.domain} is already in your basket.`,
        variant: "destructive",
      });
      return;
    }

    if (totalDomains != null && selectedDomains.length === totalDomains) {
      toast({
        title: `Limit Reached, max domains ${totalDomains}`,
        description: `Max ${totalDomains} domains allowed. Remove one or proceed checkout.`,
        variant: "destructive",
      });
      return;
    }

    setSelectedDomains([...selectedDomains, domain]);
    toast({
      title: "Domain added to basket",
      description: `${domain.domain} has been added to your basket.`,
    });
    try {
      // const price = await fetchDomainPrice(userObj, domain.domain);
      // const updatedDomain = { ...domain, price };
      // setSelectedDomains((prev) =>
      //   prev.map((d) => (d.domain === domain.domain ? updatedDomain : d))
      // );
      
    } catch (err) {
      console.error(err);
    }
  };

  const handleRemoveDomain = (domain) => {
    setSelectedDomains(
      selectedDomains.filter((d) => d.domain !== domain.domain)
    );
    toast({
      title: "Domain removed",
      description: `${domain.domain} has been removed from your basket.`,
    });
  };

  const handleSkipStep = () => {
    setSelectedDomains([]);
    toast({
      title: "Using your own domains",
      description: "You'll be able to add your domains later in the process.",
    });
    setTimeout(() => {
      handleNext();
    }, 300);
  };

  const handleContinueWithSelected = () => {
    if (selectedDomains.length === 0) {
      toast({
        title: "No domains selected",
        description:
          "Please select at least one domain to continue or skip this step.",
        variant: "destructive",
      });
      return;
    }

    handleNext();
  };

  return (
    <div className="max-w-5xl mx-auto bg-white-A700rounded-lg shadow-sm border border-gray-200 p-6">
      <div className="mb-6 items-center flex flex-col">
        <h1 className="text-2xl font-bold text-gray-800 text-center">
          Choose your domains
        </h1>
        {/* <p className="text-gray-600 text-center mt-2">
            Select domains from our suggestions or skip this step to use your own
            domains
          </p> */}
        {totalDomains != null && (
          <p className="text-gray-900 font-bold self-center flex items-center gap-x-1">
            <Info className="w-5" /> Total {totalDomains} domains required for
            the selected subscription
          </p>
        )}
      </div>

      {/* Skip Step Button */}
      {/* <div className="flex justify-center mb-6">
          <Button
            variant="outline"
            onClick={handleSkipStep}
            className="flex items-center gap-2 text-emerald-500 border-emerald-200 hover:bg-emerald-50"
          >
            Use your own domains - Skip this step
          </Button>
        </div> */}

      {/* Domain Generator Title */}
      <div className="p-4 bg-emerald-50 rounded-lg mb-6">
        <div className="flex items-start">
          <div className="rounded-full bg-emerald-100 p-2 mr-3">
            <Globe className="h-5 w-5 text-emerald-500" />
          </div>
          <div>
            <h3 className="font-medium text-gray-800">Generate Domain Names</h3>
            <p className="text-sm text-gray-600">
              Type a keyword to generate domain suggestions. Click "Add to
              Basket" on any domain you want to purchase.
            </p>
          </div>
        </div>
      </div>

      {/* Embedded Domain Generator with light theme */}
      <div className="domainGeneratorLight mb-8">
        <DomainGenerator onSelectDomain={handleAddDomain} userObj={userObj} />
      </div>

      {/* Selected Domains Section */}
      <div className="border-t border-gray-200 pt-6">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-medium text-gray-800">
            Selected Domains{" "}
            {totalDomains != null
              ? selectedDomains.length / totalDomains
              : selectedDomains.length}
          </h2>
          <div className="flex items-center">
            <ShoppingCart className="h-5 w-5 text-gray-500 mr-2" />
            <span className="text-sm font-medium text-gray-500">
              {selectedDomains.length} domain
              {selectedDomains.length !== 1 ? "s" : ""}
            </span>
          </div>
        </div>

        {selectedDomains.length > 0 ? (
          <div className="mb-6">
            <div className="grid grid-cols-2">
              {selectedDomains.map((domain, index) => (
                <div
                  key={`${domain.domain}-${index}`}
                  className="m-1 flex items-center justify-between p-3 rounded-md border overflow-hidden border-gray-200 bg-white-A700"
                >
                  <div className="flex items-center">
                    <span className="font-medium">{domain.domain}</span>
                  </div>
                  <div className="flex items-center gap-4">
                    {domain.price === 0 ? (
                      <div className="flex items-center">
                        <Loader2 className="h-3 w-3 mr-1 animate-spin" />
                        Checking
                      </div>
                    ) : (
                      <span className="font-medium">
                        ${domain.price.toFixed(2)}/year
                      </span>
                    )}
                    <button
                      onClick={() => handleRemoveDomain(domain)}
                      className="text-gray-400 hover:text-red-500 transition-colors"
                      aria-label="Remove domain"
                    >
                      <span className="sr-only">Remove</span>
                      &times;
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex justify-between items-center pt-3 border-t border-gray-200 mt-4">
              <span className="font-medium text-gray-800">Total</span>
              <span className="font-bold text-gray-900">
                ${totalDomainPrice}/year
              </span>
            </div>
          </div>
        ) : (
          <div className="text-center py-10 bg-gray-50 rounded-md">
            <div className="mx-auto w-12 h-12 rounded-full bg-gray-100 flex items-center justify-center mb-3">
              <ShoppingCart className="h-6 w-6 text-gray-400" />
            </div>
            <h3 className="text-gray-500 font-medium mb-1">
              No domains selected
            </h3>
            <p className="text-gray-400 text-sm">
              Generate and select domains to add them to your basket or skip
              this step
            </p>
          </div>
        )}
      </div>

      {/* Action Buttons */}
      <div className="flex justify-between mt-8">
        <Button
          variant="outline"
          className="text-gray-600 border-gray-300"
          onClick={handleBack}
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back
        </Button>

        <Button
          onClick={handleContinueWithSelected}
          className="bg-emerald-500 hover:bg-emerald-600 text-white-A700"
          disabled={
            totalDomains != null
              ? selectedDomains.length !== totalDomains
              : selectedDomains.length===0
          }
        >
          Continue to Checkout
          <ArrowRight className="ml-2 h-4 w-4" />
        </Button>
      </div>
    </div>
  );
}
