import {
  ArrowUpRightIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  MagnifyingGlassCircleIcon,
} from "@heroicons/react/24/outline";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "components/Accordion";

const Beanstalk = () => {
  return (
    <div className="space-y-10 overflow-y-auto max-h-[80vh] pr-2">
      {/* The Story Section */}
      <section className="mb-10">
        <h2 className="text-2xl font-bold mb-6 text-slate-800 flex items-center">
          <span className="bg-indigo-50 text-teal-600 p-2 rounded-lg mr-3">
            <ArrowUpRightIcon className="h-5 w-5" />
          </span>
          The Beanstalk & ScaledMail Story
        </h2>
        <div className="prose max-w-none">
          <p className="text-slate-600 mb-5 leading-relaxed">
            ScaledMail was born out of necessity. As Beanstalk Consulting
            perfected outbound campaigns for our clients, we discovered a
            critical gap in the market: reliable, high-deliverability email
            infrastructure built specifically for cold outreach.
          </p>
          <p className="text-slate-600 mb-5 leading-relaxed">
            What began as our internal solution quickly became the secret weapon
            behind our clients' success. After seeing unprecedented
            deliverability rates and campaign performance, we made the decision
            to spin out ScaledMail as a standalone offering, enabling businesses
            everywhere to access the same enterprise-grade infrastructure that
            powers our managed services.
          </p>
          <p className="text-slate-600 leading-relaxed">
            Today, you're using the exact same technology that helps our
            full-service clients generate millions in pipeline. Now, you have
            two ways to take your results even further.
          </p>
        </div>
      </section>

      <div className="border-t border-indigo-100 my-10"></div>

      {/* Maximize Results Section */}
      <section className="text-center mb-10">
        <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-teal-600 to-teal-500">
          Already have our mailboxes? Let's maximize your results.
        </h2>
      </section>

      <div className="border-t border-indigo-100 my-10"></div>

      {/* Options Grid */}
      <div className="grid grid-cols-2 gap-8">
        {/* Service 1 */}
        <div className="rounded-xl overflow-hidden group">
          <div className="bg-gradient-to-b from-white to-slate-50 p-8 border border-indigo-100 shadow-sm hover:shadow-md transition-all duration-300 rounded-xl h-full">
            <div className="mb-6 bg-teal-50 p-3 rounded-xl flex items-center gap-x-3 text-teal-300">
              <InformationCircleIcon className="w-10 " />
              <h3 className="text-xl font-bold  group-hover:text-teal-600 transition-colors">
                Expert Guidance, When You Need It
              </h3>
            </div>
            <h4 className="text-lg font-semibold text-slate-700 mb-5">
              Power Hour with Leonardo, Director of Outbound
            </h4>
            <p className="text-slate-600 mb-5 leading-relaxed">
              You've got the infrastructure. Now get the expertise that drives
              results.
            </p>
            <p className="text-slate-600 mb-6 leading-relaxed">
              Book a focused consulting session with Leonardo, Beanstalk's
              Director of Outbound, and transform your campaign performance.
              With over a decade of experience optimizing cold outreach
              campaigns and the architect behind many of ScaledMail's core
              strategies, Leo brings unparalleled insights to solve your most
              pressing outbound challenges.
            </p>

            <h5 className="font-bold mb-3 text-slate-700 flex items-center">
              <span className="text-teal-500 mr-2">
                <CheckCircleIcon className="h-4 w-4" />
              </span>
              What You'll Get:
            </h5>
            <ul className="mb-6 text-slate-600 space-y-2">
              {[
                "Strategic campaign review and optimization",
                "Advanced Clay integration techniques",
                "Personalization strategies that increase response rates",
                "Deliverability troubleshooting and best practices",
                "Custom solutions for your specific outbound challenges",
              ].map((item) => (
                <li key={item} className="flex items-start">
                  <span className="text-teal-500 mr-2 mt-1">
                    <CheckCircleIcon className="h-4 w-4" />
                  </span>
                  <span>{item}</span>
                </li>
              ))}
            </ul>

            <h5 className="font-bold mb-3 text-slate-700 flex items-center">
              <span className="text-teal-500 mr-2">
                <CheckCircleIcon className="h-4 w-4" />
              </span>
              Perfect for teams who:
            </h5>
            <ul className="mb-8 text-slate-600 space-y-2">
              {[
                "Have campaigns running but aren't seeing desired results",
                "Need expert help with specific technical challenges",
                "Want to validate their outbound strategy",
                "Are looking to implement advanced personalization at scale",
              ].map((item) => (
                <li key={item} className="flex items-start">
                  <span className="text-teal-500 mr-2 mt-1">
                    <CheckCircleIcon className="h-4 w-4" />
                  </span>
                  <span>{item}</span>
                </li>
              ))}
            </ul>

            <div className="mt-8">
              <a
                className={`group relative  overflow-hidden rounded-md
        px-6 py-2.5 font-medium tracking-wide
        bg-gradient-to-r from-teal-300 to-green-400
        text-white shadow-lg transition-all duration-300
        hover:shadow-teal-500/25 hover:scale-[1.01]
        border border-teal-700/10`}
                href="https://buy.stripe.com/bIYg0UdBW0Yv4uI4gQ"
                target="_blank"
              >
                <span className="absolute inset-0 w-full h-full bg-gradient-to-br from-teal-500/20 to-green-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
                <span className="flex items-center justify-center w-full h-full relative z-10">
                  <span className="mr-2"> Book Your Power Hour: $299</span>
                  <ArrowUpRightIcon className="w-5 h-5 transition-all duration-300 group-hover:translate-x-1" />
                </span>
              </a>
            </div>
          </div>
        </div>

        {/* Service 2 */}
        <div className="rounded-xl overflow-hidden group">
          <div className="bg-gradient-to-b from-white to-slate-50 p-8 border border-indigo-100 shadow-sm hover:shadow-md transition-all duration-300 rounded-xl h-full">
            <div className="mb-6 bg-teal-50 p-3 rounded-xl flex items-center gap-x-3 text-teal-300">
              <MagnifyingGlassCircleIcon className="w-10 " />
              <h3 className="text-xl font-bold  group-hover:text-teal-600 transition-colors">
                Fully Managed Outbound - We Handle Everything
              </h3>
            </div>

            <h4 className="text-lg font-semibold text-slate-700 mb-5">
              Tired of DIY? Let our experts drive your outbound success.
            </h4>
            <p className="text-slate-600 mb-6 leading-relaxed">
              Stop struggling with the complexities of outbound campaigns.
              Beanstalk Consulting—the team that built ScaledMail from the
              ground up—offers end-to-end managed services that take you from
              idea to qualified meetings with zero hassle.
            </p>

            <h5 className="font-bold mb-3 text-slate-700 flex items-center">
              <span className="text-teal-500 mr-2">
                <CheckCircleIcon className="h-4 w-4" />
              </span>
              Our end-to-end process includes:
            </h5>
            <ul className="mb-6 text-slate-600 space-y-2">
              {[
                "Deep-dive strategy session to understand your ideal customer profile",
                "AI-driven prospect list building and enrichment",
                "Highly personalized messaging that resonates with your targets",
                "Complete campaign setup and management",
                "Daily optimization and performance reporting",
                "Qualified meeting delivery directly to your calendar",
              ].map((item) => (
                <li key={item} className="flex items-start">
                  <span className="text-teal-500 mr-2 mt-1">
                    <CheckCircleIcon className="h-4 w-4" />
                  </span>
                  <span>{item}</span>
                </li>
              ))}
            </ul>

            <h5 className="font-bold mb-3 text-slate-700 flex items-center">
              <span className="text-teal-500 mr-2">
                <CheckCircleIcon className="h-4 w-4" />
              </span>
              Perfect for teams who:
            </h5>
            <ul className="mb-8 text-slate-600 space-y-2">
              {[
                "Want to focus on closing deals, not prospecting",
                "Have tried DIY outbound with mixed results",
                "Need a predictable pipeline of qualified opportunities",
                "Are looking to scale their outbound efforts without hiring",
              ].map((item) => (
                <li key={item} className="flex items-start">
                  <span className="text-teal-500 mr-2 mt-1">
                    <CheckCircleIcon className="h-4 w-4" />
                  </span>
                  <span>{item}</span>
                </li>
              ))}
            </ul>

            <div className="mt-8">
              <a
                className={`group relative  overflow-hidden rounded-md
        px-6 py-2.5 font-medium tracking-wide
        bg-gradient-to-r from-teal-300 to-green-400
        text-white shadow-lg transition-all duration-300
        hover:shadow-teal-500/25 hover:scale-[1.01]
        border border-teal-700/10`}
                href="https://app.reclaim.ai/m/dean-fiacco/beanstalk-consultation"
                target="_blank"
              >
                <span className="absolute inset-0 w-full h-full bg-gradient-to-br from-teal-500/20 to-green-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
                <span className="flex items-center justify-center w-full h-full relative z-10">
                  <span className="mr-2">
                    {" "}
                    Schedule Your Growth Strategy Session
                  </span>
                  <ArrowUpRightIcon className="w-5 h-5 transition-all duration-300 group-hover:translate-x-1" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="border-t border-indigo-100 my-10"></div>

      {/* FAQ Section */}
      <section className="bg-gradient-to-b from-white to-slate-50 rounded-xl shadow-sm border border-indigo-100 p-8 my-10">
        <h2 className="text-2xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-teal-600 to-teal-500 text-center">
          Frequently Asked Questions
        </h2>

        <Accordion type="single" collapsible className="w-full">
          {[
            {
              question:
                "What makes ScaledMail different from other email services?",
              answer:
                "ScaledMail was built specifically for cold outreach, with enterprise-grade infrastructure designed to maximize deliverability. Unlike generic email services, our platform was developed by outbound experts who understand the unique challenges of cold email campaigns.",
            },
            {
              question:
                "How soon can I expect to see results from a Power Hour session?",
              answer:
                "Many clients see immediate improvements after implementing Leonardo's recommendations. Typically, you can expect to see measurable improvements in open and response rates within 2-3 weeks of making the suggested changes to your campaigns.",
            },
            {
              question: "What does the fully managed service cost?",
              answer:
                "Our fully managed service is customized based on your specific needs, target audience, and campaign complexity. Pricing starts at $2,500/month with performance-based options available. Schedule a Growth Strategy Session for a detailed quote tailored to your business.",
            },
            {
              question:
                "Can I use my existing content with your managed service?",
              answer:
                "Absolutely! We're happy to use your existing content as a starting point. Our team will review and optimize it based on our experience and best practices, ensuring it performs at its best while maintaining your brand voice and key messaging.",
            },
            {
              question: "How do you measure campaign success?",
              answer:
                "We track multiple metrics including deliverability, open rates, response rates, meeting bookings, and ultimately, pipeline generated. Our reporting dashboard gives you full visibility into campaign performance, and we have regular check-ins to review results and make strategic adjustments.",
            },
          ].map((faq, index) => (
            <AccordionItem
              key={index}
              value={`item-${index + 1}`}
              className="border-b border-indigo-100 last:border-b-0"
            >
              <AccordionTrigger className="text-slate-700 font-medium text-left hover:text-teal-600 transition-colors py-4">
                {faq.question}
              </AccordionTrigger>
              <AccordionContent className="text-slate-600 pb-5">
                {faq.answer}
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </section>
    </div>
  );
};

export default Beanstalk;


