import React from "react";
import { motion } from "framer-motion";
import { DomainSuggestion } from "utils/domainUtils";
import {
  Loader2,
  ShoppingCart,
  ExternalLink,
  Globe,
  Check,
} from "lucide-react";
import { Button } from "components/ui/button";
import { useToast } from "components/ui/use-toast";

interface DomainCardProps {
  domain: DomainSuggestion;
  index: number;
  onSelect?: (domain: DomainSuggestion) => void;
  lightTheme?: boolean;
  showPrice?: boolean;
}

const DomainCard: React.FC<DomainCardProps> = ({
  domain,
  index,
  onSelect,
  lightTheme = false,
  showPrice = false,
}) => {
  const { toast } = useToast();

  const getStatusColor = () => {
    switch (domain.status) {
      case "available":
        return {
          bg: lightTheme ? "bg-emerald-100" : "bg-emerald-900/20",
          text: lightTheme ? "text-emerald-700" : "text-emerald-500",
          border: lightTheme ? "border-emerald-200" : "border-emerald-800",
        };
      case "premium":
        return {
          bg: lightTheme ? "bg-amber-100" : "bg-amber-900/20",
          text: lightTheme ? "text-amber-700" : "text-amber-500",
          border: lightTheme ? "border-amber-200" : "border-amber-800",
        };
      case "checking":
        return {
          bg: lightTheme ? "bg-gray-100" : "bg-gray-700/20",
          text: lightTheme ? "text-gray-600" : "text-gray-400",
          border: lightTheme ? "border-gray-200" : "border-gray-700",
        };
      default: // taken
        return {
          bg: lightTheme ? "bg-red-100" : "bg-red-900/20",
          text: lightTheme ? "text-red-700" : "text-red-500",
          border: lightTheme ? "border-red-200" : "border-red-800",
        };
    }
  };

  const colors = getStatusColor();

  const handleSelect = () => {
    if (domain.status === "taken") {
      toast({
        title: "Domain unavailable",
        description: `${domain.domain} is already taken.`,
        variant: "destructive",
      });
      return;
    }

    if (domain.status === "checking") {
      toast({
        title: "Still checking availability",
        description: `Please wait until we confirm if ${domain.domain} is available.`,
      });
      return;
    }

    if (onSelect) {
      onSelect(domain);
    }
  };

  const buttonBgColor = lightTheme
    ? "bg-white-A700 hover:bg-gray-50 text-emerald-600"
    : "bg-background/10 hover:bg-background/20";

  const textColor = lightTheme ? "text-gray-800" : "text-white";
  const mutedTextColor = lightTheme ? "text-gray-500" : "text-white/70";

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: index * 0.05 }}
      className={`border rounded-lg overflow-hidden ${
        lightTheme ? "border-gray-200 bg-white" : "border-white/10 bg-white/5"
      }`}
    >
      <div className="p-4">
        <div className="flex justify-between items-start mb-2">
          <h3 className={`font-medium ${textColor}`}>{domain.domain}</h3>
          <div
            className={`px-2 py-1 rounded-full text-xs font-medium ${colors.bg} ${colors.text}`}
          >
            {domain.status === "checking" ? (
              <div className="flex items-center">
                <Loader2 className="h-3 w-3 mr-1 animate-spin" />
                Checking
              </div>
            ) : (
              domain.status.charAt(0).toUpperCase() + domain.status.slice(1)
            )}
          </div>
        </div>

        {domain.originalLanguage && (
          <p className={`text-xs mb-2 ${mutedTextColor}`}>
            <Globe className="h-3 w-3 inline mr-1" />
            {domain.originalLanguage}
          </p>
        )}

        <div className="flex justify-between items-center mt-4">
          {showPrice &&
            domain.price !== undefined &&
            domain.status === "available" && (
              <div className={`font-medium ${textColor}`}>
                ${domain.price.toFixed(2)}/year
              </div>
            )}
          {showPrice && domain.status !== "available" && <div />}

          {!showPrice && <div></div>}

          {onSelect ? (
            <Button
              size="sm"
              className={`${buttonBgColor} border ${colors.border}`}
              onClick={handleSelect}
              disabled={domain.status === "taken"}
            >
              {domain.status === "checking" ? (
                <Loader2 className="h-4 w-4 mr-1 animate-spin" />
              ) : (
                <ShoppingCart className="h-4 w-4 mr-1" />
              )}
              {domain.status === "checking" ? "Checking..." : "Add to Basket"}
            </Button>
          ) : (
            <Button
              size="sm"
              className={`${buttonBgColor} border ${colors.border}`}
              disabled={domain.status === "taken"}
            >
              {domain.status === "checking" ? (
                <Loader2 className="h-4 w-4 mr-1 animate-spin" />
              ) : domain.status === "available" ? (
                <Check className="h-4 w-4 mr-1" />
              ) : (
                <ExternalLink className="h-4 w-4 mr-1" />
              )}
              {domain.status === "checking"
                ? "Checking..."
                : domain.status === "available"
                ? "Available"
                : "View Details"}
            </Button>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default DomainCard;
