import {
  EllipsisHorizontalCircleIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { getDomains } from "api/domain";
import { Button, Img, Input } from "components";
import { Checkbox, Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import MultipleSelectInput from "components/MultiSelectInput";
import { APP_NAMES, DOMAIN_TYPE } from "config/constants";
import { toast } from "react-toastify";
import Badge from "components/Badge";

import { isValidDomain } from "utils/validations";
import { swapDomain } from "api/order";
import { getAllApps } from "api/apps";
import { ExternalLink, Mail, RefreshCw, Info } from "lucide-react";
import { createInboxPlacementTest } from "api/inboxPlacementTest";
import Popup from "components/Popup";
import { cn } from "lib/utils";

function SwapDomainModal({ user, setShowModal, selectedDomains }) {
  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState(
    selectedDomains.map((dom, i) => ({
      id: dom.id,
      domain: dom.domain,
      newDomain: "",
    }))
  );
  const handleDomainChange = (index, field, value) => {
    const updatedDomains = [...domains];
    updatedDomains[index] = {
      ...updatedDomains[index],
      [field]: value,
    };
    setDomains(updatedDomains);
  };
  const handleSubmit = async () => {
    const validateDomains = () => {
      const seenDomains = new Set();

      for (const { newDomain } of domains) {
        if (!newDomain || !newDomain.trim()) {
          return "Please fill out all domain fields.";
        }
        if (!isValidDomain(newDomain.trim())) {
          return `Invalid domain format: "${newDomain}".`;
        }
        // Check for duplicate domains
        if (seenDomains.has(newDomain.trim())) {
          return `Duplicate domain found: "${newDomain}".`;
        }
        seenDomains.add(newDomain.trim());
      }

      return "";
    };
    const msg = validateDomains();
    if (msg) {
      return toast.error(msg);
    } else {
      try {
        setLoading(true);
        await swapDomain(user.token, user.organizationId, domains);
        setShowModal();
        toast.success("Domain Swap Request Submitted");
      } catch (error) {
        console.error(error);
        toast.error(error.response?.data?.message || "Error fetching domains");
      }
      setLoading(false);
    }
  };
  return (
    <>
      <div className="max-h-[300px] overflow-y-auto">
        {domains.map((item, index) => (
          <div key={index} className="flex gap-2 items-center mb-2">
            <span className="text-gray-600 font-bold">{index + 1}.</span>
            <Input
              className="disabled cursor-not-allowed"
              placeholder={item.domain}
              value={item.domain}
            />
            <Input
              placeholder={`NewDomain${index + 1}.com`}
              value={item.redirect_to}
              onChange={(e) =>
                handleDomainChange(index, "newDomain", e.target.value)
              }
            />
          </div>
        ))}
      </div>
      <div className="flex justify-end w-full">
        {loading ? (
          <Spinner />
        ) : (
          <Button
            className="self-center tracking-[1.25px] uppercase font-medium min-w-[150px] bg-teal-300 text-white-A700 rounded-md"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        )}
      </div>
      {/* <Popup
        isOpen={true}
        onClose={undefined}
        title="Inbox Placement Test (Email Guard)"
        className="w-[40%]"
      ></Popup> */}
    </>
  );
}

function InboxTest({ user, egApp, setShowModal, domain, refreshEG }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const data = await createInboxPlacementTest(
        user.token,
        user.organizationId,
        {
          id: egApp[0].id,
          domains: [domain.id],
        }
      );
      setShowModal();
      navigate("inbox-placement-tests");
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "Error creating test");
    }

    setLoading(false);
  };
  return (
    <div className="flex flex-col gap-6">
      {egApp.length === 0 ? (
        <div className="animate-fade-in">
          <div className="flex flex-col items-center justify-center">
            <div className="text-center">
              <p className="text-gray-600 mb-2">
                Connect your Email Guard account to run inbox placement tests
              </p>
            </div>
            <div className="flex items-center gap-x-2">
              <Button
                className="w-full bg-emerald-500 hover:bg-emerald-400/90 py-6 rounded-md font-medium text-base transition-all text-white-A700"
                onClick={() => {
                  window.open("/settings", "_blank");
                }}
              >
                CONNECT EMAIL GUARD
              </Button>
              <RefreshCw
                className="h-5 w-5 mr-2 animate-rotate cursor-pointer"
                onClick={refreshEG}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="animate-fade-in">
          <div className="mb-6">
            <label
              htmlFor="domain"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Domain
            </label>
            <Input
              id="domain"
              value={domain.domain}
              className="w-full px-4 py-2 border border-gray-200 rounded-md focus:ring-2 focus:ring-emailGuard-blue focus:border-transparent"
            />
          </div>

          <div className="flex items-start gap-3 bg-blue-50 p-4 rounded-lg mb-6 animate-slide-in">
            <Info className="h-5 w-5 text-emailGuard-blue shrink-0 mt-0.5" />
            <p className="text-sm text-gray-600">
              Two of the domains will be randomly selected for an inbox
              placement test.
            </p>
          </div>

          <div className="flex gap-3 justify-end">
            <Button
              className={cn(
                "px-8 py-2 bg-emailGuard-green hover:bg-emailGuard-green/90 text-white rounded-md font-medium transition-all",
                loading && "opacity-90"
              )}
              onClick={handleSubmit}
              disabled={loading}
            >
              SUBMIT
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default function Domains() {
  const navigate = useNavigate();

  const { userObj } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [filteredDomains, setFilteredDomains] = useState(null);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [inboxTest, setInboxTest] = useState(null);
  const [provider, setProvider] = useState([]);
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [searchTerm, setSearchTerm] = useState("");
  const [egApp, setEGApp] = useState([]);

  const loadEmailGuardApps = async () => {
    try {
      const dt = await getAllApps(userObj.token, userObj.organizationId, {
        app: APP_NAMES.EMAIL_GUARD,
      });
      setEGApp(dt[APP_NAMES.EMAIL_GUARD] || []);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching apps");
    }
  };

  const loadDomains = async () => {
    setLoading(true);
    try {
      const dt = await getDomains(userObj.token, userObj.organizationId, {
        provider,
      });
      setResults(dt);
      setFilteredDomains(dt.domains);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching domains");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (results?.domains) {
      const filtered = results.domains.filter((item) => {
        const domainMatch = item.domain
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());
        const redirectMatch = item.redirect
          ?.toLowerCase()
          .includes(searchTerm.toLowerCase());
        return domainMatch || redirectMatch;
      });
      setFilteredDomains(filtered);
    }
  }, [searchTerm, results]);

  const handleDomain = async (item) => {
    navigate("/mailboxes", {
      state: { selectedDomain: { value: item.id, label: item.domain } },
    });
  };

  const handleCheckboxChange = (item) => {
    setSelectedDomains((prevSelectedDomains) => {
      if (prevSelectedDomains.includes(item)) {
        return prevSelectedDomains.filter((domain) => domain !== item);
      } else {
        return [...prevSelectedDomains, item];
      }
    });
  };

  useEffect(() => {
    loadDomains();
  }, [provider]);

  useEffect(() => {
    loadEmailGuardApps();
  }, [userObj]);

  const csvData = filteredDomains?.map((item) => ({
    Domain: item.domain,
    Mailboxes: item.total_mailboxes,
    Provider: item.order_type,
    "Domain Source": item.domain_provider,
    Redirect: item.redirect || "N/A",
  })) || [];

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 h-full">
          {results && (
            <>
              <div className="flex justify-between items-center">
                <p className="text-xl font-bold text-gray-900">
                  Domains ({results.domains.length})
                </p>

                <div className="flex items-center space-x-2">
                  {selectedDomains.length > 0 && (
                    <>
                      <div className="md:hidden sm:hidden space-x-2">
                        {/* FIXME: Multiselect and status check  */}
                        <button
                          onClick={() => setShowModal(true)}
                          className="px-4 py-2 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg hover:bg-teal-600"
                        >
                          Swap Domain
                        </button>
                        {/* <button
                          onClick={() => setShowIUModal(true)}
                          className="px-4 py-2 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg hover:bg-teal-600"
                        >
                          Instantly Upload
                        </button> */}
                      </div>
                      <EllipsisHorizontalCircleIcon className="h-7 w-7 !text-gray-500 hover:!text-teal-300 cursor-pointer rotate-90 lg:hidden" />
                    </>
                  )}
                  <button
                    onClick={() => navigate("/marketplace")}
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg hover:bg-teal-600"
                  >
                    Order Mailboxes
                  </button>
                  <CSVLink
                    data={csvData}
                    filename={"domains.csv"}
                    className="items-center px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white-A700 border border-gray-300 rounded-lg shadow-sm sm:inline-flex hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Export
                  </CSVLink>
                </div>
              </div>

              <div className="flex flex-col mt-2 h-full">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="mt-4 flex justify-between items-center gap-2 sm:flex-col">
                      <div className="w-[60%]">
                        <Input
                          placeholder="Search..."
                          className="w-full bg-white-A700" // Ensure the input fills the 60% container
                          prefix={
                            <MagnifyingGlassIcon className="w-5 h-5 mr-5" />
                          }
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>

                      <div className="w-1/2 flex items-start justify-end gap-x-3">
                        <MultipleSelectInput
                          options={DOMAIN_TYPE}
                          label=""
                          placeholder="Select provider"
                          selected={provider}
                          setSelected={setProvider}
                          search={false}
                          className="w-full"
                          multiselect={true}
                        />
                        {/* <MultipleSelectInput
                          options={DOMAIN_PROVIDER}
                          label=""
                          placeholder="Select Domain Source"
                          selected={domainSource}
                          setSelected={setDomainSource}
                          search={false}
                          className="w-full"
                          multiselect={true}
                        /> */}
                      </div>
                    </div>
                    <div className="h-[75vh] overflow-y-auto border border-gray-200 rounded-xl mt-3">
                      <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-200">
                          <tr>
                            {[
                              "",
                              "Provider",
                              "Domain Source",
                              "Domain",
                              "Redirect",
                              "Mailboxes",
                              "Go To Order",
                              "Go To Mailboxes",
                              // "Inbox Deliverability Test"
                            ].map((header, i) => (
                              <th
                                key={i}
                                className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest"
                              >
                                {header}
                              </th>
                            ))}
                          </tr>
                        </thead>

                        <tbody className="bg-white-A700 divide-y divide-gray-200">
                          {filteredDomains &&
                            filteredDomains.map((item, index) => (
                              <tr
                                key={index}
                                className={`bg-white-A700${
                                  selectedDomains.includes(item)
                                    ? "bg-gray-100"
                                    : ""
                                }`}
                              >
                                <td className="px-4 py-4">
                                  <Checkbox
                                    className="h-4 w-4 checked:text-teal-300 cursor-pointer"
                                    value={selectedDomains.includes(item)}
                                    onChange={() => handleCheckboxChange(item)}
                                  />
                                </td>

                                <td className="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  {item.order_type === "outlook" ? (
                                    <Img
                                      src="/images/outlook_icon.png"
                                      alt="Tick"
                                      className="h-8 w-8"
                                    />
                                  ) : item.order_type === "google" ? (
                                    <Img
                                      src="/images/google_icon.png"
                                      alt="Tick"
                                      className="h-8 w-8"
                                    />
                                  ) : (
                                    <QuestionMarkCircleIcon className="h-5 w-5" />
                                  )}
                                </td>
                                <td className="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  <Badge
                                    value={item.domain_provider}
                                    skip={true}
                                  />
                                </td>
                                <td className="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  {item.domain}
                                </td>
                                <td className="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  {item.redirect}
                                </td>
                                <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  {item.total_mailboxes}
                                </td>
                                <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  <button
                                    onClick={() =>
                                      navigate(`/billing/${item.payment_id}`)
                                    }
                                    className="flex items-center space-x-1 px-3 py-1.5 rounded-md text-xs font-medium text-blue-700 bg-blue-50 hover:bg-blue-100 transition-colors"
                                  >
                                    <ExternalLink size={14} className="mr-1" />
                                    <span>Order</span>
                                  </button>
                                </td>

                                <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  <button
                                    onClick={() => handleDomain(item)}
                                    className="flex items-center space-x-1 px-3 py-1.5 rounded-md text-xs font-medium text-green-700 bg-green-50 hover:bg-green-100 transition-colors"
                                  >
                                    <Mail size={14} className="mr-1" />
                                    <span>Mailboxes</span>
                                  </button>
                                </td>
                                {/* <td className="px-4 py-4 flex items-center justify-center">
                                  <button
                                    className="flex items-center space-x-1 px-3 py-1.5 rounded-md text-xs font-medium text-purple-700 bg-purple-50 hover:bg-purple-100 transition-colors"
                                    onClick={() => setInboxTest(item)}
                                  >
                                    <Inbox size={14} className="mr-1" />
                                    <span>Test</span>
                                  </button>
                                </td> */}
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {inboxTest != null && (
        <Popup
          isOpen={inboxTest != null}
          onClose={() => {
            setInboxTest(null);
          }}
          title="Inbox Placement Test (Email Guard)"
          icon="https://www.google.com/s2/favicons?sz=64&domain_url=https%3A%2F%2Fapp.emailguard.io%2Finbox-placement-tests"
          className="w-[40%]"
        >
          <InboxTest
            user={userObj}
            showModal={inboxTest != null}
            setShowModal={() => {
              setInboxTest(null);
            }}
            egApp={egApp}
            domain={inboxTest}
            refreshEG={loadEmailGuardApps}
          />
        </Popup>
      )}
      {selectedDomains.length > 0 && showModal && (
        <Popup
          isOpen={showModal != null}
          onClose={() => {
            setSelectedDomains([]);
            setShowModal(false);
          }}
          title="Swap Domain"
          className="max-w-fit w-auto mx-auto p-4"
        >
          <SwapDomainModal
            user={userObj}
            showModal={showModal}
            setShowModal={() => {
              setSelectedDomains([]);
              setShowModal(false);
            }}
            selectedDomains={selectedDomains}
          />
        </Popup>
      )}
    </div>
  );
}
