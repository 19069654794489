import axios from "axios";
import { BACKEND_URL } from "../config/config";
import { API_VERSION } from "config/prices";

const postOrder = async (token, organizationId, uuid, data) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/api/${API_VERSION}/order/${uuid}?token=${token}&organizationId=${organizationId}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const swapDomain = async (token, organizationId, data) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/api/${API_VERSION}/order/swap-domain?token=${token}&organizationId=${organizationId}`,
      data
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getOrder = async (token, organizationId, uuid, data) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/api/${API_VERSION}/order/${uuid}?token=${token}&organizationId=${organizationId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getPendingOrder = async (token, organizationId, id) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/api/${API_VERSION}/order/pending/${id}?token=${token}&organizationId=${organizationId}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

const getOulookOrder = async (token, orgId, recordId) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/${API_VERSION}/order/outlook/${recordId}?token=${token}&organizationId=${orgId}`
  );
  return response.data;
};
const getMixedOrder = async (token, orgId, recordId) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/${API_VERSION}/order/mixed/${recordId}?token=${token}&organizationId=${orgId}`
  );
  return response.data;
};
const getGoogleOrder = async (token, orgId, recordId) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/${API_VERSION}/order/google/${recordId}?token=${token}&organizationId=${orgId}`
  );
  return response.data;
};

export {
  postOrder,
  getPendingOrder,
  getOrder,
  swapDomain,
  getOulookOrder,
  getGoogleOrder,
  getMixedOrder,
};
