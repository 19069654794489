import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import DomainInput from "./DomainInput";
import DomainCard from "./DomainCard";
import TldSelector from "./TldSelector";
import {
  generateDomainIdeas,
  DomainSuggestion,
  checkSingleDomain,
} from "utils/domainUtils";
import { Sparkles, RefreshCcw, Plus, Loader2, GlobeIcon } from "lucide-react";
import { Button } from "components/ui/button";
import { toast } from "components/ui/use-toast";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
} from "components/ui/pagination";

const industries = [
  { id: "technology", name: "Technology" },
  { id: "ecommerce", name: "eCommerce" },
  { id: "health", name: "Health & Wellness" },
  { id: "education", name: "Education" },
  { id: "finance", name: "Finance" },
  { id: "food", name: "Food & Beverage" },
  { id: "travel", name: "Travel" },
];

interface DomainGeneratorProps {
  onSelectDomain?: (domain: DomainSuggestion) => void;
  userObj: any;
}

const DomainGenerator: React.FC<DomainGeneratorProps> = ({
  onSelectDomain,
  userObj,
}) => {
  const [keyword, setKeyword] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");
  const [domainIdeas, setDomainIdeas] = useState<DomainSuggestion[]>([]);
  const [searchedDomain, setSearchedDomain] = useState<DomainSuggestion[]>([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [hasGenerated, setHasGenerated] = useState(false);
  const [page, setPage] = useState(1);
  const [showAvailableOnly, setShowAvailableOnly] = useState(false);
  const [selectedTlds, setSelectedTlds] = useState<string[]>([
    ".com",
    ".net",
    ".org",
  ]);
  const [isCheckingSearchedDomain, setIsCheckingSearchedDomain] =
    useState(false);

  useEffect(() => {
    if (keyword && keyword.length > 1) {
      generateDomains();
    }
  }, [selectedIndustry, selectedTlds]);

  const checkKeywordAsDomain = async () => {
    if (!keyword) return;

    let domainToCheck = keyword.toLowerCase().trim();

    setIsCheckingSearchedDomain(true);
    try {
      const results = await Promise.all(
        selectedTlds.map(async (tld) => {
          const name = `${domainToCheck}${tld}`;
          return await checkSingleDomain(userObj, name);
        })
      );
      setSearchedDomain(results);
    } catch (error) {
      console.error("Error checking domain:", error);
    } finally {
      setIsCheckingSearchedDomain(false);
    }
  };
  const LIMIT = 10;

  const generateDomains = async () => {
    if (!keyword) return;
    if (keyword.includes(".")) {
      return toast({
        title: "Kindly remove tld from keyword",
        variant: "destructive",
      });
    }
    checkKeywordAsDomain();
    setIsGenerating(true);
    setPage(1); // Reset to first page on new generation

    try {
      const newIdeas = await generateDomainIdeas(
        keyword,
        selectedIndustry,
        selectedTlds,
        LIMIT
      );
      setDomainIdeas(newIdeas);
      setHasGenerated(true);

      if (newIdeas.length === 0) {
        toast({
          title: "No domain ideas found",
          description: "Try a different keyword or industry.",
          variant: "destructive",
        });
      } else {
        const updatedDomains = await Promise.all(
          newIdeas.map(async (domainSuggestion) => {
            return await checkSingleDomain(userObj, domainSuggestion.domain);
          })
        );

        setDomainIdeas(updatedDomains);
      }
    } catch (error) {
      console.error("Error generating domain ideas:", error);
      toast({
        title: "Error generating domains",
        description: "There was a problem checking domain availability.",
        variant: "destructive",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const loadMoreDomains = async () => {
    setIsGenerating(true);
    try {
      const additionalIdeas = await generateDomainIdeas(
        keyword,
        selectedIndustry,
        selectedTlds,
        LIMIT, // Limit
        domainIdeas.length // Offset (current number of domains)
      );

      const updatedAdditionalIdeas = await Promise.all(
        additionalIdeas.map(async (domainSuggestion) => {
          return await checkSingleDomain(userObj, domainSuggestion.domain);
        })
      );

      // Append the new domains to the existing list
      setDomainIdeas((prev) => [...prev, ...updatedAdditionalIdeas]);

      toast({
        title: "More domains loaded",
        description: `Showing more creative domains for "${keyword}"`,
      });
    } catch (error) {
      console.error("Error loading more domain ideas:", error);
      toast({
        title: "Error loading more domains",
        description: "There was a problem checking domain availability.",
        variant: "destructive",
      });
    } finally {
      setIsGenerating(false);
      setPage((prev) => prev + 1);
    }
  };

  const regenerateDomains = () => {
    toast({
      title: "Regenerating domain ideas",
      description: "Finding new creative domains for you.",
    });
    generateDomains();
  };

  const handleSelectDomain = (domain: DomainSuggestion) => {
    if (onSelectDomain) {
      onSelectDomain(domain);
    }
  };

  const filteredDomainIdeas = showAvailableOnly
    ? domainIdeas.filter((domain) => domain.status === "available")
    : domainIdeas;

  const renderIndustryButtons = () => {
    return (
      <div className="flex flex-wrap gap-2 mb-6">
        {industries.map((industry) => (
          <button
            key={industry.id}
            onClick={() => setSelectedIndustry(industry.id)}
            className={`px-3 py-1.5 rounded-full text-sm transition-all duration-200 ${
              selectedIndustry === industry.id
                ? "bg-emerald-500 text-white"
                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
            }`}
          >
            {industry.name}
          </button>
        ))}
      </div>
    );
  };

  const renderSearchedDomain = () => {
    if (!searchedDomain) return null;

    return (
      <div className="mb-6 border border-gray-200 rounded-lg p-4 bg-white-A700shadow-sm">
        <h3 className="text-base font-medium text-gray-700 mb-2 flex items-center">
          <GlobeIcon className="h-4 w-4 mr-2 text-gray-500" />
          Domain you searched:
        </h3>

        {isCheckingSearchedDomain ? (
          <div className="flex items-center gap-2 text-gray-500">
            <Loader2 className="h-4 w-4 animate-spin" />
            Checking availability...
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-x-2 gap-y-2">
            {searchedDomain.map((sd, i) => (
              <div className="flex justify-between bg-white-A700 border rounded-md p-2 min-h-[40px]">
                <div className="flex items-center">
                  <span className="font-medium">{sd.domain}</span>
                  <span
                    className={`ml-3 text-xs px-2 py-0.5 rounded-full ${
                      sd.status === "available"
                        ? "bg-emerald-100 text-emerald-800"
                        : sd.status === "premium"
                        ? "bg-amber-100 text-amber-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {sd.status.charAt(0).toUpperCase() + sd.status.slice(1)}
                  </span>
                </div>
                {(sd.status === "available" || sd.status === "premium") && (
                  <div className="flex items-center gap-x-2">
                    <div className={`font-medium text-gray-800`}>
                      ${sd.price.toFixed(2)}/year
                    </div>
                    <Button
                      size="sm"
                      onClick={() => handleSelectDomain(sd)}
                      className="bg-emerald-500 hover:bg-emerald-600 text-white-A700"
                    >
                      <Plus className="h-4 w-4" />
                    </Button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const renderResults = () => {
    // if (isGenerating && page === 1) {
    //   return (
    //     <div className="flex flex-col items-center justify-center p-10 space-y-4">
    //       <div className="animate-spin">
    //         <Loader2 className="h-16 w-16 mb-4 text-emerald-500" />
    //       </div>
    //       <h3 className="text-xl font-medium text-gray-800">Checking domain availability...</h3>
    //       <p className="text-gray-600">Finding available domains for "{keyword}"</p>
    //     </div>
    //   );
    // }

    if (!hasGenerated) {
      return (
        <div className="flex flex-col items-center justify-center text-center p-10 space-y-4 text-gray-500">
          <Sparkles className="h-16 w-16 mb-4 text-gray-300" />
          <h3 className="text-xl font-medium text-gray-800">
            Generate creative domain ideas
          </h3>
          <p className="max-w-md">
            Enter a keyword and select an industry to generate unique domain
            name ideas.
          </p>
        </div>
      );
    }

    if (showAvailableOnly && filteredDomainIdeas.length === 0) {
      return (
        <div className="text-center p-10">
          <p className="text-gray-500">
            No available domains found. Try a different keyword or disable the
            "Available Only" filter.
          </p>
        </div>
      );
    }

    return (
      <>
        {filteredDomainIdeas.length > 0 ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-800">
                Domain Ideas
              </h2>
              <Button
                variant="outline"
                size="sm"
                onClick={regenerateDomains}
                className="text-gray-600 border-gray-300"
              >
                <RefreshCcw className="h-4 w-4 mr-2" />
                Regenerate
              </Button>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-1 gap-4">
              {filteredDomainIdeas.map((domain, index) => (
                <DomainCard
                  key={`${domain.domain}-${index}`}
                  domain={domain}
                  index={index}
                  onSelect={handleSelectDomain}
                  lightTheme={true}
                  showPrice={true}
                />
              ))}
            </div>
            {domainIdeas.length >= 4 && !showAvailableOnly && (
              <div className="mt-6">
                <Pagination>
                  <PaginationContent>
                    <PaginationItem>
                      <Button
                        onClick={loadMoreDomains}
                        className="flex items-center gap-2 px-4"
                        disabled={isGenerating}
                        variant="outline"
                      >
                        {/* {isGenerating ? (
                          <>
                            <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                            Checking domains...
                          </>
                        ) : ( */}
                        <>
                          Load More Ideas
                          <Plus className="h-4 w-4" />
                        </>
                        {/* )} */}
                      </Button>
                    </PaginationItem>
                  </PaginationContent>
                </Pagination>
              </div>
            )}
          </motion.div>
        ) : (
          <div className="text-center p-10">
            <p className="text-gray-500">
              No domain ideas found. Try a different keyword or industry.
            </p>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      <div className="mb-6">
        <DomainInput
          value={keyword}
          onChange={setKeyword}
          onSubmit={generateDomains}
          placeholder="Enter a keyword for domain ideas..."
          autoFocus
          lightTheme={true}
        />
      </div>

      <div className="grid sm:grid-cols-1 grid-cols-2 gap-4 mb-6">
        <div className="col-span-1">
          <div className="bg-white-A700border border-gray-200 shadow-sm rounded-lg p-3">
            {renderIndustryButtons()}
          </div>
        </div>
        <div className="col-span-1">
          <TldSelector
            selectedTlds={selectedTlds}
            onTldChange={setSelectedTlds}
            lightTheme={true}
          />
        </div>
      </div>

      {searchedDomain && renderSearchedDomain()}

      {/* {hasGenerated && (
        <div className="flex items-center justify-end mb-4 gap-2">
          <span className="text-sm text-gray-600">Available Only</span>
          <Switch 
            checked={showAvailableOnly} 
            onCheckedChange={setShowAvailableOnly}
            className="data-[state=checked]:bg-emerald-500"
          />
          <span className="inline-flex items-center text-sm text-emerald-600 gap-1">
            <CheckCircle className="h-3.5 w-3.5" />
          </span>
        </div>
      )} */}

      <div className="min-h-[400px]">{renderResults()}</div>
    </div>
  );
};

export default DomainGenerator;
