const EMAIL_SEQUENCER = [
  { value: "Smartlead", label: "Smartlead" },
  { value: "Instantly", label: "Instantly" },
  { value: "Email Bison", label: "Email Bison" },
  { value: "Other", label: "Other" },
];

const DOMAIN_PROVIDER = [
  { value: "Pipl", label: "Pipl" },
  { value: "Porkbun", label: "Porkbun" },
  { value: "Scaledmail", label: "Scaledmail" },
  { value: "Namecheap", label: "Namecheap" },
  { value: "Godaddy", label: "GoDaddy" },
  { value: "HostGator", label: "HostGator" },
  { value: "Ionos", label: "Ionos" },
  { value: "Cloudflare", label: "Cloudflare" },
  { value: "DnSimple", label: "DnSimple" },
  { value: "Other", label: "Other" },
];

const DOMAIN_TYPE = [
  { value: "Outlook", label: "Outlook" },
  { value: "Google", label: "Google" },
];

const LOGIN_TYPE = { email_password: "email&password", google: "google" };
const MEMBER_ROLE = { admin: "Admin", member: "Member" };
const TOKEN_TYPE = {
  resetPassword: "reset-password",
  userInvitation: "user-invitation",
};
const SUBSCRIPTION_STATUS = {
  Active: "Active",
  CancelRequest: "Cancel Request",
  RequestToCancel: "Requested to cancel",
  PendingCancellation: "Pending Cancellation",
  Cancelled: "Cancelled",
  PendingForm: "Pending Form",
};

const APP_NAMES = {
  EMAIL_GUARD: "Email Guard",
};

const ORDER_TYPE = {
  Outlook: "Outlook",
  Smtp: "Smtp",
};

const INTEGRATIONS = [
  {
    name: APP_NAMES.EMAIL_GUARD,
    logo: "https://www.google.com/s2/favicons?sz=64&domain_url=https%3A%2F%2Fapp.emailguard.io%2Finbox-placement-tests",
    link: "https://emailguard.io/",
    description: `One stop shop for email health monitoring & unique tools like domain "masking" for enhancing cold email.`,
  },
];

const CHECKOUT_TYPE = {
  REGISTER_DOMAIN: "register-domains",
  PACKAGE_SUBSCRIPTION: "package-subscription",
};

const MAX_ORGANIZATIONS = 5;

const TEST_EMAILS = [
  "shridhar@beanstalkconsulting.co",
  "umer@beanstalkconsulting.co",
  "dean@beanstalkconsulting.co",
  "umerf996r@gmail.com",
];

const isTestUser = (email) => TEST_EMAILS.includes(email);

module.exports = {
  LOGIN_TYPE,
  MEMBER_ROLE,
  TOKEN_TYPE,
  SUBSCRIPTION_STATUS,
  EMAIL_SEQUENCER,
  DOMAIN_PROVIDER,
  DOMAIN_TYPE,
  MAX_ORGANIZATIONS,
  INTEGRATIONS,
  APP_NAMES,
  CHECKOUT_TYPE,
  TEST_EMAILS,
  isTestUser,
  ORDER_TYPE
};
