import axios from "axios";
import { BACKEND_URL } from "../config/config";
import { API_VERSION } from "config/prices";

const getNotifications = async (token, organizationId) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/${API_VERSION}/notification?organizationId=${organizationId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

const markNotificationResolved = async (token, organizationId, recordId) => {
  const response = await axios.put(
    `${BACKEND_URL}/api/${API_VERSION}/notification/${recordId}/resolve?token=${token}&organizationId=${organizationId}`
  );
  return response.data;
};

export { getNotifications, markNotificationResolved };
