import DomainSelection from "components/DomainSelection";
import ProgressSteps from "components/ProgressSteps";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Globe, CheckCircle, ArrowLeft } from "lucide-react";
import { getStripe } from "utils/getStripe";
import CheckoutForm from "components/CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { Button } from "components";
import { CHECKOUT_TYPE } from "config/constants";

const STEPS = [
  {
    number: 1,
    title: "Select Domains",
    description: "Buy domains",
    icon: Globe,
  },
  {
    number: 2,
    title: "Finalize",
    description: "Complete your order",
    icon: CheckCircle,
  },
];

export default function BuyDomains() {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [domains, setDomains] = useState([]);
  const [totalDomainPrice, setTotalDomainPrice] = useState(0);

  const { userObj } = useSelector((state) => state.user);

  return (
    <div className="min-h-screen bg-gradient-to-b from-background to-gray-50">
      <div className="container max-w-6xl px-4 sm:px-6">
        <ProgressSteps currentStep={currentStep} steps={STEPS} />
        {currentStep === 1 && (
          <DomainSelection
            handleBack={() => navigate(-1)}
            handleNext={() => setCurrentStep(currentStep + 1)}
            totalDomains={null}
            selectedDomains={domains}
            setSelectedDomains={setDomains}
            totalDomainPrice={totalDomainPrice}
            userObj={userObj}
            setTotalDomainPrice={setTotalDomainPrice}
          />
        )}
        {currentStep === 2 && (
          <div className="max-w-5xl mx-auto bg-white-A700 rounded-lg shadow-sm border border-gray-200 p-6">
            <div className="w-full">
              <Elements stripe={getStripe()}>
                <CheckoutForm
                  body={{
                    type: CHECKOUT_TYPE.REGISTER_DOMAIN,
                    returnUrl: `${window.location.origin}/billing?tab=invoices`,
                    data:{
                      domains
                    }
                  }}
                />
              </Elements>
            </div>
            <div className="mt-12 flex justify-between">
              <Button
                variant="outline"
                className="text-gray-600 border-gray-300"
                onClick={() => setCurrentStep(Math.max(1, currentStep - 1))}
                disabled={currentStep === 1}
              >
                <ArrowLeft className="mr-2 h-4 w-4" />
                Back
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
