import { Button } from "components";
import CustomInput from "components/CustomInput";
import { Spinner } from "flowbite-react";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

// TODO: Send Email when new order or first customer
// Downlaod invoice for google inboxe
// Check unique and handle domain check
// multiple of 4 on domain page to select order instantly etc shri

import Stepper from "components/Stepper";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { postOrder } from "api/order";
import SenderNameConfiguration from "components/SenderNameConfiguration";
import { DomainInput } from "components/DomainInput";
import { DomainEmailSequencerInput } from "components/DomainEmailSequencerInput";
import { generateDomains } from "utils/utils";
import { validateDomains, validateMailboxes } from "utils/validations";
import Toggle from "components/Toggle";

const steps = [
  {
    title: "Setup Your Domains",
    description: "Set up your domains to proceed.",
  },
  {
    title: "Configure Sender Names",
    description: "Configure sender names for each domain.",
  },
  // {
  //   title: "Finalize",
  //   description: "Finalize your configuration.",
  // },
];

export default function OutlookOrder() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userObj } = useSelector((state) => state.user);
  const { plan, payment } = location.state || {};
  useEffect(() => {
    if (!plan || !payment || !plan.domains || !payment.Data.qty) {
      navigate("/"); // Redirect to home
    }
  }, [plan, payment, navigate]);
  const mailboxPerDomain = plan?.per_domain || 49;
  const [data, setData] = useState({
    common: {
      dhp: {
        domainHostProvider: [],
        domainHostProviderLink: "",
        domainHostUsername: "",
        domainHostPassword: "",
      },
      esp: {
        esp: [],
        espLink: "",
        emailOutreachUsername: "",
        emailOutreachPassword: "",
        workspace: "",
      },
    },
    qty: payment.Data.qty,
    ct: false,
    aliases: [],
    aliases_count: mailboxPerDomain * plan.microsoft.domains * payment.Data.qty,
    domains: generateDomains(plan.microsoft.domains * payment.Data.qty),
  });

  const [purchasedDomain, setPurchasedDomains] = useState([]);
  const checkPurhcasedDomains = () => {
    const domains = payment.Data?.domains?.selectedDomains || [];
    if (domains.length > 0) {
      const qty = plan.microsoft.domains * payment.Data.qty;
      const inputDomains = Array.from({ length: qty }, (_, index) => ({
        domain: domains[index].domain,
        redirect_to: "",
        sender_names: [{ first_name: "", last_name: "" }],
      }));
      const pDomains = Array.from({ length: qty }, (_, index) => ({
        label: domains[index].domain,
        value: domains[index].domain,
      }));
      setPurchasedDomains(pDomains);
      handleInputChange("domains", inputDomains);
    }
  };
  useEffect(() => {
    checkPurhcasedDomains();
  }, [payment]);

  const [finalLoading, setFinalLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const handleInputChange = (path, value) => {
    const keys = path.split(".");
    setData((prev) => {
      let updatedData = { ...prev };
      let nestedData = updatedData;

      // Traverse to the desired field and update the value
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          nestedData[key] = value; // Set the value at the final key
        } else {
          nestedData = nestedData[key]; // Traverse deeper into nested structure
        }
      });

      return updatedData;
    });
  };

  const submitOrder = async () => {
    setFinalLoading(true);

    try {
      const response = await postOrder(
        userObj.token,
        userObj.organizationId,
        payment["UUID"],
        data
      );
      console.info(response.text);
      navigate("/billing?new-order=true");
    } catch (error) {
      console.error("Error fetching searched:", error);
      toast.error(error?.response?.data?.message || "Unknown Error");
    }
    setFinalLoading(false);
  };

  const handleNextStep = async () => {
    if (currentStep === 0) {
      const msg = validateDomains(data.domains);
      if (msg) {
        toast.error(msg);
        return;
      }
    }
    if (currentStep === 1) {
      const msg = validateMailboxes(
        data.domains,
        data.aliases,
        data.aliases_count
      );
      if (msg) {
        toast.error(msg);
        return;
      }
      await submitOrder();
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="flex  flex-col h-[85vh] overflow-y-scroll">
      <p className="text-xl font-bold text-gray-900">{plan.reason}</p>

      <Stepper steps={steps} currentStep={currentStep} />

      {/* Step content */}
      <section className="bg-white">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {currentStep === 0 && (
            <div className="flex gap-4 justify-between">
              <div className="flex flex-col gap-4 bg-white-A700 p-5 rounded-md mb-5">
                <DomainInput
                  qty={data.qty}
                  domains={data.domains}
                  handleInputChange={handleInputChange}
                  purchasedDomain={purchasedDomain}
                  select={false}
                />

                <div className="flex flex-col gap-3 mt-3">
                  <DomainEmailSequencerInput
                    common={data.common}
                    handleInputChange={handleInputChange}
                    domainInformation={
                      purchasedDomain.length > 0 ? false : true
                    }
                  />
                  <div className="flex items-center gap-3">
                    <div className="w-1/2">
                      <CustomInput
                        label="Mailboxes"
                        placeholder="Enter how many mailboxes to create"
                        type="number"
                        min="1"
                        max={mailboxPerDomain}
                        disabled={true}
                        value={data.aliases_count}
                        onChange={(e) =>
                          handleInputChange("aliases_count", e.target.value)
                        }
                      />
                    </div>
                    <Toggle
                      className="flex-col"
                      label="Custom Tracking"
                      value={data.ct}
                      onChange={(val) => handleInputChange("ct", val)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentStep === 1 && (
            <SenderNameConfiguration
              aliases={data.aliases}
              domains={data.domains}
              aliases_count={data.aliases_count}
              handleInputChange={handleInputChange}
            />
          )}
          {finalLoading ? (
            <Spinner className="w-8 h-8" />
          ) : (
            <div
              className={`mt-8 text-center md:mt-16 flex gap-4 ${
                currentStep == 1 ? "" : "justify-center"
              }`}
            >
              {currentStep > 0 && (
                <Button
                  className="min-w-[100px] px-6 py-3 font-semibold !text-white-A700 rounded-md bg-teal-300 focus:opacity-80 hover:opacity-80"
                  onClick={handlePreviousStep}
                >
                  Previous
                </Button>
              )}
              {currentStep <= steps.length - 1 && (
                <Button
                  className="min-w-[100px] px-6 py-3 font-semibold !text-white-A700 rounded-md bg-teal-300 focus:opacity-80 hover:opacity-80"
                  onClick={handleNextStep}
                >
                  {currentStep < steps.length - 1 ? "Next" : "Finish"}
                </Button>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
