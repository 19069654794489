import axios from "axios";
import { BACKEND_URL } from "../config/config";
import { API_VERSION } from "config/prices";

const getMailboxes = async (token, organizationId, data) => {
  const response = await axios.post(
    `${BACKEND_URL}/api/${API_VERSION}/mailbox?organizationId=${organizationId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export { getMailboxes };
