import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { discountAvailable } from "api/user";
import {
  LSV_MAPPING,
  MSV_MAPPING,
  HSV_MAPPING,
  LSV_OUTLOOK_149_PRICE_ID,
  LSV_OUTLOOK_PRICE_ID,
  LSV_OUTLOOK_149,
  LSV_OUTLOOK_79,
  LSV_OUTLOOK_79_PRICE_ID,
} from "config/prices";
import { Spinner } from "flowbite-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PACKAGE_79 = [
  "hello@leadgrow.ai",
  "shridhar@beanstalkconsulting.co",
  "shri@foxes.ai",
  "lane@savedby.io",
  "umer@beanstalkconsulting.co",
  "naufal@understoryagency.com",
  "support@predict-ability.com",
  "archie@growthyard.com",
  "brandon@newflowpartners.com",
  "jbadillo@hexsearchgroup.com",
];

const Mapping = [
  {
    type: "Low Sending Volume",
    description:
      "Perfect for individuals, and beginners that just started sending cold emails. Send 10.000+ emails a month, without blowing up your budget.",
    mapping: LSV_MAPPING,
  },
  {
    type: "Medium Sending Volume",
    description:
      "Tailored for boutique agencies, and small teams managing multiple clients. We setup and deliver your cold email inboxes to your chosen sequencer following the best sending practices of over 100+ customers that use us to land in the inbox every single day.",
    mapping: MSV_MAPPING,
  },
  {
    type: "High Sending Volume",
    description:
      "Cold email infrastructure for larger companies, and bigger teams. Scalable Solutions designed with one word in mind - reliability. Our focus is eliminating sending downtime for your clients.",
    mapping: HSV_MAPPING,
  },
];

export default function Marketplace() {
  const navigate = useNavigate();
  const { userObj } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const handleClick = (plan, priceId) => {
    const transformedAddons = plan.addons
      ? Object.entries(plan.addons).map(([price_id, addonData]) => ({
          ...addonData,
          price_id,
        }))
      : [];
    navigate("/subscribe", {
      state: { ...plan, priceId, addons: transformedAddons },
    });
  };

  const handlePriceCheck = async () => {
    setLoading(true);
    try {
      const response = await discountAvailable(
        userObj.token,
        userObj.organizationId
      );
      if (response) {
        const updatedMapping = { ...Mapping[0].mapping };
        delete updatedMapping[LSV_OUTLOOK_PRICE_ID];
        updatedMapping[LSV_OUTLOOK_149_PRICE_ID] =
          LSV_OUTLOOK_149[LSV_OUTLOOK_149_PRICE_ID];

        Mapping[0].mapping = updatedMapping;
      }
      if (PACKAGE_79.includes(userObj.email)) {
        Mapping[0].mapping = {
          [LSV_OUTLOOK_79_PRICE_ID]: LSV_OUTLOOK_79[LSV_OUTLOOK_79_PRICE_ID],
          ...Mapping[0].mapping,
        };
      }
    } catch (error) {
      console.error("Error updating user:", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    handlePriceCheck();
  }, [userObj]);

  const sortPlans = (plans) => {
    return Object.entries(plans).sort(([_, a], [__, b]) => {
      if (a.new && !b.new) return -1;
      if (!a.new && b.new) return 1;
      return 0;
    });
  };

  return (
    <div className="flex max-h-[85vh] flex-col p-5 overflow-y-scroll">
      <div className="flex justify-between items-center mb-5">
        <p className="text-2xl font-bold text-gray-900">Marketplace</p>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <div>
          {Mapping.map((item, index) => (
            <div key={index} className="mb-10">
              <div className="text-center mb-5">
                <p className="text-3xl font-semibold mb-2">{item.type}</p>
                <p className="text-xl">{item.description}</p>
              </div>
              <div className="grid grid-cols-3 gap-5 sm:grid-cols-1 md:grid-cols-2">
                {sortPlans(item.mapping).map(([priceId, plan], i) => {
                  const isThreeColumnRow = Math.floor(i / 3) % 2 === 0;
                  const isMiddleElement = isThreeColumnRow && i % 3 === 1;
                  // Determine class for card background
                  const cardBgClass = isThreeColumnRow
                    ? isMiddleElement
                      ? "bg-teal-300"
                      : "bg-white-A700"
                    : "bg-white-A700";
                  return (
                    <div
                      key={i}
                      onClick={() => handleClick(plan, priceId)}
                      className={`relative border rounded-lg shadow-md p-6 hover:shadow-lg cursor-pointer ${cardBgClass}`} // Added 'relative' here
                    >
                      {plan.new && (
                        <div
                          className={`absolute -top-3 left-1/2 transform -translate-x-1/2 text-sm font-bold px-2 py-1 rounded-full  ${
                          isMiddleElement
                            ? "bg-white-A700 text-teal-300 shadow-md"
                            : "bg-teal-300 text-white-A700"
                        }`}
                        >
                          New
                        </div>
                      )}
                      <div
                        className={`flex items-center justify-center mb-3 rounded-md p-2 ${
                          isMiddleElement ? "bg-white-A700" : "bg-teal-300"
                        }`}
                      >
                        <p
                          className={`text-lg text-gray-900 ${
                            isMiddleElement
                              ? "text-teal-300"
                              : "text-white-A700"
                          }`}
                        >
                          {plan.name}
                        </p>
                      </div>
                      <p
                        className={`text-2xl font-bold text-gray-900 mb-2  ${
                          isMiddleElement ? "text-white-A700" : "text-black"
                        }`}
                      >
                        ${plan.price}/mo
                      </p>
                      <p className="text-md text-gray-900 mb-3">
                        Required Domains: {plan.domains}
                      </p>
                      <p className="text-sm text-gray-600 mb-3">
                        What’s included:
                      </p>
                      <ul className="text-sm text-gray-600 space-y-1 mb-4">
                        {plan.features.map((feature, index) => (
                          <li key={index} className="flex items-center">
                            <CheckCircleIcon className="mr-2 h-6 w-6" />{" "}
                            {feature}
                          </li>
                        ))}
                      </ul>
                      <button
                        className={`w-full text-center py-2 rounded-md transition-all duration-300 transform hover:scale-105 ${
                          isMiddleElement
                            ? "bg-white-A700 text-teal-300"
                            : "bg-teal-300 text-white-A700"
                        }`}
                      >
                        Get Started
                      </button>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
