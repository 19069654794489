import axios from "axios";
import { BACKEND_URL } from "../config/config";
import { API_VERSION } from "config/prices";

const getPayments = async (
  token,
  organizationId,
  type,
) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/${API_VERSION}/payment?token=${token}&organizationId=${organizationId}&type=${type}`
  );

  return response.data;
};

export { getPayments };
