import axios from "axios";
import { BACKEND_URL } from "../config/config";


const getCustomSession = async (token) => {
  const response = await axios.post(
    `${BACKEND_URL}/custom-session?token=${token}`,
    {
      returnUrl: window.location.href,
    }
  );
  return response.data;
};



const getSubBillingPortal = async (token,orgId,subId) => {
  const response = await axios.post(
    `${BACKEND_URL}/billing-portal/${subId}?token=${token}&organizationId=${orgId}`,
    {
      returnUrl: window.location.href,
    }
  );
  return response.data;
};

const downloadInvoice = async (token, orgId,invoiceId) => {
  const response = await axios.get(
    `${BACKEND_URL}/invoice/${invoiceId}?token=${token}&organizationId=${orgId}`,
  );
  return response.data;
};

const cancelSubscription = async (token, orgId,data) => {
  const response = await axios.post(
    `${BACKEND_URL}/cancel-subscription?token=${token}&organizationId=${orgId}`,data
  );
  return response.data;
};

const applyCoupon = async (token, orgId,data) => {
  const response = await axios.post(
    `${BACKEND_URL}/coupon?token=${token}&organizationId=${orgId}`,data
  );
  return response.data;
};

export { getSubBillingPortal, getCustomSession,downloadInvoice,cancelSubscription,applyCoupon };
