import React from "react";

const Stepper = ({ steps, currentStep }) => {
  return (
    <div className="py-12 bg-white">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <nav className="flex sm:flex-col">
          {steps.map((step, index) => (
            <React.Fragment key={index}>
              <div className="flex items-start">
                <span
                  className={`inline-flex items-center justify-center flex-shrink-0 lg:w-8 lg:h-8 h-6 w-6 text-sm font-bold ${
                    index === currentStep
                      ? "text-white bg-teal-300"
                      : "text-gray-900 bg-white-A700 border border-gray-900"
                  } rounded-full`}
                >
                  {index + 1}
                </span>
                <div className="flex-1 lg:ml-5 ml-2">
                  <p className="text-base font-bold text-gray-900">
                    {step.title}
                  </p>
                  <p className="mt-2 font-medium text-gray-500 sm:hidden md:text-sm">
                    {step.description}
                  </p>
                </div>
              </div>
              {index < steps.length - 1 && (
                <svg
                  className="flex-shrink-0 lg:w-12 lg:h-12 w-7 h-7 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="1"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              )}
            </React.Fragment>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Stepper;
