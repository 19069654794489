import axios from "axios";
import { BACKEND_URL } from "config/config";
import { API_VERSION } from "config/prices";
import { DomainSuggestion } from "utils/domainUtils";

interface DomainAvailabilityResponse {
  [domain: string]: {
    status: "available" | "taken" | "premium";
    price?: number; 
    // premium: boolean,
  }
}


export const checkDomainAvailability = async (
  userObj:any,
  domains: string[]
): Promise<DomainAvailabilityResponse> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/api/${API_VERSION}/domain/available?token=${userObj.token}&organizationId=${userObj.organizationId}`,
      { domains }
    );
    return response.data;
  } catch (error) {
    console.error("Error checking domain availability:", error);
  }
};


export const fetchDomainPrice = async (userObj:any,domain: string): Promise<number> => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/api/${API_VERSION}/domain/price?token=${userObj.token}&organizationId=${userObj.organizationId}`,
      { domain }
    );
    return response.data.price;
  } catch (error) {
    console.error("Error fetching domain price:", error);
  }
};

export const updateDomainAvailability = async (
  userObj:any,
  suggestions: DomainSuggestion[]
): Promise<DomainSuggestion[]> => {
  if (!suggestions.length) return [];

  const domainNames = suggestions.map((suggestion) => suggestion.domain);

  // Check availability for all domains in a single API call
  const availabilityResults = await checkDomainAvailability(userObj,domainNames);

  // Update each suggestion with the availability status from the API
  return suggestions.map((suggestion) => ({
    ...suggestion,
    status: availabilityResults[suggestion.domain].status || "taken",
    price: availabilityResults[suggestion.domain].price || 0,
  }));
};
