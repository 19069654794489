import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Card, CardContent, CardHeader, CardFooter } from "components/Card";

const TOOLS = [
  {
    name: "ScaledMail",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b2e9e9b8d27f62da563ce_Frame%201321315470.svg",
    description:
      "Microsoft & Google inboxes optimized for high deliverability and volume sending.",
    pricing: "$199+/month",
    url: "https://scaledmail.com/",
  },
  {
    name: "Local Business Lead List",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b2e9e9b8d27f62da563ce_Frame%201321315470.svg",
    description:
      "Target any type of local business and get real-time contact info – barber shops, dentists, HVAC… we can get them all!",
    pricing: "$5 / 1000 results",
    url: "https://localbusinessleadlist.com/",
  },
  {
    name: "EmailBison",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b2ebfaaf08b15576bdc9c_Frame%201321315470%20(1).svg",
    description:
      "Run your own email sequencer in the cloud. Use code: BEANSTALK.",
    pricing: "$499+/month",
    url: "https://app.reclaim.ai/m/dean-fiacco/beanstalk-consultation-localized-sequencer",
  },
  {
    name: "Smartlead",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b2f463198b634d09f3f2f_Frame%201321315470%20(2).svg",
    description:
      "For cold email outbound, offering unlimited mailboxes and warmup.",
    pricing: "$79+/month",
    url: "https://www.smartlead.ai/?via=dean",
  },
  {
    name: "Clay",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b2fbc576d7a3536a56449_Frame%201321315470%20(1).webp",
    description:
      "With AI targeting, copywriting, and scraping, Clay allows you to execute account-based marketing at scale.",
    pricing: "$349/month",
    url: "https://www.clay.com/?via=88282f",
  },
  {
    name: "LeadMagic",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b30175ff93824f8fc54c7_Frame%201321315470%20(2).webp",
    description:
      "A contact data provider with competitive pricing and quality.",
    pricing: "$99.99+/month",
    url: "https://leadmagic.io/?via=dean",
  },
  {
    name: "Fathom",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b302bbd6825b4c10e82a4_Frame%201321315470%20(1).svg",
    description:
      "An AI note taker for Google Meet calls that links directly with your CRM.",
    pricing: "$19+/month",
    url: "https://fathom.video/?source=agencypartners&ps_partner_key=ZGVhbmZpYWNjbzQ0MQ&ps_xid=uCTzST1c1wpqMK&gsxid=uCTzST1c1wpqMK&gspk=ZGVhbmZpYWNjbzQ0MQ",
  },
  {
    name: "Apollo",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b303b05ed224b917c02fd_Frame%201321315470%20(2).svg",
    description: "A cost-effective contact information database.",
    pricing: "$79+/month",
    url: "https://www.apollo.io/sign-up?utm_medium=partner&utm_source=agency_partnerstack&utm_campaign=agency_deanfiacco441&utm_gspartnerkey=deanfiacco441&ps_partner_key=ZGVhbmZpYWNjbzQ0MQ&ps_xid=iVkUhnYHuJ3AiW&gsxid=iVkUhnYHuJ3AiW&gspk=ZGVhbmZpYWNjbzQ0MQ",
  },
  {
    name: "PandaMatch",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b304cbd9941498b4847d2_Frame%201321315470%20(3).svg",
    description: "A 'lookalike' tool; use code DEAN at checkout for 10% off.",
    pricing: "$99/month",
    url: "https://www.pandamatch.io/",
  },
  {
    name: "EmailGuard",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b3067f1d67a542f738af5_Frame%201321315470%20(3).webp",
    description:
      "A one-stop shop for email health monitoring and unique tools like domain masking. Use code BEANSTALK at checkout.",
    pricing: "$49/month",
    url: "https://emailguard.io/",
  },
  {
    name: "PorkBun",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b30778f09cbd817a535af_Frame%201321315470%20(5).svg",
    description: "Easy to use and inexpensive Domain Registrar.",
    pricing: "$10+/domain",
    url: "https://porkbun.com/",
  },
  {
    name: "Close",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b3096e4635ccc640312c6_Frame%201321315470%20(4).webp",
    description: "Best CRM on a budget, bar none.",
    pricing: "$99/month",
    url: "https://www.close.com/?utm_campaign=partnerstack&utm_content=deanfiacco441&utm_medium=affiliate&utm_source=close-affiliate-partner&pscd=refer.close.com&ps_partner_key=ZGVhbmZpYWNjbzQ0MQ&ps_xid=LxlgfcQhqhrq4k&gsxid=LxlgfcQhqhrq4k&gspk=ZGVhbmZpYWNjbzQ0MQ",
  },
  {
    name: "Scrubby",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b30aed407a1e296ff2556_Frame%201321315470%20(7).svg",
    description:
      "Tackling 'risky' emails like catch-alls and greylists that other services miss.",
    pricing: "$60+/month",
    url: "https://scrubby.io/?fpr=dean13",
  },
  {
    name: "ZoomInfo",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b30bfca83d2b06c3efafb_Frame%201321315470%20(8).svg",
    description: "Try the OG data source that's beloved by enterprise.",
    pricing: "Custom",
    url: "https://www.zoominfo.com/free-trial?camp_id=7014y000001osPRAAY&utm_psgroup=def&pscd=try.zoominfo.com&ps_partner_key=ZGVhbmZpYWNjbzQ0MQ&ps_xid=5jFQAqYFQDQgxn&gsxid=5jFQAqYFQDQgxn&gspk=ZGVhbmZpYWNjbzQ0MQ",
  },
  {
    name: "Warmy.io",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b30d6f816e611d1ab6629_Frame%201321315470%20(9).svg",
    description: "Boost cold email deliverability with AI-powered warm-up.",
    pricing: "$49/mailbox",
    url: "https://www.warmy.io/pricing?fpr=dean74",
  },
  {
    name: "Supergrow.ai",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b30ecd407a1e296ff3b72_Frame%201321315470%20(10).svg",
    description:
      "Our top choice for LinkedIn content generation, scheduling, & engagement.",
    pricing: "$19/month",
    url: "https://www.supergrow.ai/?ref=dean",
  },
  {
    name: "ListKit",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b31067140ff853f014727_Frame%201321315470%20(5).webp",
    description:
      "Verified leads database with direct SmartLead integration. No more paying for leads that don't convert.",
    pricing: "$79+/month",
    url: "https://www.listkit.io/?ref=beanstalk",
  },
  {
    name: "MillionVerifier",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b311b307c6143fc2e1267_Frame%201321315470%20(12).svg",
    description:
      "Email verification service helps remove invalid, inactive, and spam email addresses.",
    pricing: "$50+/month",
    url: "https://www.millionverifier.com/#ea_r7qc9cj",
  },
  {
    name: "HeyReach",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b31388ca54aae55090392_Frame%201321315470%20(13).svg",
    description:
      "Automates your LinkedIn outreach, saving you time and effort.",
    pricing: "$79/month per seat",
    url: "https://www.heyreach.io/?via=dean",
  },
  {
    name: "Riverside",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b3148307c6143fc2e2695_Frame%201321315470%20(14).svg",
    description: "Amazing software for video podcasting & interviews.",
    pricing: "$24/month",
    url: "https://riverside.fm/?utm_campaign=campaign_5&utm_medium=affiliate&utm_source=rewardful&via=dean-fiacco",
  },
  {
    name: "CloudTask",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b31622c8379ee924fa1bf_Frame%201321315470%20(6).webp",
    description:
      "Connect with a global pool of qualified remote sales talent to find the perfect team.",
    pricing: "Custom",
    url: "https://cloudtask.com/find-remote-jobs-4/?ps_partner_key=ZGVhbmZpYWNjbzQ0MQ&ps_xid=IrcOBWEyoP7nNx&gsxid=IrcOBWEyoP7nNx&gspk=ZGVhbmZpYWNjbzQ0MQ",
  },
  {
    name: "Prospeo",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b317f576d7a3536a6742d_Frame%201321315470%20(7).webp",
    description:
      "Don't know the email address? Use Prospeo to test different email addresses until they find the right one.",
    pricing: "$99/month",
    url: "https://prospeo.io/?via=dean",
  },
  {
    name: "Gamma",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b31aa9c57ebcd2cb93838_Frame%201321315470%20(8).webp",
    description:
      "Empowers you to create dynamic presentations and webpages with the power of AI.",
    pricing: "$8/month",
    url: "https://gamma.app/?gspk=ZGVhbmZpYWNjbzQ0MQ&gsxid=gUvGXsukulJCag&ps_partner_key=ZGVhbmZpYWNjbzQ0MQ&ps_xid=gUvGXsukulJCag&pscd=try.gamma.app&utm_source=ps",
  },
  {
    name: "Apify",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b31cd7f4eda27bef0990f_Frame%201321315470%20(9).webp",
    description:
      "Simplifies web scraping, turning complex tasks into manageable workflows.",
    pricing: "$49/month",
    url: "https://apify.com/?fpr=uez7h",
  },
  {
    name: "AIA",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b2e9e9b8d27f62da563ce_Frame%201321315470.svg",
    description:
      "Helps you build a strong online presence on LinkedIn with its AI-powered avatars.",
    pricing: "$47/month per profile",
    url: "https://getaia.io/?_go=dean37",
  },
  {
    name: "Golden Leads",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b33072c8379ee92505dae_Frame%201321315470%20(20).svg",
    description:
      "Unlocks the full potential of your LinkedIn Sales Navigator by extracting unlimited leads.",
    pricing: "Variable",
    url: "https://goldenleads.io/?fpr=dean30",
  },
  {
    name: "Bonsai",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b33167f4eda27bef13193_Frame%201321315470%20(21).svg",
    description:
      "Streamlines client journey, enabling effortless invoicing and secure payments.",
    pricing: "$17",
    url: "https://www.hellobonsai.com/?fp_ref=beanstalkconsulting",
  },
  {
    name: "Secret",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b3345bd6825b4c10feccd_Frame%201321315470%20(10).webp",
    description:
      "Unlocks significant savings on essential software through exclusive discounts and deals.",
    pricing: "$99",
    url: "https://www.joinsecret.com/?via=dean",
  },
  {
    name: "Senja",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b335cbd9941498b49c6b4_Frame%201321315470%20(23).svg",
    description:
      "Enables collection, management, and showcase of testimonials to boost trust and conversions.",
    pricing: "$39/month",
    url: "https://senja.io/?via=dean78",
  },
  {
    name: "Tella",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b2e9e9b8d27f62da563ce_Frame%201321315470.svg",
    description: "Simple video recorder. Loom alternative.",
    pricing: "$19/month",
    url: "https://refer.tella.com/dean-fiacco",
  },
  {
    name: "RB2B",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b337c307c6143fc2f2775_Frame%201321315470%20(24).svg",
    description:
      "Gives person-level data on website visitors (names, titles, and emails).",
    pricing: "$39/month",
    url: "https://www.rb2b.com/?ref=beanstalk",
  },
  {
    name: "Taplio",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b34a93198b634d0a18df2_Frame%201321315470%20(12).webp",
    description: "LinkedIn content scheduling & engagement platform.",
    pricing: "$49/month",
    url: "https://taplio.com/?via=dean",
  },
  {
    name: "n8n",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b34ba564b81248e97747b_Frame%201321315470%20(26).svg",
    description:
      "Notifications for any start-up or small business. Cheaper than Zapier.",
    pricing: "$20+/month",
    url: "https://n8n.io/?ps_partner_key=ZGVhbmZpYWNjbzQ0MQ&ps_xid=054CNR2APiHCw3&gsxid=054CNR2APiHCw3&gspk=ZGVhbmZpYWNjbzQ0MQ",
  },
  {
    name: "PipeBoss",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b34d4f8da1771c02fce01_Frame%201321315470%20(13).webp",
    description:
      "Stop wasting leads in your sales pipeline and turn them into revenue.",
    pricing: "Custom",
    url: "https://pipeboss.co/?via=beanstalk",
  },
  {
    name: "Bouncebean",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b34e35ff93824f8fe6326_Frame%201321315470%20(28).svg",
    description: "Great alternative tool for validating emails and catch-alls.",
    pricing: "Custom",
    url: "https://bounceban.com/?via=dean",
  },
  {
    name: "Reclaim AI",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/678b34f37f4eda27bef21b38_Frame%201321315470%20(29).svg",
    description:
      "Calendly alternative: automate scheduling, prioritize tasks seamlessly.",
    pricing: "Custom",
    url: "https://reclaim.ai/?utm_campaign=partnerstack&utm_term=ps_543ca70ffa05&pscd=go.reclaim.ai&ps_partner_key=NTQzY2E3MGZmYTA1&ps_xid=L0b1xWQydPfKg5&gsxid=L0b1xWQydPfKg5&gspk=NTQzY2E3MGZmYTA1",
  },
  {
    name: "Mailreef",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/67d1cf44f07b830a8e6285de_Frame%201321315470.svg",
    description: "Top tier private SMTP solution with dedicated servers.",
    pricing: "Custom",
    url: "https://www.mailreef.com/",
  },
  {
    name: "Pipl.ai",
    icon: "https://cdn.prod.website-files.com/67859eada0e7c0a363ca1ef8/67d1cf44503a7bb40af34ff3_Frame%201321315470%20(1).svg",
    description: "Another great option for a solid cold email sequencer.",
    pricing: "Custom",
    url: "https://pipl.ai/",
  },
];

const PricingCard = ({
  icon,
  title,
  price,
  description,
  url,
  ctaText = "Try Now",
}) => {
  return (
    <Card className="bg-white-A700 overflow-hidden transition-all duration-300 hover:shadow-lg bg-white-A700 border border-gray-100 rounded-2xl h-full">
      <CardHeader className="p-6 pb-0">
        <img src={icon} alt={title} className="w-20 h-20" />
        <h3 className="text-xl font-bold text-gray-800">{title}</h3>
        <p className="text-xl font-semibold text-gray-600 my-3">{price}</p>
      </CardHeader>
      <CardContent className="p-6 pt-2">
        <p className="text-gray-600 min-h-[50px]">{description}</p>
      </CardContent>
      <CardFooter>
        <a
          className={`group relative inline-flex items-center justify-center overflow-hidden rounded-full
        px-6 py-2.5 font-medium tracking-wide
        bg-gradient-to-r from-teal-300 to-green-400
        text-white shadow-lg transition-all duration-300
        hover:shadow-teal-500/25 hover:scale-[1.01]
        border border-teal-700/10`}
          href={url}
          target="_blank"
        >
          <span className="absolute inset-0 w-full h-full bg-gradient-to-br from-teal-500/20 to-green-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
          <span className="flex items-center justify-center w-full h-full relative z-10">
            <span className="mr-2">{ctaText}</span>
            <ArrowRightIcon className="w-5 h-5 transition-all duration-300 group-hover:translate-x-1" />
          </span>
        </a>
      </CardFooter>
    </Card>
  );
};

export default function Tools() {
  
  return (
    <div className="flex max-h-[85vh] flex-col p-5 overflow-y-scroll">
      {/* Content */}
      <div className="relative container mx-auto px-4">
        <div className="text-center mb-16">
          <h1 className="text-4xl md:text-5xl font-bold mb-6 text-gray-900">
            Choose the Right{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-teal-600 to-teal-500">
              Tools
            </span>{" "}
            for Your Outbound Strategy
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            We've partnered with the best-in-class tools to help you scale your
            outbound efforts efficiently.
          </p>
        </div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto">
          {TOOLS.map((item, i) => (
            <PricingCard
              icon={item.icon}
              title={item.name}
              price={item.pricing}
              description={item.description}
              ctaColor="green"
              url={item.url}
            />
          ))}
        </div>

        <div className="mt-20 text-center">
          <p className="text-gray-600 mb-6">
            Need help deciding? Our experts can guide you through the best
            combination for your needs.
          </p>
          <a
            className={`group relative inline-flex items-center justify-center overflow-hidden rounded-full
        px-6 py-2.5 font-medium tracking-wide
        bg-gradient-to-r from-teal-300 to-green-400
        text-white shadow-lg transition-all duration-300
        hover:shadow-teal-500/25 hover:scale-[1.01]
        border border-teal-700/10`}
            href="https://www.beanstalkconsulting.co/"
            target="_blank"
          >
            <span className="absolute inset-0 w-full h-full bg-gradient-to-br from-teal-500/20 to-green-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></span>
            <span className="flex items-center justify-center w-full h-full relative z-10">
              <span className="mr-2"> Book a Consultation</span>
              <ArrowRightIcon className="w-5 h-5 transition-all duration-300 group-hover:translate-x-1" />
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}
