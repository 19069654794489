const Badge = ({ value, skip = false, color = "teal" }) => {
  if (skip && !value) return null;
  return (
    <p
      className={`text-xs font-semibold border-${color}-300 text-${color}-500 bg-${color}-50 border rounded-full inline-flex items-center px-2.5 py-1`}
    >
      {value}
    </p>
  );
};

export default Badge;
