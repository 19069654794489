import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Button, Input, PasswordInput, Text } from "components";
import { default as ModalProvider } from "react-modal";
import { Spinner } from "components/Spinner";
import { toast } from "react-toastify";
import CustomInput from "components/CustomInput";
import { INTEGRATIONS } from "config/constants";
import { checkPasswordStrength, isValidEmail } from "utils/validations";
import { updateUser } from "api/user";
import { useEffect } from "react";
import { addTeam, getTeam, removeTeam } from "api/team";
import MultipleSelectInput from "components/MultiSelectInput";
import { getOrganizations } from "api/organization";
import {
  ArrowRightEndOnRectangleIcon,
  MagnifyingGlassIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { createApp, deleteApp, getAllApps } from "api/apps";

function AppDataModal({ isShowModal, onModalClose, user, app, ...props }) {
  const [loading, setLoading] = useState(false);
  const handleDelete = async (item) => {
    setLoading(true);
    try {
      const response = await deleteApp(
        user.token,
        user.organizationId,
        item.id
      );
      toast.success("Delete Successfully");
      window.location.reload();
    } catch (error) {
      toast.error(error.response?.data?.message || "Error deleting App");
    }
    setLoading(false);
  };
  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isShowModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-lg p-8 bg-white-A700 rounded-lg">
        <div className="flex items-center justify-between w-full mb-2">
          <div className="flex gap-x-3 items-center">
            <img
              className="flex-shrink-0 object-cover w-10 h-10 rounded-full"
              src={app.logo}
              alt={app.name}
            />
            <Text size="lg" as="p" className="tracking-[0.18px]">
              {app.name}
            </Text>
          </div>
          <XMarkIcon
            className="h-12 w-12 cursor-pointer"
            onClick={onModalClose}
          />
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <div className="w-full mb-3 gap-2 flex flex-col">
            {app.connections.map((conn, i) => (
              <div
                className="flex bg-gray-100 p-2 border border-gray-300 rounded-md items-center justify-between"
                key={i}
              >
                <div>
                  <p>{conn.name}</p>
                  <p className="text-md font-medium text-gray-500">
                    Created At: {conn.createdAt}
                  </p>
                </div>
                <TrashIcon
                  className="w-5 text-red-400 cursor-pointer"
                  onClick={() => handleDelete(conn)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </ModalProvider>
  );
}

function AddNewAppModal({ isShowModal, onModalClose, user, app, ...props }) {
  const [loading, setLoading] = useState(false);
  const [connInput, setConnInput] = useState({ name: "", key: "" });
  const [searchApp, setSearchApp] = useState(app ? app : "");
  const [filteredApps, setFilteredApps] = useState([]);
  const [addApp, setAddNewApp] = useState(app ? app : "");
  const handleInputChange = (field, value) => {
    setConnInput((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const filterApps = () => {
    const filtered = INTEGRATIONS.filter(
      (item) => item.name.toLowerCase().includes(searchApp.toLowerCase()) // Proper filtering
    );
    setFilteredApps(filtered);
  };

  useEffect(() => {
    filterApps();
  }, [searchApp]);
  const handleCreate = async () => {
    if (!connInput.name.trim() || !connInput.key.trim()) {
      toast.error("Kindly input all data");
      return;
    }
    setLoading(true);
    try {
      const response = await createApp(user.token, user.organizationId, 
       { ...connInput, app: addApp },
      );
      toast.success("Create Successfully");
      window.location.reload();
    } catch (error) {
      toast.error(error.response?.data?.message || "Error creating App");
    }
    setLoading(false);
  };
  const handleGoBack = async () => {
    setConnInput({ name: "", key: "" });
    if (app) {
      onModalClose();
    } else {
      setAddNewApp(null);
    }
  };
  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isShowModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-2xl p-8 bg-white-A700 rounded-lg">
        <div className="flex items-center justify-between w-full mb-2">
          <Text size="lg" as="p" className="tracking-[0.18px]">
            Add New App
          </Text>
          <XMarkIcon
            className="h-12 w-12 cursor-pointer"
            onClick={onModalClose}
          />
        </div>
        {addApp ? (
          <div className="flex w-full flex-col gap-y-3">
            <Input
              placeholder="Name Your Connection"
              className="w-full bg-white-A700" // Ensure the input fills the 60% container
              value={connInput.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
            />
            <Input
              placeholder="Add Key Here"
              className="w-full bg-white-A700" // Ensure the input fills the 60% container
              value={connInput.key}
              onChange={(e) => handleInputChange("key", e.target.value)}
            />
            {loading ? (
              <Spinner />
            ) : (
              <div className="flex gap-x-4 self-end">
                <button
                  onClick={handleGoBack}
                  className="bg-gray-300 rounded hover:bg-gray-400 flex items-center justify-center text-white p-2 text-white-A700"
                >
                  Go Back
                </button>
                <button
                  onClick={handleCreate}
                  className="bg-teal-300 rounded hover:bg-teal-400 flex items-center justify-center text-white p-2 text-white-A700"
                >
                  Create Connection
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className="w-full mb-3 gap-2 flex flex-col">
            <Input
              placeholder="Search App"
              className="w-full bg-white-A700" // Ensure the input fills the 60% container
              prefix={<MagnifyingGlassIcon className="w-5" />}
              value={searchApp}
              onChange={(e) => setSearchApp(e.target.value)}
            />
            <div className="flex flex-col space-y-3">
              {filteredApps.map((item, i) => (
                <Integration
                  key={i}
                  name={item.name}
                  description={item.description}
                  logo={item.logo}
                  link={item.link}
                  add={true}
                  handleClick={undefined}
                  handleAdd={() => setAddNewApp(item.name)}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </ModalProvider>
  );
}

function UpdateInformationModal({ user, isShowModal, onModalClose, ...props }) {
  const [inpval, setInpval] = useState({
    password: "",
    cPassword: "",
  });
  const [loading, setLoading] = useState(false);

  const setVal = (e) => {
    const { name, value } = e.target;
    setInpval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleUpdateUser = async () => {
    const { password, cPassword } = inpval;
    if (password) {
      const { rules, isStrong } = checkPasswordStrength(
        inpval.password,
        inpval.name
      );
      if (!isStrong) {
        return toast.error("Passowrd not strong enought");
      }
      if (password !== cPassword) {
        return toast.error("Passwords do not match!");
      }
    }

    setLoading(true);
    try {
      const response = await updateUser(user.token, user.organizationId, {
        password,
        cPassword,
      });
      toast.success("User updated successfully");
      onModalClose(); // Close the modal on success
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to update user.");
    }
    setLoading(false);
  };

  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isShowModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-lg p-8 bg-white-A700 rounded-lg">
        <Text size="lg" as="p" className="tracking-[0.18px] mb-4">
          Update Password
        </Text>

        <div className="w-full mb-3 gap-2 flex flex-col">
          <PasswordInput
            setVal={setVal}
            name="password"
            inpval={inpval.password}
            label="New Password"
          />
          <PasswordInput
            placeholder="Enter your password again in here"
            label="Confirm Password"
            setVal={setVal}
            name="cPassword"
            inpval={inpval.cPassword}
          />
        </div>
        <div className="flex justify-between w-full gap-4">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Button
                shape="round"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                onClick={onModalClose}
              >
                Cancel
              </Button>
              <Button
                variant="gradient"
                shape="round"
                color="teal_300_teal_600"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                style={{ backgroundColor: "#F44336", color: "white" }}
                onClick={handleUpdateUser}
              >
                Update
              </Button>
            </>
          )}
        </div>
      </div>
    </ModalProvider>
  );
}

function AddTeamMembernModal({
  user,
  isShowModal,
  onModalClose,
  members,
  organizations,
  loadMembers,
  ...props
}) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState([]);
  const addMember = async () => {
    if (!isValidEmail(email)) {
      toast.error("Invalid Email");
      return;
    }
    if (selectedOrg.length === 0) {
      toast.error("Select a organiztion to add member");
      return;
    }
    setLoading(true);
    try {
      const response = await addTeam(user.token, user.organizationId, {
        email,
        organization: selectedOrg.map((item) => item.value),
      });
      onModalClose();
      loadMembers();
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to add member");
    }
    setLoading(false);
  };
  useEffect(() => {
    if (user?.organizationId && organizations?.length > 0) {
      const matchedOrg = organizations.find(
        (org) => org.value === user.organizationId
      );
      if (matchedOrg) {
        setSelectedOrg([matchedOrg]);
      }
    }
  }, [organizations]);
  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isShowModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-lg p-8 bg-white-A700 rounded-lg">
        <Text size="lg" as="p" className="tracking-[0.18px] mb-4">
          Add New Team Member
        </Text>
        <input
          type="email"
          name="email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="Email address"
          className="border-[1px] block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300"
        />
        <div className="my-2" />
        <MultipleSelectInput
          options={organizations}
          label="Organization to add:"
          placeholder="Add member to which organiztion"
          selected={selectedOrg}
          setSelected={(v) => setSelectedOrg(v)}
          search={true}
          multiselect={true}
        />
        <div className="my-2" />

        <div className="flex mt-2 items-center justify-end w-full">
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Button
                shape="round"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px]"
                onClick={onModalClose}
              >
                Cancel
              </Button>
              <Button
                variant="gradient"
                color="teal_300_teal_600"
                className="tracking-[1.25px] uppercase font-medium min-w-[150px] rounded-md"
                style={{ backgroundColor: "#F44336", color: "white" }}
                onClick={addMember}
              >
                Add
              </Button>
            </>
          )}
        </div>
      </div>
    </ModalProvider>
  );
}

function Integration({
  name,
  description,
  logo,
  link,
  connections,
  handleClick,
  handleAdd,
}) {
  return (
    <div className="flex items-center justify-between space-x-5 bg-gray-100 p-1 border border-gray-300 rounded-md px-2">
      <div className="flex items-center flex-1 min-w-0">
        <img
          className="flex-shrink-0 object-cover w-10 h-10 rounded-full"
          src={logo}
          alt={name}
        />
        <div className="flex-1 min-w-0 ml-4">
          <p className="text-sm font-bold text-gray-900 truncate">{name}</p>
          <p className="mt-1 text-sm font-medium text-gray-500 truncate">
            {description}
          </p>
          <a
            href={link}
            title=""
            target="_blank"
            className="text-sm font-medium text-gray-400 transition-all duration-200 hover:text-gray-900"
          >
            Learn More
          </a>
        </div>
      </div>
      {connections ? (
        <div className="flex items-center gap-x-5">
          <div className="flex flex-col items-center justify-center">
            <p>{connections.length}</p>
            <p className="text-sm font-medium text-gray-400">Connections</p>
          </div>
          <ArrowRightEndOnRectangleIcon
            className="w-5 cursor-pointer hover:text-teal-300"
            onClick={() => handleClick(connections)}
          />
        </div>
      ) : (
        <p
          className="text-md mr-5 cursor-pointer font-medium text-gray-400 hover:text-teal-300"
          onClick={handleAdd}
        >
          Add
        </p>
      )}
    </div>
  );
}

export default function Settings() {
  const [appData, setAppData] = useState(null);
  const [memberLoad, setMemberLoad] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [teamMemberModal, setTeamMemberModal] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [apps, setApps] = useState({});
  const [loadingApps, setLoadingApps] = useState(false);
  const user = useSelector((state) => state.user.userObj);
  const [tab, setTab] = useState("Profile");
  const [addNewApp, setAddNewApp] = useState(null);
  // const [tab, setTab] = useState("Profile");
  const [profile, setProfile] = useState({
    name: user.name,
    email: user.email,
  });
  const [team, setTeam] = useState([]);
  const loadMembers = async () => {
    setMemberLoad(true);
    try {
      const response = await getTeam(user.token, user.organizationId);
      setTeam(response.team);
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to load team members."
      );
    }
    setMemberLoad(false);
  };
  const handleRemoveMember = async (member) => {
    if (user.role !== "Admin") {
      toast.error("Only site admin remove member");
      return;
    }
    setMemberLoad(true);
    try {
      const response = await removeTeam(
        user.token,
        user.organizationId,
        member.id
      );
      toast.success("Removed Successfully");
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to reamove team members."
      );
    }
    await loadMembers();
    setMemberLoad(false);
  };
  const loadOrganizations = async () => {
    try {
      const response = await getOrganizations(user.token, user.organizationId); // Pass the body to POST request
      setOrganizations(
        response.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error fetching organizations"
      );
    }
  };

  const loadApps = async () => {
    setLoadingApps(true);
    try {
      const response = await getAllApps(user.token, user.organizationId); // Pass the body to POST request
      setApps(response);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching Apps");
    }
    setLoadingApps(false);
  };
  useEffect(() => {
    loadOrganizations();
    loadMembers();
    loadApps();
  }, [user]);
  return (
    <main>
      <div className="py-2">
        <div className="px-4 mx-auto sm:px-6 md:px-8">
          <h1 className="text-2xl font-bold text-gray-900">Settings</h1>
        </div>

        <div className="px-4 mx-auto mt-8 sm:px-6 md:px-8">
          <div className="w-full pb-1 overflow-x-auto">
            <div className="border-b border-gray-200 mb-5">
              <nav className="flex -mb-px space-x-5">
                {["Profile", "Team", "Integrations"].map((item, i) => (
                  <div
                    onClick={() => setTab(item)}
                    key={i}
                    className={`py-2 text-sm font-medium  transition-all duration-200 border-b-2  whitespace-nowrap cursor-pointer ${
                      tab == item
                        ? "text-teal-300 border-teal-300"
                        : "text-gray-500 hover:border-gray-300 border-transparent  "
                    }`}
                  >
                    {item}
                  </div>
                ))}
              </nav>
            </div>
          </div>
          {tab == "Profile" && (
            <div className="space-y-6">
              <div className="grid grid-cols-2">
                <label
                  htmlFor=""
                  className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                >
                  Name
                </label>
                <input
                  type="text"
                  name=""
                  id=""
                  placeholder=""
                  value={profile.name}
                  className="border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300"
                />
              </div>

              <div className="grid grid-cols-2">
                <label
                  htmlFor=""
                  className="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  name=""
                  id=""
                  placeholder=""
                  value={profile.email}
                  className="border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300"
                />
              </div>
              <div className="mt-8 flex justify-end">
                <button
                  onClick={() => setPasswordModal(true)}
                  className="w-1/2 px-6 py-3 bg-teal-300 border  hover:bg-teal-600 text-white-A700 rounded-md"
                >
                  Update Password
                </button>
              </div>
            </div>
          )}
          {tab == "Team" && (
            <div>
              <div
                className="flex justify-end"
                onClick={() => {
                  if (user.role !== "Admin") {
                    toast.error("Only site admin can add member");
                    return;
                  }
                  setTeamMemberModal(true);
                }}
              >
                <button className="w-full lg:w-1/4 sm:px-1 px-4 py-2 bg-teal-300 border  hover:bg-teal-600 text-white-A700 rounded-md">
                  Add New Member
                </button>
              </div>
              <div className="flow-root mt-8">
                <div className="-my-5 divide-y divide-gray-100">
                  {memberLoad ? (
                    <Spinner />
                  ) : (
                    team &&
                    team.map((user, index) => (
                      <div className="p-5 rounded-md shadow-md " key={index}>
                        <div className="flex items-center sm:flex-col">
                          <div className="ml-4">
                            <p className="text-sm font-bold text-gray-900">
                              {user["User Name"][0] === "USER_NOT_JOINED"
                                ? ""
                                : user["User Name"][0]}
                            </p>
                            <p className="mt-1 text-sm font-medium text-gray-500">
                              {user["User Email"][0]}
                            </p>
                          </div>

                          <div className="flex items-center justify-end ml-auto space-x-8">
                            {user["User Name"][0] === "USER_NOT_JOINED" ? (
                              <p className="text-sm font-bold text-gray-900">
                                Invitation Not Accepted Yet
                              </p>
                            ) : (
                              <>
                                <div
                                  onClick={() => handleRemoveMember(user)}
                                  title="Remove"
                                  className="text-sm font-medium text-red-400 transition-all duration-200 hover:text-red-600 cursor-pointer"
                                >
                                  Remove
                                </div>
                                {/* <div
                                  title="Edit"
                                  className="text-sm font-medium text-indigo-600 transition-all duration-200 hover:text-indigo-700"
                                >
                                  Edit
                                </div> */}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          )}
          {tab === "Integrations" && (
            <div>
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-base font-bold text-gray-900">
                    Connect Apps
                  </p>
                  <p className="mt-1 text-sm font-medium text-gray-500">
                    Seamlessly connect third-party apps and manage all your
                    accounts in one place.
                  </p>
                </div>
                <div>
                  <button
                    onClick={() => setAddNewApp("")}
                    className="bg-teal-300 rounded hover:bg-teal-400 flex items-center justify-center text-white p-2 text-white-A700"
                  >
                    <PlusIcon className="w-6 mr-2" />
                    <p>Add Connection</p>
                  </button>
                </div>
              </div>
              {loadingApps ? (
                <Spinner />
              ) : (
                <div className="flow-root mt-8">
                  <div className="-my-5 divide-y divide-gray-200">
                    <div className="flex flex-col space-y-3">
                      {INTEGRATIONS.map((item, i) => (
                        <Integration
                          key={i}
                          name={item.name}
                          description={item.description}
                          logo={item.logo}
                          link={item.link}
                          connections={apps[item.name]}
                          add={false}
                          handleClick={(e) =>
                            setAppData({ ...item, connections: e })
                          }
                          handleAdd={() => setAddNewApp(item.name)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <UpdateInformationModal
        isShowModal={passwordModal}
        onModalClose={() => setPasswordModal(false)}
        user={user}
      />
      {appData && (
        <AppDataModal
          isShowModal={appData != null}
          onModalClose={() => setAppData(null)}
          app={appData}
          user={user}
        />
      )}
      {addNewApp != null && (
        <AddNewAppModal
          isShowModal={addNewApp != null}
          onModalClose={() => setAddNewApp(null)}
          app={addNewApp}
          user={user}
        />
      )}
      <AddTeamMembernModal
        isShowModal={teamMemberModal}
        onModalClose={() => setTeamMemberModal(false)}
        user={user}
        team={team}
        organizations={organizations}
        loadMembers={loadMembers}
      />
    </main>
  );
}
