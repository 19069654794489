import axios from "axios";
import { BACKEND_URL } from "../config/config";
import { API_VERSION } from "config/prices";

const getOrganizations = async (token,orgId) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/${API_VERSION}/organization?organizationId=${orgId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};


const createOrganization = async (token,orgId,orgName) => {
  const response = await axios.post(
    `${BACKEND_URL}/api/${API_VERSION}/organization?organizationId=${orgId}&token=${token}`,
    {
      name:orgName
    }
  );
  return response.data;
};

export { getOrganizations,createOrganization };
