import { Button } from "components";
import React, { useState } from "react";
import CustomInput from "components/CustomInput";
import { toast } from "react-toastify";

import NumericDropdown from "components/NumericDropdown";
import Stepper from "components/Stepper";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Spinner } from "flowbite-react";
import SenderNameConfiguration from "components/SenderNameConfiguration";
import { postOrder } from "api/order";
import { DomainInput } from "components/DomainInput";
import { DomainEmailSequencerInput } from "components/DomainEmailSequencerInput";
import { generateDomains } from "utils/utils";
import { validateMailboxes } from "utils/validations";
import Toggle from "components/Toggle";

const steps = [
  {
    title: "Email Sequencer",
    description: "Confirm email sequencer",
  },
  {
    title: "Outlook",
    description: "Set up your outlook mailboxes",
  },
  {
    title: "Google",
    description: "Set up your google mailboxes",
  },
  // {
  //   title: "Finalize",
  //   description: "Finalize your configuration",
  // },
];

export default function MixedOrder() {
  const location = useLocation();
  const navigate = useNavigate();

  const { userObj } = useSelector((state) => state.user);
  const { plan, payment } = location.state || {};
  useEffect(() => {
    if (!plan || !payment || !plan.domains || !payment.Data.qty) {
      navigate("/"); // Redirect to home
    }
  }, [plan, payment, navigate]);

  const qty = payment.Data.qty;
  const outlookDomains = plan.microsoft.domains;
  const microsoftDomains = plan.google.mailbox / 2;
  useEffect(() => {
    if (!plan) {
      navigate("/"); // Redirect to home page
    }
  }, [plan, navigate]);

  const [data, setData] = useState({
    qty: qty,
    common: {
      dhp: {
        domainHostProvider: [],
        domainHostProviderLink: "",
        domainHostUsername: "",
        domainHostPassword: "",
      },
      esp: {
        esp: [],
        espLink: "",
        emailOutreachUsername: "",
        emailOutreachPassword: "",
        workspace: "",
      },
    },
    google: {
      aliases: [],
      aliases_count: microsoftDomains * 2,
      domains: generateDomains(microsoftDomains * qty),
      usersPerDomain: 2,
    },
    outlook: {
      ct: false,
      aliases: [],
      aliases_count: 49 * outlookDomains * qty,
      domains: generateDomains(outlookDomains * qty),
    },
  });

  const handleInputChange = (path, value) => {
    const keys = path.split(".");
    setData((prev) => {
      let updatedData = { ...prev };
      let nestedData = updatedData;

      // Traverse to the desired field and update the value
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          nestedData[key] = value; // Set the value at the final key
        } else {
          nestedData = nestedData[key]; // Traverse deeper into nested structure
        }
      });

      return updatedData;
    });
  };

  const [purchasedDomain, setPurchasedDomain] = useState([]);
  const checkPurhcasedDomains = () => {
    const domains = payment.Data?.domains?.selectedDomains || [];
    if (domains.length > 0) {
      const pDomains = domains.map((item, i) => ({
        label: item.domain,
        value: item.domain,
      }));
      setPurchasedDomain(pDomains);
    }
  };

  useEffect(() => {
    checkPurhcasedDomains();
  }, [payment]);

  const [finalLoading, setFinalLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const submitOrder = async () => {
    setFinalLoading(true);

    try {
      const response = await postOrder(
        userObj.token,
        userObj.organizationId,
        payment["UUID"],
        data
      );
      navigate("/billing?new-order=true");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Unknown Error");
    }
    setFinalLoading(false);
  };

  const handleNextStep = async () => {
    if (currentStep === 1) {
      const msg = validateMailboxes(
        data.outlook.domains,
        data.outlook.aliases,
        data.outlook.aliases_count
      );
      if (msg) {
        toast.error(msg);
        return;
      }
      if (purchasedDomain.length > 0) {
        const selectedDomainValues = Object.values(data.outlook.domains)
          .flat()
          .map((item) => item.domain);
        const availableDomains = purchasedDomain.filter(
          (option) => !selectedDomainValues.includes(option.value)
        );
        const d = availableDomains.map((item, i) => ({
          domain: item.value,
          redirect_to: "",
          sender_names: [{ first_name: "", last_name: "" }],
        }));
        handleInputChange("google.domains", d);
      }
    }
    if (currentStep === 2) {
      const msg = validateMailboxes(
        data.google.domains,
        data.google.aliases,
        data.google.aliases_count
      );
      if (msg) {
        toast.error(msg);
        return;
      }
      await submitOrder();
      return;
    }
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="flex h-full flex-col pr-3 max-h-[85vh] overflow-auto">
      <p className="text-xl font-bold text-gray-900">{plan.reason}</p>

      <Stepper steps={steps} currentStep={currentStep} />

      {/* Step content */}
      <section className="bg-white">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {currentStep === 0 && (
            <div className="flex flex-col h-full">
              <DomainEmailSequencerInput
                common={data.common}
                handleInputChange={handleInputChange}
                domainInformation={purchasedDomain.length>0?false:true}
              />
            </div>
          )}

          {currentStep === 1 && (
            <div className="flex gap-4 justify-between">
              <div className="flex flex-col w-[60vw]">
                <DomainInput
                  qty={data.qty}
                  domains={data.outlook.domains}
                  handleInputChange={(field, value) =>
                    handleInputChange(`outlook.${field}`, value)
                  }
                  purchasedDomain={purchasedDomain}
                  select={true}
                />
                <div className="flex flex-col gap-3 mt-3">
                  <div className="flex items-center gap-3">
                    <div className="w-1/2">
                      <CustomInput
                        label="Mailboxes"
                        placeholder="Enter how many mailboxes to create"
                        type="number"
                        min="1"
                        max="49"
                        value={data.outlook.aliases_count}
                        onChange={(e) =>
                          handleInputChange(
                            "outlook.aliases_count",
                            e.target.value
                          )
                        }
                        disabled={true}
                      />
                    </div>
                    <Toggle
                      className="flex-col"
                      label="Custom Tracking"
                      value={data.outlook.ct}
                      onChange={(val) => handleInputChange("outlook.ct", val)}
                    />
                  </div>
                </div>
                <div className="mt-4" />
                <div className="flex flex-col gap-4 bg-white-A700 p-5 rounded-md mb-5">
                  <SenderNameConfiguration
                    aliases={data.outlook.aliases}
                    domains={data.outlook.domains}
                    aliases_count={data.outlook.aliases_count}
                    handleInputChange={(field, value) =>
                      handleInputChange(`outlook.${field}`, value)
                    }
                    purchasedDomain={purchasedDomain}
                    select={false}
                  />
                </div>
              </div>
            </div>
          )}

          {currentStep === 2 && (
            <div className="flex flex-col h-full">
              <h1 className="text-2xl font-bold mb-4">Domains Information</h1>
              <div className="flex flex-col gap-4 bg-white-A700 p-5 rounded-md mb-5">
                <DomainInput
                  qty={data.qty}
                  domains={data.google.domains}
                  handleInputChange={(field, value) =>
                    handleInputChange(`google.${field}`, value)
                  }
                  purchasedDomain={purchasedDomain}
                  // availableDomains={availableDomains}
                  // setAvailableDomains={null}
                />
                {/* <NumericDropdown
                  label="Number of Users Per Domain"
                  value={data.google.usersPerDomain}
                  onChange={(newValue) =>
                    handleInputChange("google.usersPerDomain", newValue)
                  }
                  max={3}
                /> */}
              </div>
              <h1 className="text-2xl font-bold mb-4">Mailboxes Information</h1>
              <div className="flex flex-col gap-4 bg-white-A700 p-5 rounded-md mb-5">
                <SenderNameConfiguration
                  aliases={data.google.aliases}
                  domains={data.google.domains}
                  aliases_count={data.google.aliases_count}
                  handleInputChange={(field, value) =>
                    handleInputChange(`google.${field}`, value)
                  }
                />
              </div>
            </div>
          )}

          {finalLoading ? (
            <Spinner className="w-8 h-8" />
          ) : (
            <div
              className={`mt-8 text-center md:mt-16 flex gap-4 ${
                currentStep == 1 ? "" : "justify-center"
              }`}
            >
              {currentStep > 0 && (
                <Button
                  className="min-w-[100px] px-6 py-3 font-semibold !text-white-A700 rounded-md bg-teal-300 focus:opacity-80 hover:opacity-80"
                  onClick={handlePreviousStep}
                >
                  Previous
                </Button>
              )}
              {currentStep <= steps.length - 1 && (
                <Button
                  className="min-w-[100px] px-6 py-3 font-semibold !text-white-A700 rounded-md bg-teal-300 focus:opacity-80 hover:opacity-80"
                  onClick={handleNextStep}
                >
                  {currentStep < steps.length - 1 ? "Next" : "Finish"}
                </Button>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
}
