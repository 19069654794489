import {
  CodeBracketSquareIcon,
  InboxStackIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Spinner } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MultipleSelectInput from "components/MultiSelectInput";
import { toast } from "react-toastify";
import Badge from "components/Badge";
import { getAllApps } from "api/apps";
import {
  getAllInboxPlacementTests,
  getInboxPlacementTest,
} from "api/inboxPlacementTest";
import { formatTimestamp } from "utils/utils";
import { APP_NAMES } from "config/constants";
import { default as ModalProvider } from "react-modal";
import { Text } from "components";
import Popup from "components/Popup";

function InboxTest({ userObj, item }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const loadTest = async () => {
    setLoading(true);
    try {
      const dt = await getInboxPlacementTest(
        userObj.token,
        userObj.organizationId,
        item.uuid,
        {
          account_id: item.account_id,
        }
      );
      setResult(dt.data);
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "Error fetching test");
    }
    setLoading(false);
  };
  const Status = ({ label, value, circle = false }) => {
    const color = "teal";
    return (
      <li className="flex flex-col shadow-sm rounded-md border border-gray-200 p-3 min-h-[100px]">
        <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
          {label}
        </p>
        {circle ? (
          <p
            className={`text-xs font-semibold border-${color}-300 text-${color}-500 bg-${color}-50 border  rounded-full inline-flex items-center px-2.5 py-1`}
          >
            {value}
          </p>
        ) : (
          <p className="text-base font-bold text-gray-900">{value}</p>
        )}
      </li>
    );
  };

  useEffect(() => {
    if (item.uuid) {
      loadTest();
    }
  }, [item]);

  const EmailStatus = ({ item }) => {
    const color = "teal";
    return (
      <li className="flex items-center justify-between p-5 border border-gray-300 shadow-sm rounded-md">
        <div>
          <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
            test
          </p>
          <p className="text-base font-bold text-gray-900">
            {/* {formatTimestamp(item.)} */}
          </p>
          <p className="text-sm font-medium text-gray-700">
            From {item.sender_email_account_address}
          </p>
        </div>
        <p
          className={`text-xs font-semibold border-${color}-300 text-${color}-500 bg-${color}-50 border  rounded-full inline-flex items-center px-2.5 py-1`}
        >
          {item.folder}
        </p>
      </li>
    );
  };
  return (
    <div>
      <div className="grid grid-cols-4 gap-x-3 mb-3">
        <Status label="Status" value={item.status} />
        <Status label="Started At" value={formatTimestamp(item.created)} />
        {result && (
          <>
            <Status
              label="Completed At"
              value={formatTimestamp(result.completed_at)}
            />
            <Status label="Test Score" value={result.overall_score} />
          </>
        )}
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {result && (
            <>
              <div className="grid grid-cols-2 gap-2">
                {result.inbox_placement_test_emails.map((item, i) => (
                  <EmailStatus key={i} item={item} />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default function InboxPlacementTests() {
  const navigate = useNavigate();
  const { userObj } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [egApp, setEGApp] = useState([]);
  const [selectedApp, setSelectedApp] = useState([]);
  const [testModal, setTestModal] = useState(null);
  const loadEmailGuardApps = async () => {
    setLoading(true);
    try {
      const dt = await getAllApps(userObj.token, userObj.organizationId, {
        app: APP_NAMES.EMAIL_GUARD,
      });
      const apps = dt[APP_NAMES.EMAIL_GUARD] || [];
      if (apps.length) {
        const items = apps.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setEGApp(items);
        setSelectedApp([items[0]]);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching apps");
    }
    setLoading(false);
  };

  const loadTests = async () => {
    setLoading(true);
    try {
      const dt = await getAllInboxPlacementTests(
        userObj.token,
        userObj.organizationId,
        {
          account_id: selectedApp[0].value,
        }
      );
      setResults(dt);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching tests");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedApp.length !== 0) {
      loadTests();
      return;
    }
  }, [selectedApp]);
  useEffect(() => {
    loadEmailGuardApps();
  }, [userObj]);

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 h-full">
          <div className="flex justify-between items-center mb-5">
            <p className="text-2xl font-bold text-gray-900">
              Inbox Placement Test
            </p>
            <div className="flex gap-x-2">
              {/* <MultipleSelectInput
                label=""
                options={egApp}
                placeholder="Email Guard Account"
                selected={selectedApp}
                setSelected={setSelectedApp}
                multiselect={false}
                search={false}
              /> */}
              {/* FIXME: Show domain here */}
              {/* <button
                onClick={() => {
                  navigate("/");
                }}
                className="flex items-center gap-2 text-white-A700 bg-teal-300 hover:cursor-pointer p-2 rounded-lg font-medium text-xs"
              >
                <InboxStackIcon className="w-5 h-5" />
                New Inbox Placement Test
              </button> */}
            </div>
          </div>
          {results && (
            <div className="flex flex-col mt-2 h-full">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="h-[75vh] overflow-y-auto border border-gray-200 rounded-xl">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-200">
                        <tr>
                          <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                            Name
                          </th>
                          <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                            Email
                          </th>
                          <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                            Status
                          </th>
                          <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                            Overall Score
                          </th>
                          <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                            Created At
                          </th>
                          <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-white-A700 divide-y divide-gray-200">
                        {results.map((item, index) => (
                          <tr
                            key={index}
                            className="hover:bg-gray-50 transition-colors"
                          >
                            <td className="px-4 py-4 text-sm text-gray-900">
                              {item.name}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-900">
                              {item.email}
                            </td>
                            <td className="px-4 py-4">
                              <Badge value={item.status} />
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-900">
                              {item.score === null ? "-" : item.score}
                            </td>
                            <td className="px-4 py-4 text-sm text-gray-900">
                              {formatTimestamp(item.created)}
                            </td>
                            {/* <td className="px-4 py-4 text-sm text-gray-900">
                              {formatTimestamp(item.completed_at)}
                            </td> */}
                            <td className="px-4 py-4 flex">
                              {item.status === "Completed" && (
                                <div
                                  className="p-1 px-2 flex gap-x-2 cursor-pointer hover:bg-gray-200 bg-gray-100 rounded-md border"
                                  onClick={() => setTestModal(item)}
                                >
                                  <CodeBracketSquareIcon className="w-5 h-5 text-gray-500" />
                                  <button className="text-sm text-gray-700">
                                    Results
                                  </button>
                                </div>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <Popup
                isOpen={testModal === null ? false : true}
                onClose={() => setTestModal(null)}
                title={`Inbox Test Result (${testModal?.name})`}
              >
                <InboxTest item={testModal} userObj={userObj} />
              </Popup>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
