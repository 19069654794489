import axios from "axios";
import { BACKEND_URL } from "../config/config";
import { API_VERSION } from "config/prices";

const getTeam = async (token, orgId) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/${API_VERSION}/team?token=${token}&organizationId=${orgId}`
  );
  return response.data;
};

const addTeam = async (token, orgId, data) => {
  const response = await axios.post(
    `${BACKEND_URL}/api/${API_VERSION}/team?token=${token}&organizationId=${orgId}`,
    data
  );
  return response.data;
};

const removeTeam = async (token, orgId,id) => {
  const response = await axios.delete(
    `${BACKEND_URL}/api/${API_VERSION}/team/${id}?token=${token}&organizationId=${orgId}`
  );
  return response.data;
};

export { getTeam, addTeam, removeTeam };
