import axios from "axios";
import { BACKEND_URL } from "../config/config";
import { API_VERSION } from "config/prices";

const getDomains = async (token, organizationId, data) => {
  const response = await axios.post(
    `${BACKEND_URL}/api/${API_VERSION}/domain?token=${token}&organizationId=${organizationId}`,
    data
  );
  return response.data;
};

const getRegisteredDomains = async (token, organizationId, data) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/${API_VERSION}/domain/registered?token=${token}&organizationId=${organizationId}`,
    data
  );
  return response.data;
};

const getAvailableDomains = async (token, organizationId, data) => {
  const response = await axios.post(
    `${BACKEND_URL}/api/${API_VERSION}/domain/available?token=${token}&organizationId=${organizationId}`,
    data
  );
  return response.data;
};

export { getDomains, getAvailableDomains, getRegisteredDomains };
