import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Cog8ToothIcon,
  CreditCardIcon,
  GlobeAltIcon,
  InboxStackIcon,
  PlusIcon,
} from "@heroicons/react/16/solid";
import { clearUser, setOrganization } from "store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { createOrganization, getOrganizations } from "api/organization";
import {
  BellIcon,
  BuildingStorefrontIcon,
  ChatBubbleLeftEllipsisIcon,
  CheckCircleIcon,
  InboxIcon,
  InformationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Text } from "components";
import { getNotifications, markNotificationResolved } from "api/notification";
import { default as ModalProvider } from "react-modal";
import { Spinner } from "components/Spinner";
import { toast } from "react-toastify";
import { MAX_ORGANIZATIONS } from "config/constants";
import { Crown } from "lucide-react";

const LINKS = {
  primary: [
    { href: "/", label: "Domains", icon: GlobeAltIcon },
    { href: "/mailboxes", label: "Mailboxes", icon: InboxStackIcon },
    { href: "/marketplace", label: "Marketplace", icon: PlusIcon },
    { href: "/purchase-domains", label: "Purchase Domains", icon: Crown },
    { href: "/guide", label: "Guide", icon: InformationCircleIcon },
    {
      href: "/inbox-placement-tests",
      label: "Inbox Placement Test",
      icon: InboxIcon,
    },
    { href: "/tools", label: "Tools", icon: BuildingStorefrontIcon },
  ],
  secondary: [
    {
      href: "/expert-help",
      label: "Expert Help",
      icon: ChatBubbleLeftEllipsisIcon,
    },
    { href: "/settings", label: "Settings", icon: Cog8ToothIcon },
    { href: "/billing", label: "Billing", icon: CreditCardIcon },
  ],
};

function NotificationsModal({
  isShowModal,
  onModalClose,
  notifications,
  userObj,
  handleReload,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const handleNotificationResolved = async (item) => {
    setLoading(true);
    try {
      const response = await markNotificationResolved(
        userObj.token,
        userObj.organizationId,
        item.id
      );
      handleReload();
    } catch (error) {
      console.error("Error fetching mailboxes:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isShowModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayclassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-[80vw] p-8 bg-white-A700 rounded-lg">
        <div className="flex items-center justify-between w-full mb-2">
          <Text size="lg" as="p" className="tracking-[0.18px]">
            Notifications (
            <span className="text-teal-300">{notifications?.totalRecords}</span>
            )
          </Text>
          <XMarkIcon
            className="h-10 w-10 cursor-pointer"
            onClick={() => onModalClose(false)}
          />
        </div>
        {/* Domain Input Fields */}
        {loading ? (
          <Spinner />
        ) : (
          <div className="flex flex-col w-full max-h-[80%] overflow-y-scroll">
            {notifications?.data?.length > 0 ? (
              <div className="space-y-2 flex flex-col max-h-[80vh] overflow-y-scroll">
                {notifications.data.map((item, index) => (
                  <div key={index} className="flex items-center">
                    <CheckCircleIcon
                      className="mr-2 w-6 h-6 cursor-pointer text-gray-500 hover:text-teal-300"
                      onClick={() => handleNotificationResolved(item)}
                    />
                    <p className="text-sm font-medium text-gray-900 bg-gray-100 rounded-md p-2 w-full min-h-[4vh]">
                      {item.Message}
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-500 text-sm text-center">
                No notifications
              </p>
            )}
          </div>
        )}
      </div>
    </ModalProvider>
  );
}

function CreateOrgnizationModal({
  isShowModal,
  onModalClose,
  userObj,
  handleSelectItem,
  ...props
}) {
  const [loading, setLoading] = useState(false);
  const [org, setOrg] = useState("");
  const handleNewOrganization = async () => {
    if (!org) {
      return toast.error("Kindly add organization name");
    }
    setLoading(true);
    try {
      const data = await createOrganization(
        userObj.token,
        userObj.organizationId,
        org
      );
      handleSelectItem({ id: data.ID, name: data.Name });
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error creating organizations"
      );
    }
    setLoading(false);
  };

  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isShowModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayclassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-[50%] p-8 bg-white-A700 rounded-lg">
        <div className="flex items-center justify-between w-full mb-2">
          <Text size="lg" as="p" className="tracking-[0.18px]">
            Create New Organization
          </Text>
          <XMarkIcon
            className="h-10 w-10 cursor-pointer"
            onClick={() => onModalClose(false)}
          />
        </div>
        <div className="px-2 w-full flex flex-col space-y-2">
          <div>
            <label
              htmlFor="organization"
              className="text-sm font-bold text-gray-900"
            >
              Organization
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="organization"
                onChange={(e) => setOrg(e.target.value)}
                value={org}
                placeholder="Organization Name"
                className="border-[1px] block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300"
              />
            </div>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <button
              className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-teal-300 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-300 hover:bg-teal-600 text-white-A700"
              onClick={handleNewOrganization}
            >
              Create
            </button>
          )}
        </div>
      </div>
    </ModalProvider>
  );
}

export default function Layout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current route
  const userObj = useSelector((state) => state.user.userObj);
  const [orgLoading, setOrgLoading] = useState(false);
  const [notiLoading, setNotiLoading] = useState(false);
  const [orgModal, setOrgModal] = useState(false);
  const handleLogout = () => {
    dispatch(clearUser());
    navigate("/sign-in");
  };
  // #4ecda5

  const getNavLinkClass = (path) => {
    return location.pathname === path
      ? "flex items-center px-4 py-2.5 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg transition-all duration-200 group"
      : "flex items-center px-4 py-2.5 text-sm font-medium text-gray-900 rounded-lg hover:bg-gray-200 transition-all duration-200 group";
  };

  const [organizations, setOrganizations] = useState([]);
  const [profileDropdown, setProfileDropdown] = useState(false);

  const [orgDropdown, setOrgDropdown] = useState(false);

  const [selectedOrg, setSelectedOrg] = useState(userObj.organizationName);
  const [notifications, setNotifications] = useState(null);
  const [notificationsModal, setNotificationsModal] = useState(false);
  const toggleOrganization = () => {
    setOrgDropdown(!orgDropdown);
  };

  const handleSelectItem = (item) => {
    if (item.Name === selectedOrg) return;
    setSelectedOrg(item.name);
    setOrgDropdown(false);
    dispatch(
      setOrganization({ organizationName: item.name, organizationId: item.id })
    );
    window.location.reload();
  };

  const loadOrganizations = async () => {
    setOrgModal(false);
    setOrgLoading(true);
    try {
      const response = await getOrganizations(
        userObj.token,
        userObj.organizationId
      ); // Pass the body to POST request

      setOrganizations(response);
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error fetching organizations"
      );
    }
    setOrgLoading(false);
  };

  const loadNotifications = async () => {
    setNotiLoading(true);
    try {
      const response = await getNotifications(
        userObj.token,
        userObj.organizationId
      );
      setNotifications(response);
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error fetching notifications"
      );
    }
    setNotiLoading(false);
  };
  useEffect(() => {
    loadOrganizations();
    loadNotifications();
  }, [userObj]);

  const toggleProfileDropdown = () => {
    setProfileDropdown(!profileDropdown);
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <div className="flex flex-col flex-1 min-h-screen">
      <header className="bg-white-A700 border-b border-gray-200">
        <div className="px-4 mx-auto">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center lg:hidden">
              <button
                type="button"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 text-gray-400 bg-white-A700rounded-lg hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-300"
              >
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div>

            <div className="flex mr-auto lg:ml-0">
              <div className="flex items-center flex-shrink-0">
                <img
                  className="block w-auto h-12 sm:hidden md:hidden"
                  src="/logo.png"
                  alt="Scaledmail"
                />
              </div>
            </div>
            {orgLoading ? (
              <div className="mr-5">
                <Spinner size={5} />
              </div>
            ) : (
              <div className="mr-5 rounded-md flex items-center justify-center gap-3">
                <button
                  id="dropdownNavbarLink"
                  onClick={toggleOrganization}
                  className="text-gray-700 hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 font-medium flex items-center justify-between w-full md:w-auto"
                >
                  <p className="min-w-[130px]">{selectedOrg}</p>
                  <svg
                    className="ml-2 -mr-0.5 h-4 w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                    ></path>
                  </svg>
                </button>
                {orgDropdown && (
                  <div
                    id="dropdownNavbar"
                    className="absolute top-14 bg-white-A700 text-base z-10 list-none divide-y divide-gray-100 rounded shadow min-w-44 px-2"
                  >
                    <ul
                      className="py-1 space-y-1"
                      aria-labelledby="dropdownNavbarLink"
                    >
                      {organizations.map((item) => (
                        <li key={item.id}>
                          <button
                            onClick={() => handleSelectItem(item)}
                            className={`w-full text-sm  text-gray-700 p-2 rounded-md ${
                              item.name === selectedOrg
                                ? "bg-gray-100 cursor-default"
                                : "hover:bg-gray-100 cursor-pointer"
                            }`}
                          >
                            {item.name}
                          </button>
                        </li>
                      ))}
                      <li className="my-1 border-b border-gray-200" />
                      <li
                        className="rounded-md flex items-center  p-2 hover:bg-gray-200 cursor-pointer"
                        onClick={() => {
                          if (userObj.role !== "Admin") {
                            toast.error("Only Admin Can Create Organization");
                            return;
                          }
                          if (organizations.length >= MAX_ORGANIZATIONS) {
                            return toast.error(
                              `Maximum ${MAX_ORGANIZATIONS} organizations allowed`
                            );
                          } else {
                            setOrgModal(true);
                          }
                        }}
                      >
                        <PlusIcon className="h-6 text-gray-500" />
                        <p className="text-sm text-gray-500">
                          Add New Organization
                        </p>
                      </li>
                    </ul>
                  </div>
                )}
                {!notiLoading && (
                  <div className="relative">
                    <button
                      type="button"
                      onClick={() => setNotificationsModal(!notificationsModal)}
                      className="p-1 text-gray-700 transition-all duration-200 bg-white-A700rounded-full hover:text-gray-900 focus:outline-none hover:bg-gray-100"
                    >
                      <BellIcon className="w-6 h-6" />
                    </button>
                    {notifications?.data?.length > 0 && (
                      <span className="inline-flex items-center px-1.5 absolute -top-px -right-1 py-0.5 rounded-full text-xs font-semibold bg-teal-300 text-white">
                        {notifications?.data?.length || 0}
                      </span>
                    )}
                  </div>
                )}
                <NotificationsModal
                  isShowModal={notificationsModal}
                  onModalClose={setNotificationsModal}
                  notifications={notifications}
                  userObj={userObj}
                  handleReload={loadNotifications}
                />
                <CreateOrgnizationModal
                  isShowModal={orgModal}
                  onModalClose={() => setOrgModal(false)}
                  userObj={userObj}
                  handleSelectItem={handleSelectItem}
                />
              </div>
            )}
            <div className="relative sm:hidden">
              <button
                type="button"
                onClick={toggleProfileDropdown}
                className="flex items-center max-w-xs rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-300"
              >
                <div className="flex items-center justify-center w-8 h-8 bg-gray-300 rounded-full text-sm font-medium text-gray-900">
                  {userObj.name
                    .split(" ")
                    .filter(Boolean) // removes empty strings
                    .map((word, index) =>
                      index < 2 ? word[0].toUpperCase() : ""
                    )
                    .join("")}
                </div>

                <span className="hidden ml-2 text-sm font-medium text-gray-900">
                  {userObj.name}
                </span>
              </button>

              {profileDropdown && (
                <div className="absolute right-0 mt-2 bg-white-A700 text-base z-10 list-none rounded shadow w-44">
                  <ul className="py-1">
                    <li>
                      <button
                        onClick={() => navigate("/settings")}
                        className="text-sm hover:bg-gray-100 text-gray-700 block w-full text-left px-4 py-2"
                      >
                        Profile
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={handleLogout}
                        className="text-sm hover:bg-gray-100 text-gray-700 block w-full text-left px-4 py-2"
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      <div className="flex flex-1">
        <div className="border-r border-gray-200 md:flex md:w-30 md:flex-col">
          {/* Large screen */}
          <div className="flex flex-col overflow-y-auto sm:hidden px-4 md:px-1 min-h-full justify-between">
            <div className="space-y-4 p-2">
              <nav className="flex-1 space-y-1">
                {LINKS.primary.map((link) => (
                  <a
                    href={link.href}
                    className={getNavLinkClass(link.href)}
                    key={link.href}
                  >
                    <link.icon className="flex-shrink-0 w-5 h-5 mr-4" />
                    {link.label}
                  </a>
                ))}
              </nav>
            </div>

            <div className="py-4 border-t-gray-200 border-t-2">
              <nav className="flex-1 space-y-1">
                {LINKS.secondary.map((link) => (
                  <a
                    href={link.href}
                    className={getNavLinkClass(link.href)}
                    key={link.href}
                  >
                    <link.icon className="flex-shrink-0 w-5 h-5 mr-4" />
                    {link.label}
                  </a>
                ))}
              </nav>
            </div>
          </div>

          {/* Mobile Screen */}
          <div
            className={`${
              isMenuOpen
                ? "flex flex-col overflow-y-auto fixed top-0 left-0 w-screen h-full bg-gray-50 z-50"
                : "hidden"
            }`}
          >
            <div className="hidden sm:flex items-center bg-white-A700 border-b-2">
              <img className="h-16" src="logo.png" alt="Scaledmail" />
              <XMarkIcon
                onClick={() => setIsMenuOpen(false)}
                className="absolute top-4 right-4 p-2 text-gray-600 rounded-full sm:bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-400 w-10 h-10"
                aria-label="Close menu"
              />
            </div>
            <div className="flex flex-col justify-between px-4 md:px-0">
              <div className="space-y-4 p-2">
                <nav className="flex-1 space-y-1">
                  {LINKS.primary.map((link) => (
                    <a
                      href={link.href}
                      className={getNavLinkClass(link.href)}
                      key={link.href}
                    >
                      <link.icon className="flex-shrink-0 w-5 h-5 mr-4" />
                      {link.label}
                    </a>
                  ))}
                  <div className="hidden sm:block">
                    <a
                      href="/settings"
                      className={getNavLinkClass("/settings")}
                    >
                      <Cog8ToothIcon className="flex-shrink-0 w-5 h-5 mr-4" />
                      Settings
                    </a>
                    <a href="/billing" className={getNavLinkClass("/billing")}>
                      <CreditCardIcon className="flex-shrink-0 w-5 h-5 mr-4" />
                      Orders
                    </a>
                  </div>
                </nav>
              </div>

              <div className="py-4 border-t-gray-200 border-t-2">
                <nav className="flex-1 space-y-1 sm:hidden">
                  {LINKS.secondary.map((link) => (
                    <a
                      href={link.href}
                      className={getNavLinkClass(link.href)}
                      key={link.href}
                    >
                      <link.icon className="flex-shrink-0 w-5 h-5 mr-4" />
                      {link.label}
                    </a>
                  ))}
                </nav>
                <nav className="flex-1 space-y-1 hidden sm:block border-t-gray-200">
                  <ul className="py-1">
                    <li>
                      <button
                        onClick={() => navigate("/settings")}
                        className="text-sm hover:bg-gray-100 text-gray-700 block w-full text-left px-4 py-2"
                      >
                        Profile
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={handleLogout}
                        className="text-sm hover:bg-gray-100 text-gray-700 block w-full text-left px-4 py-2"
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

        {/* Page content goes here */}
        <main className="flex-1 p-4 bg-gray-50">
          <Outlet />
          {/* This is where the routed page content will be displayed */}
        </main>
      </div>
    </div>
  );
}
