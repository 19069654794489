import { MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/16/solid";
import { Button, Img, Input, Text } from "components";
import { Spinner } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { getMailboxes } from "api/mailbox";
import MultipleSelectInput from "components/MultiSelectInput";
import { getDomains } from "api/domain";
import { useLocation } from "react-router-dom";
import {
  ArrowPathIcon,
  ArrowPathRoundedSquareIcon,
  EllipsisHorizontalCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { APP_NAMES, DOMAIN_TYPE } from "config/constants";
import { toast } from "react-toastify";
import { default as ModalProvider } from "react-modal";
import { createInboxPlacementTest } from "api/inboxPlacementTest";
import Badge from "components/Badge";
import { formatTimestamp } from "utils/utils";
import { getAllApps } from "api/apps";

function InboxTest({
  user,
  egApp,
  showModal,
  setShowModal,
  mailboxes,
  refreshEG,
}) {
  const [loading, setLoading] = useState(false);
  const [inboxes, setInboxes] = useState({});

  const handleSubmit = async () => {
    if (selectedApp.length === 0) {
      return toast.error("Kindly select email guard account");
    }
    setLoading(true);
    try {
      const data = await createInboxPlacementTest(
        user.token,
        user.organizationId,
        {
          id: selectedApp[0].value,
          type: APP_NAMES.EMAIL_GUARD,
          inboxes: mailboxes.map((item) => item.id),
        }
      );
      setShowModal();
      toast.success("Inbox Placement Test Created");
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message || "Error creating test");
    }

    setLoading(false);
  };
  const [selectedApp, setSelectedApp] = useState([]);
  return (
    <ModalProvider
      appElement={document.getElementById("root")}
      isOpen={showModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayclassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center p-8 bg-white-A700 rounded-lg max-h-[80%] overflow-y-scroll">
        <div className="w-full mb-3 gap-2 flex">
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-between w-full mb-2">
              <Text size="lg" as="p" className="tracking-[0.18px]">
                Inbox Placement Test (Email Guard)
              </Text>
              <XMarkIcon
                className="h-12 w-12 cursor-pointer"
                onClick={() => setShowModal()}
              />
            </div>
            {egApp.length === 0 ? (
              <div className="flex gap-x-2 self-center">
                <Button
                  className="tracking-[1.25px] uppercase font-medium min-w-[150px] bg-teal-300 text-white-A700 rounded-md"
                  onClick={() => {
                    window.open("/settings", "_blank");
                  }}
                >
                  Connect Email Guard
                </Button>
                <ArrowPathIcon
                  className="w-6 cursor-pointer hover:text-teal-300"
                  onClick={refreshEG}
                />
              </div>
            ) : (
              <>
                <div className="max-h-[70vh] overflow-y-auto">
                  <MultipleSelectInput
                    options={egApp.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                    label="Select Email Guard Account"
                    placeholder="Email Guard Account"
                    selected={selectedApp}
                    setSelected={setSelectedApp}
                    multiselect={false}
                    search={false}
                  />
                  {inboxes.length > 0
                    ? inboxes.map((item, i) => (
                        <div className="flex flex-col border-2 border-gray-100 rounded-md p-4">
                          <div className="flex items-center justify-between gap-x-6">
                            <h2 className="text-xl font-medium text-gray-900">
                              {item.name}
                            </h2>
                            <Badge value={item.email} color="gray" />
                          </div>
                          <p className="text-sm text-gray-500 mt-1">
                            Created at: {formatTimestamp(item.created_at)}
                          </p>
                          <div className="flex items-center justify-between mt-1">
                            <p>Filter Phrase</p>
                            <p className="p-1 border-2 border-gray-100 rounded-md bg-gray-50">
                              {item.filter_phrase}
                            </p>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm font-medium text-gray-700 mb-1">
                              Recipents:
                            </p>
                            <div className="flex overflow-x-scroll gap-1">
                              {item.inbox_placement_test_emails.map((j, i) => (
                                <div
                                  key={i}
                                  className="p-1 border-2 rounded-md bg-gray-50"
                                >
                                  {j.email}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ))
                    : mailboxes.map((item, i) => (
                        <div
                          key={i}
                          className="p-2 border-2 border-gray-100 rounded-md bg-gray-50 mt-2"
                        >
                          {item.Email}
                        </div>
                      ))}
                </div>

                <div className="flex justify-between w-full gap-4 mt-4">
                  {loading ? (
                    <Spinner />
                  ) : (
                    <Button
                      className="tracking-[1.25px] uppercase font-medium min-w-[150px] bg-teal-300 text-white-A700 rounded-md"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </ModalProvider>
  );
}

export default function Mailboxes() {
  const { userObj } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMailboxes, setFilteredMailboxes] = useState(null);
  const [provider, setProvider] = useState([]);
  const location = useLocation();

  useEffect(() => {
    if (location.state?.selectedDomain) {
      setSelectedDomain([location.state.selectedDomain]);
    }
  }, [location.state]);

  const loadMailboxes = async () => {
    setLoading(true);
    try {
      const requestBody = {
        domain_ids: selectedDomain.map((domain) => domain.value),
        provider,
      };

      const response = await getMailboxes(
        userObj.token,
        userObj.organizationId,
        requestBody
      );
      setResults(response);
      setFilteredMailboxes(response.mailboxes);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching mailboxes");
    }
    setLoading(false);
  };
  const loadDomains = async () => {
    try {
      const dt = await getDomains(userObj.token, userObj.organizationId);
      const domainList = dt.domains.map((item) => ({
        value: item.id,
        label: item.domain,
      }));
      setDomains(domainList);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error fetching domains");
    }
  };
    // const [inboxTest, setInboxTest] = useState(false);
  // const [egApp, setEGApp] = useState([]);
  // const loadEmailGuardApps = async () => {
  //   try {
  //     const dt = await getAllApps(userObj.token, userObj.organizationId, {
  //       app: APP_NAMES.EMAIL_GUARD,
  //     });
  //     setEGApp(dt[APP_NAMES.EMAIL_GUARD] || []);
  //   } catch (error) {
  //     toast.error(error.response?.data?.message || "Error fetching apps");
  //   }
  // };
  useEffect(() => {
    loadDomains();
    // loadEmailGuardApps();
  }, []);

  useEffect(() => {
    loadMailboxes();
  }, [selectedDomain, provider]);
  const [selectedMailboxes, setSelectedMailboxes] = useState([]);
  const handleCheckboxChange = (item) => {
    setSelectedMailboxes((prevSelectedDomains) => {
      if (prevSelectedDomains.includes(item)) {
        return prevSelectedDomains.filter((domain) => domain !== item);
      } else {
        return [...prevSelectedDomains, item];
      }
    });
  };

  useEffect(() => {
    if (results?.mailboxes) {
      const filtered = results.mailboxes.filter(
        (item) =>
          `${item.first_name} ${item.last_name}`
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          item.Email?.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredMailboxes(filtered);
    }
  }, [searchTerm, results]);

  return (
    <div className="bg-white">
      {loading ? (
        <Spinner />
      ) : (
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {results && (
            <>
              <div className="flex justify-between items-center">
                <p className="text-xl font-bold text-gray-900">
                  Mailboxes ({results.mailboxes.length})
                </p>
                {/* <div className="flex items-center space-x-2">
                  {selectedMailboxes.length > 0 && (
                    <>
                      <div className="md:hidden sm:hidden space-x-2">
                        <button
                          onClick={() => {
                            if (selectedMailboxes.length > 2) {
                              return toast.error(
                                "Can do maximum 2 tests at a time"
                              );
                            }
                            setInboxTest(true);
                          }}
                          className="px-4 py-2 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg hover:bg-teal-600"
                        >
                          Inbox Placement Test
                        </button>
                      </div>
                      <EllipsisHorizontalCircleIcon className="h-7 w-7 !text-gray-500 hover:!text-teal-300 cursor-pointer rotate-90 lg:hidden" />
                    </>
                  )}
                  <CSVLink
                    data={csvData}
                    filename={"domains.csv"}
                    className="items-center px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white-A700 border border-gray-300 rounded-lg shadow-sm sm:inline-flex hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Export
                  </CSVLink>
                </div> */}
              </div>
              <div className="flex flex-col">
                <div className="mt-4 flex justify-between items-center gap-2 sm:flex-col">
                  <div className="w-[60%]">
                    <Input
                      placeholder="Search mailboxes"
                      className="w-full bg-white-A700"
                      prefix={<MagnifyingGlassIcon className="w-5 h-5 mr-5" />}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>

                  <div className="w-1/2 flex items-start justify-end">
                    <MultipleSelectInput
                      options={DOMAIN_TYPE}
                      label=""
                      placeholder="Select provider"
                      selected={provider}
                      setSelected={setProvider}
                      search={false}
                      className="w-full"
                      multiselect={true}
                    />
                  </div>
                </div>
                {domains && domains.length > 0 && (
                  <div className="w-1/2 mt-2">
                    <MultipleSelectInput
                      options={domains}
                      label=""
                      placeholder="Select a Domain"
                      selected={selectedDomain}
                      setSelected={setSelectedDomain}
                      multiselect={true}
                    />
                  </div>
                )}
              </div>

              <div className="flex flex-col mt-4 lg:mt-8 h-full">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="h-[75vh] overflow-y-auto border border-gray-200 rounded-xl">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-200">
                          <tr>
                            {/* <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest"></th> */}
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Provider
                            </th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Email
                            </th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Sender Name
                            </th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Password
                            </th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest"></th>
                          </tr>
                        </thead>
                        <tbody className="bg-white-A700 divide-y divide-gray-200">
                          {filteredMailboxes &&
                            filteredMailboxes.map((item, index) => (
                              <tr key={index} className="bg-white">
                                {/* <td className="px-4 py-4">
                                  <input
                                    className="rounded-md cursor-pointer"
                                    type="checkbox"
                                    checked={selectedMailboxes.includes(item)}
                                    onChange={() => handleCheckboxChange(item)}
                                  />
                                </td> */}
                                <td className="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                {item.order_type === "outlook" ? (
                                    <Img
                                      src="/images/outlook_icon.png"
                                      alt="Tick"
                                      className="h-8 w-8"
                                    />
                                  ) : item.order_type === "google" ? (
                                    <Img
                                      src="/images/google_icon.png"
                                      alt="Tick"
                                      className="h-8 w-8"
                                    />
                                  ) : (
                                    <QuestionMarkCircleIcon className="h-5 w-5" />
                                  )}
                                </td>
                                <td className="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  {item.email}
                                </td>

                                <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  {item.first_name} {item.last_name}
                                </td>
                                <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                  {item.mailbox_password}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              {/* {inboxTest && selectedMailboxes.length > 0 && (
                <InboxTest
                  user={userObj}
                  showModal={inboxTest}
                  setShowModal={() => {
                    setSelectedMailboxes([]);
                    setInboxTest(false);
                  }}
                  egApp={egApp}
                  mailboxes={selectedMailboxes}
                  refreshEG={loadEmailGuardApps}
                />
              )} */}
            </>
          )}
        </div>
      )}
    </div>
  );
}
