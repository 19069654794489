import { useEffect, useState } from "react";
import {
  Check,
  Globe,
  CheckCircle,
  Lightbulb,
  Sparkles,
  Minus,
  Plus,
  X,
  ArrowLeft,
  ArrowRight,
  Package,
} from "lucide-react";
import { RadioGroup, RadioGroupItem } from "components/ui/radio-group";
import { Elements } from "@stripe/react-stripe-js";
import { getStripe } from "utils/getStripe";
import { cn } from "lib/utils";
import CheckoutForm from "components/CheckoutForm";
import { useLocation, useNavigate } from "react-router-dom";

import { Button } from "components/ui/button";

import { WARMY_GOOGLE_NAME, WARMY_OUTLOOK_NAME } from "config/prices";
import ProgressSteps from "components/ProgressSteps";
import { useSelector } from "react-redux";
import DomainSelection from "components/DomainSelection";
import { CHECKOUT_TYPE, isTestUser } from "config/constants";

const QuantitySelector = ({
  quantity,
  onQuantityChange,
  min = 1,
  max = 100,
}) => {
  const handleDecrease = () => {
    if (quantity > min) {
      onQuantityChange(quantity - 1);
    }
  };

  const handleIncrease = () => {
    if (quantity < max) {
      onQuantityChange(quantity + 1);
    }
  };

  const handleChange = (e) => {
    const val = parseInt(e.target.value);
    if (!isNaN(val) && val >= min && val <= max) {
      onQuantityChange(val);
    }
  };

  return (
    <div className="flex items-center">
      <button
        type="button"
        onClick={handleDecrease}
        disabled={quantity <= min}
        className={cn(
          "w-10 h-10 flex items-center justify-center rounded-l-md border transition-all duration-200",
          quantity <= min
            ? "bg-gray-100 text-gray-400 cursor-not-allowed"
            : "bg-white-A700text-gray-700 hover:bg-gray-50 active:bg-gray-100"
        )}
        aria-label="Decrease quantity"
      >
        <Minus className="w-4 h-4" />
      </button>

      <input
        type="text"
        value={quantity}
        onChange={handleChange}
        className="w-14 h-10 text-center border-t border-b focus:outline-none focus:ring-0 text-gray-900"
        aria-label="Quantity"
      />

      <button
        type="button"
        onClick={handleIncrease}
        disabled={quantity >= max}
        className={cn(
          "w-10 h-10 flex items-center justify-center rounded-r-md border transition-all duration-200",
          quantity >= max
            ? "bg-gray-100 text-gray-400 cursor-not-allowed"
            : "bg-white-A700text-gray-700 hover:bg-gray-50 active:bg-gray-100"
        )}
        aria-label="Increase quantity"
      >
        <Plus className="w-4 h-4" />
      </button>
    </div>
  );
};

const AddonCard = ({ addon, isSelected, onToggle }) => {
  return (
    <div
      className={cn(
        "glass-card hover-card p-5 cursor-pointer transition-all duration-300 overflow-hidden",
        isSelected && "active-card"
      )}
      onClick={() => onToggle(addon)}
    >
      <div className="flex  justify-between">
        <div className="flex-1">
          <img
            className="object-cover w-7 h- mb-2 rounded-lg"
            src={addon.image}
            alt={addon.name}
          />
          {/* Addon name and description */}
          <h3 className="font-semibold text-gray-900">{addon.name}</h3>
          <p className="text-sm text-gray-600 mt-1">/{addon.type}</p>
        </div>

        {/* Price and action button */}
        <div className="flex flex-col items-end ml-4">
          <div className="text-lg font-semibold text-gray-900">
            ${addon.price.toFixed(2)}
          </div>
          <div
            className={cn(
              "mt-3 w-8 h-8 flex items-center justify-center rounded-full border transition-all duration-300",
              isSelected
                ? "bg-brand text-white-A700 border-brand"
                : "bg-white-A700text-gray-500 border-gray-200 hover:border-brand/50 hover:text-brand"
            )}
          >
            {isSelected ? (
              <Check className="w-4 h-4" />
            ) : (
              <Plus className="w-4 h-4" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const PricingSummary = ({ basePrice, quantity, amount, totalDomainPrice }) => {
  const formatPrice = (price) => {
    return `$${price.toFixed(2)}`;
  };
  return (
    <div className="glass-card p-6 animate-fade-in">
      <h2 className="text-xl font-bold text-gray-900 mb-6">Total Due Today</h2>

      <div className="space-y-4">
        <div className="flex justify-between text-sm">
          <span className="text-gray-600">Base Price</span>
          <span className="font-medium text-gray-900">
            {formatPrice(basePrice)} × {quantity}
          </span>
        </div>

        <div className="flex justify-between text-sm">
          <span className="text-gray-600">Subtotal</span>
          <span className="font-medium text-gray-900">
            {formatPrice(basePrice * quantity)}
          </span>
        </div>

        {amount.addonDetails.length > 0 && (
          <div className="border-t border-gray-100 pt-4 space-y-3">
            <div className="text-sm font-medium text-gray-700">Add-ons</div>
            {amount.addonDetails.map((addon) => (
              <div
                key={addon.price_id}
                className="flex justify-between text-sm items-center"
              >
                <div className="flex flex-col">
                  <span className="text-gray-600">{addon.name}</span>
                  <span className="text-gray-600">
                    ({addon.total_qty} x ${addon.price})
                  </span>
                </div>
                <span className="font-medium text-gray-900">
                  {formatPrice(addon.total_cost)}
                </span>
              </div>
            ))}
          </div>
        )}
        {totalDomainPrice > 0 && (
          <div className="border-t border-gray-100 pt-4 space-y-3">
            <div className="text-sm font-medium text-gray-700">Domains</div>
            <div className="flex justify-between text-sm items-center">
              <span className="text-gray-600">Total</span>

              <span className="font-medium text-gray-900">
                {totalDomainPrice}
              </span>
            </div>
          </div>
        )}

        <div className="border-t border-gray-100 pt-4">
          <div className="flex justify-between">
            <span className="font-bold text-gray-900">Total</span>
            <span className="text-2xl font-bold text-gray-900">
              {formatPrice(amount.totalDueToday)}
            </span>
          </div>
          {amount.oneTimeTotal > 0 && (
            <div className="mt-6">
              <p className="text-sm text-gray-500 mt-4 text-center">
                ${amount.monthlyRecurring.toFixed(2)} / month after the first
                month
              </p>
              <p className="text-xs text-gray-500 mt-2 text-center">
                One-time addons included in today's charge.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SelectedAddonsList = ({ selectedAddons, onRemoveAddon }) => {
  if (selectedAddons.length === 0) {
    return (
      <div className="text-sm text-gray-500 italic p-3 text-center">
        No addons selected
      </div>
    );
  }

  return (
    <div className="space-y-2 animate-fade-in">
      {selectedAddons.map((addon) => (
        <div
          key={addon.price_id}
          className="flex items-center justify-between p-3 bg-white-A700rounded-lg border border-gray-100 shadow-subtle overflow-hidden animate-slide-in"
        >
          <div className="flex items-center">
            {/* Color dots if available */}

            <img
              className="object-cover w-7 h- mr-2 rounded-lg"
              src={addon.image}
              alt={addon.name}
            />
            <div>
              <p className="font-medium text-sm text-gray-900">{addon.name}</p>
              <p className="text-xs text-gray-500">${addon.price.toFixed(2)}</p>
            </div>
          </div>

          <button
            type="button"
            onClick={() => onRemoveAddon(addon.price_id)}
            className="w-6 h-6 flex items-center justify-center rounded-full text-gray-400 hover:text-gray-700 hover:bg-gray-100 transition-colors"
            aria-label={`Remove ${addon.name}`}
          >
            <X className="w-3.5 h-3.5" />
          </button>
        </div>
      ))}
    </div>
  );
};

const SubscriptionCard = ({
  title,
  frequency,
  features,
  domains,
  price,
  quantity,
  onQuantityChange,
}) => {
  return (
    <div className="glass-card p-6 animate-fade-in">
      <div className="space-y-6">
        <div>
          <h2 className="text-xl font-bold text-gray-900">{title}</h2>
          <p className="text-sm text-gray-500 mt-1">/{frequency}</p>
        </div>

        <div className="space-y-4">
          {features.map((feature, index) => (
            <div key={index} className="flex items-start">
              <div className="flex-shrink-0 w-5 h-5 rounded-full bg-green-100 flex items-center justify-center mt-0.5">
                <CheckCircle className="w-3 h-3 text-brand" />
              </div>
              <span className="ml-3 text-sm text-gray-700">{feature}</span>
            </div>
          ))}
        </div>

        <div className="flex items-center py-2 px-4 bg-gray-50 rounded-lg">
          <Globe className="w-5 h-5 text-gray-500" />
          <span className="ml-3 text-sm font-medium text-gray-700">
            {domains} Total Domains Required
          </span>
        </div>

        <div className="pt-4 border-t border-gray-100">
          <div className="flex items-center justify-between">
            <div className="text-sm font-medium text-gray-700">Quantity:</div>
            <QuantitySelector
              quantity={quantity}
              onQuantityChange={onQuantityChange}
            />
          </div>
        </div>

        <div className="pt-4 border-t border-gray-100">
          <div className="flex items-center justify-between">
            <div className="text-lg font-bold text-gray-900">
              ${(price * quantity).toFixed(2)}
            </div>
            {/* <button className="px-5 py-2.5 bg-brand text-white rounded-lg font-medium shadow-sm hover:bg-brand-dark transition-colors duration-200">
              Buy Domains
            </button> */}
          </div>
          <p className="text-xs text-gray-500 mt-2">
            {domains} domains required for this subscription.
          </p>
        </div>
      </div>
    </div>
  );
};

const Index = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const location = useLocation();
  const plan = location.state;
  const navigate = useNavigate();
  useEffect(() => {
    if (!plan) {
      navigate("/"); // Redirect to home page
    }
  }, [plan, navigate]);
  const { userObj } = useSelector((state) => state.user);
  const [quantity, setQuantity] = useState(1);
  const basePrice = plan.price;
  const reqDomains = plan.domains * quantity;
  const [availableAddons, setAvailableAddons] = useState(plan.addons);
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [totalDomainPrice, setTotalDomainPrice] = useState(0);
  const [selectedAddons, setSelectedAddons] = useState([]);
  // const [needDomains, setNeedDomains] = useState(
  //   isTestUser(userObj.email) ? null : "own"
  // );
  const [needDomains, setNeedDomains] = useState(null);
  const showDomainStep = needDomains === "scaledmail";
  const toggleAddon = (addon) => {
    const isSelected = selectedAddons.some(
      (item) => item.price_id === addon.price_id
    );

    if (isSelected) {
      setSelectedAddons(
        selectedAddons.filter((item) => item.price_id !== addon.price_id)
      );
    } else {
      setSelectedAddons([...selectedAddons, addon]);
    }
  };
  const removeAddon = (addonId) => {
    setSelectedAddons(
      selectedAddons.filter((item) => item.price_id !== addonId)
    );
  };
  const [amount, setAmount] = useState({
    monthlyRecurring: 0,
    oneTimeTotal: 0,
    totalDueToday: 0,
    addonDetails: [],
  });
  function calculateBillingBreakdown() {
    const google_mailboxes = plan.google?.mailbox || 0;
    const microsoft_domains = plan.microsoft?.domains || 0;
    let monthlyRecurring = plan.price * quantity;

    const monthlyOrSubscriptionAddons = selectedAddons.filter(
      (addon) => addon.type !== "one-time"
    );

    const addonDetails = [];

    monthlyOrSubscriptionAddons.forEach((addon) => {
      let addonCost = 0;
      let total_qty = 0;
      if (addon.name === WARMY_OUTLOOK_NAME) {
        total_qty = quantity * microsoft_domains;
        addonCost = addon.price * total_qty;
      } else if (addon.name === WARMY_GOOGLE_NAME) {
        total_qty = quantity * google_mailboxes;
        addonCost = addon.price * total_qty;
      }

      monthlyRecurring += addonCost;

      addonDetails.push({
        ...addon,
        total_qty,
        total_cost: addonCost,
      });
    });

    const oneTimeAddons = selectedAddons.filter(
      (addon) => addon.type === "one-time"
    );

    let oneTimeTotal = 0;
    oneTimeAddons.forEach((addon) => {
      let addonCost = 0;
      let total_qty = 0;
      if (
        [
          "Instantly Setup (Per Package)",
          "Profile Picture Upload (Per Package)",
        ].includes(addon.name)
      ) {
        total_qty = (quantity * microsoft_domains) / 4;
        addonCost = addon.price * total_qty;
      }
      oneTimeTotal += addonCost;
      addonDetails.push({
        ...addon,
        total_qty,
        total_cost: addonCost,
      });
    });

    let totalDueToday = monthlyRecurring + oneTimeTotal;
    if (selectedDomains.length > 0) {
      totalDueToday += totalDomainPrice;
    }
    setAmount({
      monthlyRecurring,
      oneTimeTotal,
      totalDueToday,
      addonDetails,
    });
  }

  useEffect(() => {
    calculateBillingBreakdown();
  }, [quantity, selectedAddons, totalDomainPrice]);

  // const handleDomainOptionSelect = (option) => {
  //   setNeedDomains(option);
  //   // Show a toast notification based on the selected option
  //   if (option === "scaledmail") {
  //     toast({
  //       title: "ScaledMail Domains Selected",
  //       description:
  //         "You'll be guided through domain selection in the next step.",
  //     });
  //   } else {
  //     toast({
  //       title: "Using Own Domains",
  //       description: "You've chosen to use your own domains.",
  //     });
  //   }
  // };

  const renderDomainSelection = () => {
    return (
      <div className="mt-8 glass-card p-6 animate-fade-in">
        <h3 className="text-lg font-semibold text-gray-900 mb-4">
          Do you want to purchase domains?
        </h3>
        <RadioGroup
          value={needDomains || ""}
          onValueChange={setNeedDomains}
          className="space-y-3"
        >
          <div className="flex items-center space-x-2 p-3 rounded-lg border border-gray-200 hover:bg-gray-50 cursor-pointer">
            <RadioGroupItem value="scaledmail" id="scaledmail" />
            <label
              htmlFor="scaledmail"
              className="flex flex-1 items-center cursor-pointer"
            >
              <span className="text-sm font-medium ml-2">
                Yes, I want to buy domains from ScaledMail
              </span>
            </label>
          </div>
          <div className="flex items-center space-x-2 p-3 rounded-lg border border-gray-200 hover:bg-gray-50 cursor-pointer">
            <RadioGroupItem value="own" id="own" />
            <label
              htmlFor="own"
              className="flex flex-1 items-center cursor-pointer"
            >
              <span className="text-sm font-medium ml-2">
                No, I'll use my own domains
              </span>
            </label>
          </div>
        </RadioGroup>

        {needDomains === null && (
          <div className="mt-4 p-3 bg-amber-50 rounded-lg border border-amber-100">
            <div className="flex items-start text-sm text-amber-800">
              <Lightbulb className="w-4 h-4 mt-0.5 mr-2 text-amber-500" />
              <span>
                Please select whether you want to purchase domains before
                continuing.
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const getSteps = () => {
    const baseSteps = [
      {
        number: 1,
        title: "Confirm Package",
        description: "Confirm package quantity",
        icon: Package,
      },
    ];

    if (showDomainStep) {
      baseSteps.push({
        number: 2,
        title: "Select Domains",
        description: "Buy domains",
        icon: Globe,
      });
    }

    baseSteps.push({
      number: showDomainStep ? 3 : 2,
      title: "Finalize",
      description: "Complete your order",
      icon: CheckCircle,
    });

    return baseSteps;
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-background to-gray-50">
      <div className="container max-w-6xl px-4 sm:px-6">
        <ProgressSteps currentStep={currentStep} steps={getSteps()} />
        {currentStep === 1 && (
          <div className="max-w-5xl mx-auto bg-white-A700rounded-lg shadow-sm border border-gray-200 p-6">
            <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 gap-8">
              {/* Left Column: Subscription Details */}
              <div className="lg:col-span-2 space-y-8">
                <SubscriptionCard
                  title={`Subscribe to ${plan.name}`}
                  frequency="monthly"
                  features={plan.features}
                  domains={reqDomains}
                  price={basePrice}
                  quantity={quantity}
                  onQuantityChange={setQuantity}
                />

                {availableAddons.length > 0 && (
                  <div className="glass-card p-6">
                    <div className="flex items-center space-x-2 mb-6">
                      <Sparkles className="w-5 h-5 text-brand" />
                      <h2 className="text-xl font-bold text-gray-900">
                        Add-ons
                      </h2>
                    </div>

                    <div className="grid grid-cols-2 sm:grid-cols-1 gap-4">
                      {availableAddons.map((addon) => (
                        <AddonCard
                          key={addon.price_id}
                          addon={addon}
                          isSelected={selectedAddons.some(
                            (item) => item.price_id === addon.price_id
                          )}
                          onToggle={toggleAddon}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {/* Right Column: Summary */}
              <div className="space-y-8">
                {/* Pricing Summary */}
                <PricingSummary
                  amount={amount}
                  quantity={quantity}
                  basePrice={plan.price}
                  totalDomainPrice={totalDomainPrice}
                />

                {availableAddons.length > 0 && (
                  <div className="glass-card p-6">
                    <div className="flex items-center justify-between mb-4">
                      <div className="flex items-center space-x-2">
                        <Sparkles className="w-5 h-5 text-brand" />
                        <h3 className="font-semibold text-gray-900">
                          Selected Add-ons
                        </h3>
                      </div>
                      <span className="text-sm font-medium px-2.5 py-1 bg-gray-100 rounded-full">
                        {selectedAddons.length}
                      </span>
                    </div>

                    <SelectedAddonsList
                      selectedAddons={selectedAddons}
                      onRemoveAddon={removeAddon}
                    />

                    {selectedAddons.length === 0 && (
                      <div className="mt-4 flex items-center justify-center p-4 border border-dashed border-gray-200 rounded-lg bg-gray-50">
                        <div className="flex items-center text-sm text-gray-500">
                          <Lightbulb className="w-4 h-4 mr-2 text-amber-500" />
                          <span>
                            Select add-ons from the list to enhance your package
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            { renderDomainSelection()}
            {/* {isTestUser(userObj.email) && renderDomainSelection()} */}
            <div className="mt-12 flex justify-between">
              <Button
                variant="outline"
                className="text-gray-600 border-gray-300"
                onClick={() => navigate(-1)}
              >
                <ArrowLeft className="mr-2 h-4 w-4" />
                Back
              </Button>

              <Button
                onClick={() => setCurrentStep(Math.min(3, currentStep + 1))}
                disabled={currentStep === 3 || !needDomains}
                className="bg-emerald-500 hover:bg-emerald-600 text-white-A700"
              >
                {needDomains === null
                  ? ""
                  : needDomains === "own"
                  ? "Checkout"
                  : "Continue to Domains"}

                <ArrowRight className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </div>
        )}
        {showDomainStep && currentStep === 2 && (
          <DomainSelection
            handleBack={() => setCurrentStep(currentStep - 1)}
            handleNext={() => setCurrentStep(currentStep + 1)}
            totalDomains={reqDomains}
            selectedDomains={selectedDomains}
            setSelectedDomains={setSelectedDomains}
            totalDomainPrice={totalDomainPrice}
            userObj={userObj}
            setTotalDomainPrice={setTotalDomainPrice}
          />
        )}

        {(showDomainStep ? currentStep === 3 : currentStep === 2) && (
          <div className="max-w-5xl mx-auto bg-white-A700 rounded-lg shadow-sm border border-gray-200 p-6">
            <div className="w-full">
              <Elements stripe={getStripe()}>
                <CheckoutForm
                  body={{
                    type: CHECKOUT_TYPE.PACKAGE_SUBSCRIPTION,
                    returnUrl: `${window.location.origin}/order-success`,
                    data: {
                      priceId: plan.priceId,
                      addons: amount?.addonDetails || [],
                      qty: quantity,
                      domains:
                        selectedDomains.length > 0
                          ? {
                              selectedDomains,
                              amount: totalDomainPrice,
                            }
                          : null,
                    },
                  }}
                />
              </Elements>
            </div>
            <div className="mt-12 flex justify-between">
              <Button
                variant="outline"
                className="text-gray-600 border-gray-300"
                onClick={() => setCurrentStep(Math.max(1, currentStep - 1))}
                disabled={currentStep === 1}
              >
                <ArrowLeft className="mr-2 h-4 w-4" />
                Back
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
