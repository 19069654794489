
import React, { useState, useEffect, useRef } from 'react';
import { X } from 'lucide-react';

interface DomainInputProps {
  value: string;
  onChange: (value: string) => void;
  onSubmit?: () => void;
  placeholder?: string;
  autoFocus?: boolean;
  lightTheme?: boolean;
}

const DomainInput: React.FC<DomainInputProps> = ({
  value,
  onChange,
  onSubmit,
  placeholder = "Enter a domain name...",
  autoFocus = false,
  lightTheme = false
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onSubmit) {
      onSubmit();
    }
  };

  const handleClear = () => {
    onChange('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const inputClass = lightTheme 
    ? `w-full px-6 py-4 text-lg bg-white-A700 border-2 ${
        isFocused ? 'border-emerald-300' : 'border-gray-200'
      } rounded-sm focus:outline-none focus:ring-2 focus:ring-emerald-200 placeholder:text-gray-400
      transition-all duration-300 shadow-sm`
    : `domain-input transition-all duration-300 ${
        isFocused ? 'ring-2 ring-white/20 shadow-lg' : ''
      }`;

  return (
    <div className="relative w-full max-w-3xl mx-auto">
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder}
        className={inputClass}
        spellCheck="false"
        autoComplete="off"
      />
      {value && (
        <button
          type="button"
          onClick={handleClear}
          className={`absolute right-4 top-1/2 -translate-y-1/2 ${
            lightTheme
              ? 'text-gray-400 hover:text-gray-600 hover:bg-gray-100'
              : 'text-white/50 hover:text-white/90 hover:bg-white/10'
          } p-1.5 rounded-full transition-all duration-200`}
          aria-label="Clear input"
        >
          <X className="h-5 w-5" />
        </button>
      )}
    </div>
  );
};

export default DomainInput;
