import { getUser } from "api/auth";
import { useSelector } from "react-redux";
import { Spinner } from "components/Spinner";
import React, { useEffect, useState } from "react";
import { useRoutes, Navigate, useLocation } from "react-router-dom";

import NotFound from "pages/NotFound";

import Verify from "pages/Auth/Verify";
import SignIn from "pages/Auth/SignIn";
import SignUp from "pages/Auth/SignUp";
import ResetPassword from "pages/Auth/ResetPassword";
import ForgotPassword from "pages/Auth/ForgotPassword";

import MixedOrder from "pages/Purchase/MixedOrder";
import OutlookOrder from "pages/Purchase/OutlookOrder";
import GoogleOrder from "pages/Purchase/GoogleOrder";

import Layout from "pages/Layout";
import Billing from "pages/Billing";
import Mailboxes from "pages/Mailboxes";
import Domains from "pages/Domains";
import Marketplace from "pages/Marketplace";
import UpdateOrder from "pages/UpdateOrder";

import Settings from "pages/Settings";
import Success from "pages/Success";
import Guide from "pages/Guide";
import Subscribe from "pages/Purchase/Subscribe";
import OrderSuccess from "pages/Purchase/OrderSuccess";
import AccountSetup from "pages/Auth/AccountSetup";
import InboxPlacementTests from "pages/InboxPlacementTests";
import Tools from "pages/Tools";
import Beanstalk from "modals/Beanstalk";
import PurchaseDomains from "pages/PurchaseDomains";
import BuyDomains from "pages/BuyDomains";

const PrivateRoute = ({ element }) => {
  const { pathname } = useLocation();
  const { userObj } = useSelector((state) => state.user);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isCheckingToken, setIsCheckingToken] = useState(true);

  const checkTokenValidity = (token) => {
    if (!token) return false;

    const tokenPayload = JSON.parse(atob(token.split(".")[1]));
    const expiry = tokenPayload.exp * 1000; // Convert expiry to milliseconds
    return expiry > Date.now();
  };

  const verifyTokenWithBackend = async (userObj) => {
    try {
      const data = await getUser(userObj.token, userObj.organizationId);
      window.Atlas.call("identify", {
        userId: data.organizationId, // Use actual userId
        name: data.name, // Use actual user name
        email: data.email, // Use actual user email
        customFields: {
          product: "Scaledmail",
        },
      });
      return true; // Valid token
    } catch (error) {
      console.error("Error verifying token with backend:", error);
      return false; // Invalid token
    }
  };

  useEffect(() => {
    const checkToken = async () => {
      if (userObj && userObj.token) {
        const localValid = checkTokenValidity(userObj.token);
        if (!localValid || !userObj.role) {
          setIsTokenValid(false);
          setIsCheckingToken(false);
        } else {
          const backendValid = await verifyTokenWithBackend(userObj);
          setIsTokenValid(backendValid);
          setIsCheckingToken(false);
        }
      } else {
        setIsTokenValid(false);
        setIsCheckingToken(false);
      }
    };

    checkToken();
  }, [userObj]);

  if (isCheckingToken) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spinner />
      </div>
    );
  }

  // Redirect to sign-in if the user is not authenticated
  if (!userObj || !isTokenValid) {
    return <Navigate to="/sign-in" />;
  }

  return element;
};

const ProjectRoutes = () => {
  const { userObj } = useSelector((state) => state.user);
  const isAuthenticated = userObj && userObj.token; // Check if user is logged in

  const routes = [
    // Public routes without layout
    {
      path: "sign-in",
      element: <SignIn />,
    },
    {
      path: "sign-up",
      element: <SignUp />,
    },
    {
      path: "forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "reset-password",
      element: <ResetPassword />,
    },
    {
      path: "account-setup",
      element: <AccountSetup />,
    },
    {
      path: "verify",
      element: <Verify />,
    },
    {
      path: "/success",
      element: <Success />,
    },
    // Private routes with layout and authentication
    {
      path: "/",
      element: isAuthenticated ? <Layout /> : <SignIn />, // Only render Layout if authenticated
      children: [
        { path: "/", element: <PrivateRoute element={<Domains />} /> },
        {
          path: "/purchase-domains",
          element: <PrivateRoute element={<PurchaseDomains />} />,
        },
        { path: "/guide", element: <PrivateRoute element={<Guide />} /> },
        {
          path: "mailboxes",
          element: <PrivateRoute element={<Mailboxes />} />,
        },
        {
          path: "buy-domains",
          element: <PrivateRoute element={<BuyDomains />} />,
        },
        { path: "settings", element: <PrivateRoute element={<Settings />} /> },
        {
          path: "marketplace",
          element: <PrivateRoute element={<Marketplace />} />,
        },
        {
          path: "update-order/:id",
          element: <PrivateRoute element={<UpdateOrder />} />,
        },
        {
          path: "tools",
          element: <Tools />,
        },
        {
          path: "marketplace/purchase/google-order",
          element: <PrivateRoute element={<GoogleOrder />} />,
        },
        {
          path: "marketplace/purchase/outlook-order",
          element: <PrivateRoute element={<OutlookOrder />} />,
        },
        {
          path: "marketplace/purchase/mixed-order",
          element: <PrivateRoute element={<MixedOrder />} />,
        },
        {
          path: "subscribe",
          element: <PrivateRoute element={<Subscribe />} />,
        },
        {
          path: "inbox-placement-tests",
          element: <PrivateRoute element={<InboxPlacementTests />} />,
        },
        {
          path: "order-success/:uuid",
          element: <PrivateRoute element={<OrderSuccess />} />, //for only showing all data
        },
        {
          path: "billing",
          element: <PrivateRoute element={<Billing />} />, //for only showing all data
        },
        {
          path: "/expert-help",
          element: <PrivateRoute element={<Beanstalk />} />, //for only showing all data
        },
        {
          path: "billing/:orderId", // The '?' makes orderId optional
          element: <PrivateRoute element={<Billing />} />,
        },
      ],
    },

    // Fallback route (Not Found)
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  const element = useRoutes(routes);
  return element;
};

export default ProjectRoutes;
