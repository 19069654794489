import React, { useEffect, useRef } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const Popup = ({ isOpen, onClose, title, children, className, icon }) => {
  const popupRef = useRef(null); // Removed TypeScript generic type

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") onClose();
    };

    const handleOutsideClick = (e) => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscape);
      document.addEventListener("mousedown", handleOutsideClick);
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("mousedown", handleOutsideClick);
      document.body.style.overflow = "";
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-2 animate-fade-in">
      <div className="absolute inset-0 bg-black bg-opacity-80"></div>
      <div
        ref={popupRef}
        className={`relative w-full max-h-[90vh] mx-4 rounded-2xl shadow-2xl animate-scale-in overflow-hidden bg-white-A700 border border-indigo-100 ${className}`}
      >
        <div className="flex items-center justify-between p-1 sticky top-0 bg-white/95 z-10 border-b border-indigo-50">
          <div className="flex items-center gap-x-2">
            {icon && <img src={icon} alt="Scaledmail" className="w-8 h-8" />}
            <h2 className="text-xl font-medium text-gray-800">{title}</h2>
          </div>
          <button
            onClick={onClose}
            className="p-2 rounded-full hover:bg-indigo-50 transition-colors text-slate-500 hover:text-slate-900"
            aria-label="Close"
          >
            <XMarkIcon className="w-6 h-6" />
          </button>
        </div>
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

export default Popup;
