import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { signIn } from "api/auth";
import { forgotPassword } from "api/auth";

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (userCred) => {
    try {
      const data = await signIn(userCred);
      const token = data.token;
      return {
        token,
        name: data.name,
        email: userCred.email,
        organizationName: data.organization_name,
        organizationId: data.organization_id,
        role:data.role
      };
    } catch (error) {
      console.error("Error:", error);
      throw new Error(error.response?.data?.message || "Error occurred");
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    userObj: null,
  },
  reducers: {
    setUser: (state, action) => {
      const { email, name, token, organizationName, organizationId,role } = action.payload;
      state.userObj = { email, name, token, organizationName, organizationId,role };
    },
    setOrganization: (state, action) => {
      const { organizationName, organizationId } = action.payload;
      if (state.userObj) {
        state.userObj.organizationName = organizationName;
        state.userObj.organizationId = organizationId;
      }
    },
    clearUser: (state) => {
      state.userObj = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.userObj = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.userObj = action.payload;
      });
  },
});

export default userSlice.reducer;
export const { clearUser, setUser, setOrganization } = userSlice.actions;

