import axios from "axios";
import { BACKEND_URL } from "../config/config";
import { API_VERSION } from "config/prices";

const updateUser = async (token, organizationId, data) => {
  const response = await axios.put(
    `${BACKEND_URL}/api/${API_VERSION}/user?token=${token}&organizationId=${organizationId}`,
    data
  );

  return response.data;
};

const discountAvailable = async (token, orgId) => {
  const response = await axios.get(
    `${BACKEND_URL}/api/${API_VERSION}/available?token=${token}&organizationId=${orgId}`
  );
  return response.data;
};

export { discountAvailable, updateUser };
