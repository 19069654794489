function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
function checkPasswordStrength(password, name) {
  const violatedRules = {
    1: password.length >= 8,
    2: /[0-9!@#$%^&*]/.test(password),
    3: !password.toLowerCase().includes(name),
  };
  let isStrong = true;
  for (const r in violatedRules) {
    if (!violatedRules[r]) {
      isStrong = false;
      break;
    }
  }
  return { rules: violatedRules, isStrong };
}


const isValidURL = (url) => {
  // FIXME:
  return true;
};

const isValidDomain = (domain) => {
  const domainRegex = /^(?!www\.)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/;
  return domainRegex.test(domain.trim());
};

const validateDomains = (domains) => {
  const seenDomains = new Set();

  for (const { domain, redirect_to = null } of domains) {
    if (!domain || !domain.trim()) {
      return "Please fill out all domain fields.";
    }
    if (!isValidDomain(domain.trim())) {
      return `Invalid domain format: "${domain}".`;
    }
    // Check for duplicate domains
    if (seenDomains.has(domain.trim())) {
      return `Duplicate domain found: "${domain}".`;
    }
    seenDomains.add(domain.trim());
    if (redirect_to && redirect_to.trim()) {
      if (!isValidURL(redirect_to)) {
        return `Invalid redirect URL: "${redirect_to}".`;
      }
    }
  }

  return "";
};

const validateMailboxes = (domains, aliases, aliases_count) => {
  const domain_msg = validateDomains(domains);
  if (domain_msg) return domain_msg;
  const validDomains = new Set(domains.map(({ domain }) => domain.trim()));
  if (!aliases.length) return "Generate mailboxes first.";
  if (aliases.length !== aliases_count) {
    return `The total aliases generated (${aliases.length}) does not match the required count (${aliases_count}).`;
  }
  
  const seenEmails = new Set();
  for (const alias of aliases) {
    if (alias.alias.trim() === "") return "Alias is empty, please generate again.";
    
    const currentDomain = alias.domain.trim();
    if (!validDomains.has(currentDomain)) {
      return `Domain changed or missing for alias "${alias.alias}". Please generate again.`;
    }
    const email = `${alias.alias.trim()}@${currentDomain}`;   
    if (seenEmails.has(email)) {
      return `Duplicate email found: ${email}. Please generate again.`;
    }
    
    seenEmails.add(email);
  }
  
  return "";
};

// FIXME: restriction duplication and other
module.exports = {
  isValidDomain,
  validateDomains,
  validateMailboxes,
  checkPasswordStrength,
  isValidEmail,
};
